import React from 'react';
import type { NextPage } from 'next';

import type { FetchCourseSharingGroupOutput } from '@acadeum/api';
import { Link, Output, Outputs, Separator } from '@acadeum/ui';
import {__COURSESHARE_URL__} from '@acadeum/consts';

import { useTranslate } from '@acadeum/translate';

interface CourseSharingGroupPageProps {
  courseSharingGroup: FetchCourseSharingGroupOutput
}

export const CourseSharingGroupPage: NextPage<CourseSharingGroupPageProps> = ({
  courseSharingGroup
}) => {
  const t = useTranslate('CourseSharingGroup');

  return (
    <section>
      <Outputs>
        <Output
          label={t('name')}
          value={courseSharingGroup.name || `#${courseSharingGroup.id}`}
        />

        <Output
          label={t('description')}
          value={courseSharingGroup.description}
        />

        <Output
          label={t('institutions')}
          value={courseSharingGroup.institutions.length === 0
            ? t('noInstitutions')
            : (
              <ul>
                {courseSharingGroup.institutions.map((institution) => (
                  <li key={institution.id}>
                    {institution.name}
                  </li>
                ))}
              </ul>
            )
          }
        />

        <Output
          label={t('consortia')}
          value={courseSharingGroup.consortia.length === 0
            ? t('noConsortia')
            : (
              <ul>
                {courseSharingGroup.consortia.map((consortium) => (
                  <li key={consortium.id}>
                    {consortium.name}
                  </li>
                ))}
              </ul>
            )
          }
        />
      </Outputs>

      <Separator />

      <h4>
        {t('courses')}
      </h4>

      <p>
        See <Link target="_blank" to={`${__COURSESHARE_URL__}?%22refinementList%5Bsession.course.courseSharingGroups.id%5D%5B0%5D%22=%22${courseSharingGroup.id}%22&%22view%22=%22sections%22`}>
          Sections
        </Link>
        {' / See '}
        <Link target="_blank" to={`${__COURSESHARE_URL__}?%22refinementList%5BcourseSharingGroups.id%5D%5B0%5D%22=%22${courseSharingGroup.id}%22&%22view%22=%22courses%22`}>
          Courses
        </Link>
      </p>
    </section>
  );
};
