import React, { memo } from 'react';
import { capitalize } from 'lodash';

import { getErrorData } from '@acadeum/helpers';
import { useTranslate } from '@acadeum/translate';
import type { FormModalProps } from '@acadeum/ui';
import type { OnSubmit } from '@acadeum/ui';
import {
  Blank,
  Card,
  CircularProgress,
  Col,
  DataBlock,
  FormField,
  FormModal,
  FormRow,
  HStack,
  Row,
  Separator,
  Text,
  toast,
  VStack
} from '@acadeum/ui';

import { useFetchTeachingGradeQuery, useEditGradeMutation } from '../../../../api/grades';

import actions from '../../../../actions';

import { useCanEditTeachingGrade } from '../../hooks';

const { fetchEnrollmentRequestsTM } = actions;

export interface TeachingGradeModalProps extends Pick<FormModalProps, 'onHide' | 'show'> {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  grade: any;
  downloadRow: () => Promise<void>;
}

interface FormValues {
  letterGrade: string;
  numericalGrade?: number;
  gradeNotes?: string;
}

export const TeachingGradeModal = memo<TeachingGradeModalProps>(({
  grade,
  downloadRow,
  onHide,
  show
}) => {
  const t = useTranslate('TeachingGradeModal');
  const canEdit = useCanEditTeachingGrade();

  const [editGrade] = useEditGradeMutation();

  const onSubmit: OnSubmit<FormValues> = async (values) => {
    try {
      await editGrade({
        ...values,
        id: grade.id
      }).unwrap();
      // Refresh list of enrollments
      await fetchEnrollmentRequestsTM();
      onHide(false);
      toast.success(t('Grade has been updated successfully'));
    } catch (error) {
      const { message } = getErrorData(error);
      switch (message) {
        case 'not_consortial':
          return toast.error('Enrollment is not a consortium type');
        case 'no_grade':
          return toast.error('Missing Letter Grade');
        default:
          throw error;
      }
    }
  };

  return (
    <FormModal
      show={show}
      onHide={onHide}
      onCancel={onHide}
      title={canEdit ? t('Edit Grade Details') : t('Grade Details')}
      hideFooter={!canEdit}
      actions={[{ title: t('Download'), onClick: downloadRow }]}
      onSubmit={onSubmit}
    >
      <TeachingGradeDetails
        gradeId={grade.id}
        canEdit={canEdit}
      />
    </FormModal>
  );
});

const TeachingGradeDetails = ({
  gradeId,
  canEdit
}) => {
  const t = useTranslate('TeachingGradeModal');
  const { data, isLoading } = useFetchTeachingGradeQuery(gradeId);

  if (!data) {
    if (isLoading) {
      return (
        <HStack justify="center" style={{ height: '20rem' }}>
          <CircularProgress />
        </HStack>
      );
    }
    return null;
  }

  return (
    <Card>
      <Text variant="subtitle2" mb="md">
        {t('Grade')}
      </Text>
      <FormRow>
        <FormField
          required
          autoFocus
          name="letterGrade"
          label={t('Letter Grade')}
          defaultValue={data.letterGrade}
          readOnly={!canEdit}
        />
        <FormField
          type="number"
          name="numericalGrade"
          label={t('Numerical Grade')}
          defaultValue={data.numericalGrade}
          readOnly={!canEdit}
        />
      </FormRow>
      <FormField
        multiline
        label={t('Note')}
        name="gradeNotes"
        defaultValue={data.gradeNotes}
        readOnly={!canEdit}
      />
      <Separator />
      <VStack gap="md" align="none">
        <Text variant="subtitle2">
          {t('Details')}
        </Text>
        {[
          {
            title: t('Student'),
            value: (
              <DataBlock
                type="student"
                student={data.student}
              />
            )
          },
          {
            title: t('Home Institution'),
            value: (
              <DataBlock
                type="institution"
                hideLogo
                institution={data.student.institution}
              />
            )
          },
          {
            title: t('Enrollment Type'),
            value: capitalize(data.type)
          },
          {
            title: t('Course'),
            value: (
              <DataBlock
                type="course"
                course={data.section.session.course}
              />
            )
          },
          {
            title: t('Course Dates'),
            value: (
              <DataBlock
                type="courseSessionDates"
                session={data.section.session}
              />
            )
          }
        ].map(({ value, title }) => (
          <Row key={title}>
            <Col col={5}>{title}</Col>
            <Col col={7}>{value || <Blank />}</Col>
          </Row>
        ))}
      </VStack>
    </Card>
  );
};
