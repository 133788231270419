import React, { useCallback, useEffect, useRef, useState } from 'react';
import type { FC } from 'react';
import classNames from 'classnames';
import { useFormContext } from 'react-hook-form';

import isValidPostalCode from 'common-lib/lib/isValidPostalCode';

import {
  toast,
  Form,
  FormField,
  FormRow,
  ContentSection,
  StickyFormFooter,
  CountryRegionSelect
} from '@acadeum/ui';
import { useTranslate } from '@acadeum/translate';
import type { Student } from '@acadeum/types';

import { ETHNICITY_OPTIONS, GENDER_OPTIONS, RACE_OPTIONS, STUDENT_LEVEL_OPTIONS, YES_OR_NO_OPTIONS } from '../../helpers/select';

import './StudentForm.sass';

interface StudentFormProps {
  values: Student;
  onSubmit: (values: Student) => void;
  cancelLocation: string;
  submitText: string;
  className?: string;
  submitOnMount?: boolean
}

const StudentForm: FC<StudentFormProps> = ({
  values: defaultValues,
  cancelLocation,
  submitText,
  onSubmit: onSubmit_,
  className,
  submitOnMount
}) => {
  const [level, setLevel] = useState(defaultValues && defaultValues.level);
  const t = useTranslate('StudentForm');
  // eslint-disable-next-line
  const form = useRef<any>();

  useEffect(() => {
    if (submitOnMount) {
      setTimeout(() => {
        form.current.submit();
      }, 0);
    }
  }, []);

  const onSubmit = async (values: Student) => {
    // https://github.com/Acadeum/Tickets/issues/1577#issuecomment-2043213113
    if (!values.email && defaultValues.email) {
      values.email = defaultValues.email;
    }
    values.races = getRacesValueFromRacesFormValue(values.races);
    await onSubmit_(values);
  };

  return (
    <Form
      ref={form}
      className={classNames('form StudentForm', className)}
      onSubmit={onSubmit}
    >
      <ContentSection title={t('personalInformation')}>
        <FormRow>
          <FormField
            required
            type="name"
            name="firstName"
            label={t('firstName.label')}
            placeholder={t('firstName.placeholder')}
            defaultValue={defaultValues && defaultValues.firstName}
          />
          <FormField
            required
            type="name"
            name="lastName"
            label={t('lastName.label')}
            placeholder={t('lastName.placeholder')}
            defaultValue={defaultValues && defaultValues.lastName}
          />

          <FormField
            type="name"
            name="middleName"
            label={t('middleName.label')}
            placeholder={t('middleName.placeholder')}
            defaultValue={defaultValues && defaultValues.middleName}
          />
          <FormField
            required
            name="dob"
            label={t('dob')}
            type="date"
            defaultValue={defaultValues && defaultValues.dob}
          />

          <FormField
            name="gender"
            label="Gender"
            type="select"
            options={GENDER_OPTIONS}
            placeholder={t('gender')}
            defaultValue={defaultValues && defaultValues.gender}
          />
          <FormField
            required
            type="select"
            name="ethnicity"
            label={t('ethnicity.label')}
            options={ETHNICITY_OPTIONS}
            placeholder={t('ethnicity.placeholder')}
            defaultValue={defaultValues && defaultValues.ethnicity}
          />
        </FormRow>


        <FormFieldRaces
          t={t}
          values={defaultValues}
        />

        <FormField
          noMargin
          border
          name="citizenship"
          label={t('citizenship')}
          type="radio"
          options={YES_OR_NO_OPTIONS}
          defaultValue={defaultValues && defaultValues.citizenship}
        />
      </ContentSection>

      <ContentSection title={t('academicInformation')}>
        <FormRow>
          <FormField
            required
            name="hiStudentId"
            label={t('hiStudentId.label')}
            defaultValue={defaultValues && defaultValues.hiStudentId}
            placeholder={t('hiStudentId.placeholder')}
            labelTooltip={t('hiStudentId.labelTooltip')}
          />
          <FormField
            required={!(defaultValues && defaultValues.email)}
            type="email"
            name="email"
            label={t('email.label')}
            placeholder={t('email.placeholder')}
            disabled={defaultValues && defaultValues.email}
            defaultValue={defaultValues && defaultValues.email}
          />

          <FormField
            name="level"
            label={t('level.label')}
            type="select"
            placeholder={t('level.placeholder')}
            defaultValue={defaultValues && defaultValues.level}
            options={STUDENT_LEVEL_OPTIONS}
            onChange={e => setLevel(e.target?.value)}
          />
          <FormField
            name="startDate"
            label={t('startDate.label')}
            type="date"
            labelTooltip={t('startDate.labelTooltip')}
            defaultValue={defaultValues && defaultValues.startDate}
          />
        </FormRow>

        <FormField
          name="major"
          label={t('major.label')}
          placeholder={t('major.placeholder')}
          defaultValue={defaultValues && defaultValues.major}
        />

        <FormRow>
          <FormField
            name="advisorName"
            label={t('advisorName.label')}
            placeholder={t('advisorName.placeholder')}
            defaultValue={defaultValues && defaultValues.advisorName}
          />
          <FormField
            name="advisorEmail"
            label={t('advisorEmail.label')}
            placeholder={t('advisorEmail.placeholder')}
            type="email"
            defaultValue={defaultValues && defaultValues.advisorEmail}
          />
        </FormRow>
      </ContentSection>

      <ContactInformation values={defaultValues} t={t} />

      {level === 'High School' && (
        <ContentSection title={t('parentContact')}>
          <FormRow>
            <FormField
              name="parentFirstName"
              label={t('parentFirstName.label')}
              placeholder={t('parentFirstName.placeholder')}
              defaultValue={defaultValues && defaultValues.parentFirstName}
            />
            <FormField
              name="parentLastName"
              label={t('parentLastName.label')}
              placeholder={t('parentLastName.placeholder')}
              defaultValue={defaultValues && defaultValues.parentLastName}
            />
          </FormRow>
          <FormField
            name="parentEmail"
            label={t('parentEmail.label')}
            placeholder={t('parentEmail.placeholder')}
            defaultValue={defaultValues && defaultValues.parentEmail}
          />
        </ContentSection>
      )}

      <ContentSection title={t('notes')}>
        <FormField
          name="notes"
          type="markdown"
          defaultValue={defaultValues && defaultValues.notes}
        />
      </ContentSection>

      <StickyFormFooter
        onCancelProps={{
          url: cancelLocation
        }}
        submitProps={{
          children: submitText
        }}
      />
    </Form>
  );
};

export default StudentForm;

const getRacesValueFromRacesFormValue = (races: Student['races']) => {
  if (races && races.length === 1 && races[0] === '-') {
    return '-';
  }
  return races;
};

const getRacesFormValueFromRacesValue = (races: Student['races']) => {
  if (races && races === '-') {
    return ['-'];
  }
  return races;
};

const FormFieldRaces = ({ values, t }) => {
  const { setValue } = useFormContext();

  const onChange = (event) => {
    if (event.target.value && event.target.value.length > 1 && event.target.value.includes('-')) {
      toast.warn(t('warnRaceNotReported'));
      setValue('races', ['-']);
    }
  };

  return (
    <FormField
      required
      multiple
      type="select"
      name="races"
      label={t('race')}
      options={RACE_OPTIONS}
      onChange={onChange}
      defaultValue={values && getRacesFormValueFromRacesValue(values.races)}
    />
  );
};

const ContactInformation = ({ values, t }) => {
  const { setValue } = useFormContext();

  const [country, setCountry] = useState<string>(values && values.country);

  const validatePostalCodeForCountry = useCallback((value) => {
    if (!isValidPostalCode(value, country)) {
      return t('postalCode.validationError');
    }
  }, [country]);

  const onChangeCountry = (event) => {
    setCountry(event.target.value);
    setValue('postalCode', undefined);
    setValue('state', null);
    setValue('residency', null);
  };

  return (
    <ContentSection title={t('contactInformation')}>
      <FormField
        required
        name="country"
        label={t('country')}
        autoComplete="country-name"
        type="country"
        onChange={onChangeCountry}
        defaultValue={values && values.country}
      />

      <FormRow>
        <FormField
          required
          name="addressLine1"
          label={t('addressLine1.label')}
          placeholder={t('addressLine1.placeholder')}
          labelTooltip={t('addressLine1.labelTooltip')}
          defaultValue={values && values.addressLine1}
        />
        <FormField
          name="addressLine2"
          label={t('addressLine2.label')}
          placeholder={t('addressLine2.placeholder')}
          defaultValue={values && values.addressLine2}
        />
        <FormField
          required
          name="city"
          label={t('city.label')}
          placeholder={t('city.placeholder')}
          defaultValue={values && values.city}
        />
        <CountryRegionSelect
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          required
          // eslint-disable-next-line
          onChange={() => { }}
          Component={FormField}
          country={country}
          notAvailableLabel={t('state.notAvailableLabel')}
          noCountrySelectedLabel=""
          label={t('state.label')}
          type="select"
          name="state"
          defaultValue={values && values.state}
        />
        <FormField
          required
          name="postalCode"
          validate={validatePostalCodeForCountry}
          label={t('postalCode.label')}
          placeholder={t('postalCode.placeholder')}
          defaultValue={values && values.postalCode}
        />
        <CountryRegionSelect
          // eslint-disable-next-line
          onChange={() => { }}
          Component={FormField}
          country={country}
          notAvailableLabel={t('residency.notAvailableLabel')}
          noCountrySelectedLabel=""
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          label={t('residency.label')}
          type="select"
          name="residency"
          defaultValue={values && values.residency}
        />
      </FormRow>
      <FormField
        type="phone"
        name="phone"
        label={t('phone.label')}
        placeholder={t('phone.placeholder')}
        defaultValue={values && values.phone}
      />
    </ContentSection>
  );
};
