import { injectAdminUsersEndpoints } from '@acadeum/api';

import { rtkApi } from '../rtkApi';

export const {
  useAdminRequestUserEmailVerificationMutation,
  useAdminRequestUserPasswordResetMutation,
  useAdminResetUserPasswordMutation,
  useAdminVerifyUserEmailMutation,
  useFetchAdminUsersQuery,
  useAdminChangeOwnInstitutionMutation,
  useFetchUserAdminQuery
} = injectAdminUsersEndpoints(rtkApi);
