import type { RtkApi } from '@acadeum/helpers';

type AppDomain = 'admin' | 'student';

interface SingleSignOnConfig {
  updatedAt: Date,
  saml: {
    entryPoint: URL,
    certificate: string
  }
}

export const injectAuthenticationSettingsEndpoints = (rtkApi: RtkApi) =>
  rtkApi
    .enhanceEndpoints({ addTagTypes: ['authenticationSettings'] })
    .injectEndpoints({
      endpoints: build => ({
        fetchSingleSignOnConfig: build.query<SingleSignOnConfig, AppDomain>({
          query: (domain) => `/authentication-settings/single-sign-on/${domain}`,
          providesTags: (_result, _error, domain) => [{ type: 'authenticationSettings', id: domain }]
        }),
        resetSingleSignOnConfig: build.mutation<void, AppDomain>({
          query: (domain) => ({
            method: 'DELETE',
            url: `/authentication-settings/single-sign-on/${domain}`
          }),
          invalidatesTags: (_result, _error, domain) => [{ type: 'authenticationSettings', id: domain }]
        })
      })
    });

export type UseFetchSingleSignOnConfigQuery = ReturnType<typeof injectAuthenticationSettingsEndpoints>['useFetchSingleSignOnConfigQuery'];
export type UseResetSingleSignOnConfigMutation = ReturnType<typeof injectAuthenticationSettingsEndpoints>['useResetSingleSignOnConfigMutation'];
