import type { FC, MutableRefObject } from 'react';
import React, { useMemo, useRef, useState } from 'react';
import type { ColumnDef } from '@tanstack/react-table';
import { useSelector } from 'react-redux';

import formatUserName from 'common-lib/lib/formatUserName';

import { useTranslate } from '@acadeum/translate';
import type {
  FetchUsersInput,
  FetchUsersOutput,
  UseActivateUserMutation,
  UseAdminRequestUserEmailVerificationMutation,
  UseAdminRequestUserPasswordResetMutation,
  UseDeactivateUserMutation,
  UseDeleteUserMutation,
  UseFetchContactsQuery,
  UseFetchUserRolesQuery,
  UseFetchUsersQuery,
  UserListItem,
  UseSetUsersRolesForUsersMutation,
  UseUpdateContactMutation
} from '@acadeum/api';
import type { ActionsProps } from '@acadeum/ui';
import {
  Actions,
  Button,
  ConfirmActionModal,
  DataBlock,
  Form,
  FormField,
  Page,
  PageLoader,
  Table,
  Tag,
  toast
} from '@acadeum/ui';
import { ArrowsRotateIcon, LockIcon, PlusIcon, WrenchIcon } from '@acadeum/icons';
import { canEditUser, userHasPermission } from '@acadeum/helpers';
import { getAuthSelector } from '@acadeum/auth';

import { useOnError } from '../../../providers/useOnError';

import { DeactivateUserModal } from '../ui/DeactivateUserModal';

import { ReassignUserRoleToUsersOrUserModal } from './ui/ReassignUserRoleToUsersOrUserModal';

import styles from './UserManagementPage.module.scss';
import { useSettingsRoutes } from '../../../hooks/useSettingsRoutes';
import { useApp } from '../../../providers/useApp';

export interface UserManagementPageProps {
  useFetchUserRolesQuery: UseFetchUserRolesQuery;
  useFetchContactsQuery: UseFetchContactsQuery;
  useActivateUserMutation: UseActivateUserMutation;
  useDeactivateUserMutation: UseDeactivateUserMutation;
  useFetchUsersQuery: UseFetchUsersQuery;
  useUpdateContactMutation: UseUpdateContactMutation;
  useAdminRequestUserPasswordResetMutation: UseAdminRequestUserPasswordResetMutation;
  useAdminRequestUserEmailVerificationMutation: UseAdminRequestUserEmailVerificationMutation;
  useDeleteUserMutation: UseDeleteUserMutation;
  useSetUsersRolesForUsersMutation: UseSetUsersRolesForUsersMutation;
}

export const UserManagementPage: FC<UserManagementPageProps> = ({
  useActivateUserMutation,
  useFetchUserRolesQuery,
  useFetchContactsQuery,
  useDeactivateUserMutation,
  useFetchUsersQuery,
  useUpdateContactMutation,
  useAdminRequestUserEmailVerificationMutation,
  useAdminRequestUserPasswordResetMutation,
  useDeleteUserMutation,
  useSetUsersRolesForUsersMutation
}) => {
  const t = useTranslate('shared-admin-ui.UserManagementPage');
  const onError = useOnError();
  const { app } = useApp();
  const { getSettingsUrl, getUserDetailsUrl, getCreateUserUrl } = useSettingsRoutes();

  const [adminRequestUserEmailVerificationMutation] = useAdminRequestUserEmailVerificationMutation();
  const [adminRequestUserPasswordResetMutation] = useAdminRequestUserPasswordResetMutation();

  const {
    data,
    error: userRolesError
  } = useFetchUserRolesQuery({
    pageSize: 1000
  });

  const roles = data?.results;
  const {
    data: contacts,
    error: contactsError
  } = useFetchContactsQuery();

  const [activeUsers, setActiveUsers] = useState<FetchUsersOutput>();
  const [showReassignRoleModal, setShowReassignRoleModal] = useState(false);
  const [showResetPasswordEmailModal, setShowResetPasswordEmailModal] = useState(false);
  const [showVerificationEmailModal, setShowVerificationEmailModal] = useState(false);
  const [showActivateUserModal, setShowActivateUserModal] = useState(false);
  const [showDeactivateUserModal, setShowDeactivateUserModal] = useState(false);
  const [showDeleteUserModal, setShowDeleteUserModal] = useState(false);

  const [activateUser] = useActivateUserMutation();
  const [deleteUser] = useDeleteUserMutation();

  const userProfile = useSelector(getAuthSelector('user'));

  const formRef = useRef() as MutableRefObject<HTMLFormElement>;

  const roleOptions = useMemo(() => {
    const options: {
      value: null | number;
      label: string
    }[] = [
      {
        value: null,
        label: t('all')
      }
    ];

    if (!roles) {
      return options;
    }

    return options.concat(
      roles.map((role) => ({
        value: role.id,
        label: role.name
      }))
    );
  }, [roles]);

  const cols = useMemo<ColumnDef<UserListItem>[]>(() => [
    {
      accessorFn: (row) => formatUserName(row),
      id: 'name',
      header: t('columns.name'),
      cell: ({ row }) => (
        <DataBlock
          type="user"
          user={row.original}
          inlineInfo={(
            <>
              {row.original.singleSignOn && (
                <Tag className={styles.UserManagementPage__status} variant="sso"/>
              )}
              <Tag
                className={styles.UserManagementPage__status}
                variant={row.original.status === 'PASSWORD_NOT_SET' ? 'passwordNotSet' : row.original.status}
              />
            </>
          )}
        />
      )
    },
    {
      header: t('email', { global: true }),
      accessorKey: 'email'
    },
    {
      accessorKey: 'title',
      header: t('columns.title')
    },
    {
      id: 'role',
      header: t('role', { global: true }),
      cell: ({ row }) => <UserRole user={row.original}/>
    },
    {
      id: 'actions',
      cell: ({ row }) => {
        const user = row.original;
        const actions: ActionsProps['actions'] = [
          {
            title: t('seeUser'),
            url: `${getUserDetailsUrl(user.id)}?tab=info`
          },
          {
            title: t('seeUserLog'),
            url: `${getUserDetailsUrl(user.id)}?tab=log`
          }
        ];

        if (canEditUser('update', {
          user,
          byUser: userProfile,
          userInstitutionId: userProfile?.institution.id as number
        })) {
          actions.push({
            title: t('sendPasswordResetEmail.title'),
            onClick: () => {
              setActiveUsers([user]);
              setShowResetPasswordEmailModal(true);
            }
          });

          if (user.status === 'UNVERIFIED') {
            actions.push({
              title: t('sendVerificationEmail.title'),
              onClick: () => {
                setActiveUsers([user]);
                setShowVerificationEmailModal(true);
              }
            });
          }

          if (user.isActive) {
            actions.push({
              title: t('reassignRole'),
              onClick: () => {
                setActiveUsers([user]);
                setShowReassignRoleModal(true);
              }
            });
          } else {
            actions.push({
              title: t('activateUser.title'),
              onClick: () => {
                setActiveUsers([user]);
                setShowActivateUserModal(true);
              }
            });
          }
        }

        if (user.isActive && canEditUser('delete', {
          user,
          byUser: userProfile,
          userInstitutionId: userProfile?.institution.id as number
        })) {
          actions.push({
            title: t('deactivateUser.title'),
            onClick: () => {
              setActiveUsers([user]);
              setShowDeactivateUserModal(true);
            }
          });
        }
        if (!user.isActive && canEditUser('delete', {
          user,
          byUser: userProfile,
          userInstitutionId: userProfile?.institution.id as number
        })) {
          actions.push({
            title: t('deleteUser.title'),
            danger: true,
            onClick: () => {
              setActiveUsers([user]);
              setShowDeleteUserModal(true);
            }
          });
        }

        return (
          <Actions
            variant="kebab"
            actions={actions}
          />
        );
      }
    }
  ], []);

  const [query, setQuery] = useState<FetchUsersInput>();

  const {
    error,
    isLoading: loading,
    data: users
  } = useFetchUsersQuery({ ...query });

  /**
   * Since the react-table library has a known bug that 'globalFilters'
   * does not work with custom columns, we create an additional property
   * in the data array for search purposes.
   *
   * https://github.com/TanStack/table/discussions/2883
   */
  const rows = useMemo(() => {
    if (users === undefined || users.length < 0) {
      return;
    }

    return users.map(user => ({
      name: formatUserName(user),
      ...user
    }));
  }, [users]);

  const sendPasswordResetEmail = async (user) => {
    try {
      await adminRequestUserPasswordResetMutation({ id: user.id }).unwrap();
      toast.success(t('sendPasswordResetEmail.successMessage'));
      setShowResetPasswordEmailModal(false);
    } catch (error: unknown) {
      console.log(error);
      toast.error(t('sendPasswordResetEmail.errorMessage'));
    }
  };

  const sendVerificationEmail = async (user) => {
    try {
      await adminRequestUserEmailVerificationMutation({ id: user.id }).unwrap();
      toast.success(t('sendVerificationEmail.successMessage'));
      setShowVerificationEmailModal(false);
    } catch (error: unknown) {
      console.log(error);
      toast.error(t('sendVerificationEmail.errorMessage'));
    }
  };

  const onActivateUser = async (user) => {
    try {
      await activateUser({ id: user.id }).unwrap();
      toast.success(t('activateUser.successMessage'));
      setShowActivateUserModal(false);
      formRef.current.submit();
    } catch (error: unknown) {
      console.log(error);
      toast.error(t('activateUser.errorMessage'));
    }
  };

  const onDeleteUser = async (user) => {
    try {
      await deleteUser({ id: user.id });
      toast.success(t('deleteUser.successMessage'));
      setShowDeleteUserModal(false);
      formRef.current.submit();
    } catch (error: unknown) {
      console.log(error);
      toast.error(t('deleteUser.errorMessage'));
    }
  };

  const onSubmit = async (values) => {
    let query;
    if (values.filters.roleId) {
      query = {
        filters: values.filters
      };
    } else {
      query = {
        filters: {
          status: values.filters.status
          // type: values.filters.type
        }
      };
    }
    setQuery(query);
  };

  const ACCOUNT_STATUS_OPTIONS = useAccountStatusOptions();

  const error_ = error || userRolesError || contactsError;
  if (error_) {
    return onError(error_);
  }

  return (
    <Page
      title={t('title')}
      breadcrumbs={[[t(app === 'admin' ? 'general' : 'settings', { global: true }), getSettingsUrl()], t('title')]}
    >
      {!(roles && contacts) ? (
        <PageLoader/>
      ) : (
        <div className={styles.UserManagementPage}>
          {userHasPermission(userProfile, 'user:create', {
            orgId: userProfile?.institution.id,
            ownerId: null
          }) && (
            <div className={styles.UserManagementPage__createUser}>
              <Button
                icon={PlusIcon}
                url={getCreateUserUrl()}
              >
                {t('createUser')}
              </Button>
            </div>
          )}

          <Form
            ref={formRef}
            className={styles.UserManagementPage__filters}
            onSubmit={onSubmit}
          >
            <FormField
              noMargin
              className={styles.UserManagementPage__filter}
              type="select"
              name="filters.status"
              options={ACCOUNT_STATUS_OPTIONS}
              placeholder={t('account.state.label')}
              onChange={() => formRef.current.submit()}
            />
            {/*<FormField*/}
            {/*  noMargin*/}
            {/*  className={styles.UserManagementPage__filter}*/}
            {/*  type="select"*/}
            {/*  name="filters.type"*/}
            {/*  options={ACCOUNT_TYPE_OPTIONS}*/}
            {/*  placeholder={t('account.type.label')}*/}
            {/*  onChange={() => formRef.current.submit()}*/}
            {/*/>*/}
            <FormField
              noMargin
              className={styles.UserManagementPage__filter}
              type="select"
              name="filters.roleId"
              options={roleOptions}
              placeholder={t('role', { global: true })}
              onChange={() => formRef.current.submit()}
            />
          </Form>

          <Table
            enableSorting
            enableRowSelection
            enableGlobalFilter
            columns={cols}
            data={rows}
            loading={loading}
            renderTopLeftToolbarCustomActions={({ selectedRows }) => {
              if (!userHasPermission(userProfile, 'user:update', {
                orgId: userProfile?.institution.id,
                ownerId: null
              })) {
                return null;
              }
              return (
                <Button
                  variant="tertiary"
                  icon={ArrowsRotateIcon}
                  disabled={selectedRows.length === 0}
                  onClick={() => {
                    setActiveUsers(selectedRows);
                    setShowReassignRoleModal(true);
                  }}
                >
                  {t('reassign')}
                </Button>
              );
            }}
            translate={{
              searchPlaceholder: t('searchPlaceholder'),
              resultText: ({ totalCount }) => t('resultText', { totalCount }),
              selectedResultText: ({ totalCount, selectedRowsCount }) => t('selectedResultText', {
                totalCount,
                selectedRowsCount
              })
            }}
          />

          {activeUsers && (
            <ReassignUserRoleToUsersOrUserModal
              useSetUsersRolesForUsersMutation={useSetUsersRolesForUsersMutation}
              show={showReassignRoleModal}
              onHide={setShowReassignRoleModal}
              roles={roles}
              users={activeUsers}
              onSuccessfulSubmit={() => formRef.current.submit()}
            />
          )}

          {activeUsers && (
            <ConfirmActionModal
              title={t('sendPasswordResetEmail.title')}
              description={t('sendPasswordResetEmail.description', {
                userName: <strong>{formatUserName(activeUsers[0])}</strong>,
                email: <strong>{activeUsers[0].email}</strong>
              })}
              submitText={t('sendPasswordResetEmail.submitText')}
              show={showResetPasswordEmailModal}
              onSubmit={() => sendPasswordResetEmail(activeUsers[0])}
              onHide={() => setShowResetPasswordEmailModal(false)}
            />
          )}

          {activeUsers && (
            <ConfirmActionModal
              title={t('sendVerificationEmail.title')}
              description={t('sendVerificationEmail.description', {
                userName: <strong>{formatUserName(activeUsers[0])}</strong>,
                email: <strong>{activeUsers[0].email}</strong>
              })}
              submitText={t('sendVerificationEmail.submitText')}
              show={showVerificationEmailModal}
              onSubmit={() => sendVerificationEmail(activeUsers[0])}
              onHide={() => setShowVerificationEmailModal(false)}
            />
          )}

          {activeUsers && (
            <ConfirmActionModal
              title={t('activateUser.title')}
              description={t('activateUser.description', {
                userName: <strong>{formatUserName(activeUsers[0])}</strong>
              })}
              submitText={t('activateUser.submitText')}
              show={showActivateUserModal}
              onSubmit={() => onActivateUser(activeUsers[0])}
              onHide={() => setShowActivateUserModal(false)}
            />
          )}

          {activeUsers && (
            <DeactivateUserModal
              useUpdateContactMutation={useUpdateContactMutation}
              useDeactivateUserMutation={useDeactivateUserMutation}
              useFetchUsersQuery={useFetchUsersQuery}
              onHide={setShowDeactivateUserModal}
              show={showDeactivateUserModal}
              contacts={contacts}
              user={activeUsers[0]}
            />
          )}

          {activeUsers && (
            <ConfirmActionModal
              danger
              title={t('deleteUser.title')}
              description={t('deleteUser.description', {
                userName: <strong>{formatUserName(activeUsers[0])}</strong>,
                br: <br/>,
                danger: (children) => <strong className="danger">{children}</strong>
              })}
              submitText={t('deleteUser.submitText')}
              show={showDeleteUserModal}
              onSubmit={() => onDeleteUser(activeUsers[0])}
              onCancel={() => setShowDeleteUserModal(false)}
              onHide={() => setShowDeleteUserModal(false)}
            />
          )}
        </div>
      )}
    </Page>
  );
};


// function UserType({ user }) {
//   const t = useTranslate('shared-admin-ui.UserManagementPage');
//
//   if (user.firstName && user.lastName) {
//     return (
//       <span className={styles.UserManagementPage__userType}>
//         <ProfileIcon/>{t('user')}
//       </span>
//     );
//   }
//
//   return (
//     <span className={styles.UserManagementPage__userType}>
//       <GroupIcon/>
//       {t('group')}
//     </span>
//   );
// }

function UserRole({ user }) {
  const roleElements = user.roles.map((role) => {
    const isDefaultRole = role.type === 'DEFAULT';
    return (
      <>
        {isDefaultRole ? (
          <LockIcon/>
        ) : (
          <WrenchIcon/>
        )}
        {role.name}
      </>
    );
  });

  return (
    <div className={styles.UserManagementPage__roles}>
      {roleElements.map((roleElement, i) => (
        <span key={i} className={styles.UserManagementPage__userRole}>
          {roleElement}
        </span>
      ))}
    </div>
  );
}

const useAccountStatusOptions = () => {
  const t = useTranslate('shared-admin-ui.UserManagementPage');

  return useMemo(() => [
    { value: null, label: t('all') },
    { value: 'ACTIVE', label: t('account.state.active') },
    { value: 'VERIFIED', label: t('account.state.verified') },
    { value: 'UNVERIFIED', label: t('account.state.unverified') },
    { value: 'INACTIVE', label: t('account.state.inactive') },
    { value: 'PASSWORD_NOT_SET', label: t('account.state.passwordNotSet') }
  ], [t]);
};

// const ACCOUNT_TYPE_OPTIONS = [
//   { value: null, label: t('UserManagementPage.all') },
//   { value: 'INDIVIDUAL', label: t('UserManagementPage.account.type.user') },
//   { value: 'GROUP', label: t('UserManagementPage.account.type.group') }
// ];
