import { rtkApi } from './rtkApi';

import { injectAuthenticationSettingsEndpoints } from '@acadeum/api';

const authenticationSettingsApi = injectAuthenticationSettingsEndpoints(rtkApi);

export const {
  useFetchSingleSignOnConfigQuery,
  useResetSingleSignOnConfigMutation
} = authenticationSettingsApi;
