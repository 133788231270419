import { injectAdminStudentsUserEndpoints } from '@acadeum/api';

import { rtkApi } from '../rtkApi';

export const { 
  useFetchAdminStudentUsersQuery,
  useFetchAdminStudentUserQuery,
  useStudentUserRequestVerifyEmailMutation,
  useStudentUserVerifyEmailMutation,
  useStudentUserRequestPasswordResetMutation,
  useStudentUserResetPasswordMutation
} = injectAdminStudentsUserEndpoints(rtkApi);
