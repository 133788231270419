import React from 'react';

import Link from '../../components/Link';
import Row from '../../components/Row';
import Col from '../../components/Col';

import './AdminTools.sass';

export default function AdminTools() {
  return (
    <section className="admin-tools-page">
      <Row>
        <Col col={4} sm={6} xs={12} className="admin-tools__section">
          <h1>Data</h1>
          <ul className="admin-tools-page__menu">
            <li><Link to="/admin/course-approvals/upload">Upload Course Approvals</Link></li>
            <li><Link to="/admin/approved-courses">View Approved Courses</Link></li>
            <li><Link to="/admin/mappings/upload">Upload Mappings</Link></li>
            <li><Link to="/admin/students/upload">Upload Students</Link></li>
            <li><Link to="/admin/users/create">Create User</Link></li>
            <li><Link to="/institutions/add">Create Institution</Link></li>
            <li><Link to="/admin/enrollments">Enrollments</Link></li>
            <li><Link to="/admin/course-mappings">Course Mappings</Link></li>
          </ul>
        </Col>

        <Col col={4} sm={6} xs={12} className="admin-tools__section">
          <h1>Reports</h1>
          <ul className="admin-tools-page__menu">
            <li><Link to="/admin/financial-breakdown">Financial Breakdown</Link></li>
            <li><Link to="/admin/weekly-breakdown">Weekly Breakdown</Link></li>
            <li><Link to="/admin/yearly-enrollments">Yearly Enrollments</Link></li>
            <li><Link to="/admin/monthly-enrollments">Monthly Enrollments</Link></li>
            <li><Link to="/admin/weekly-enrollments">Weekly Enrollments</Link></li>
            <li><Link to="/admin/session-over-session">Session over Session</Link></li>
            <li><Link to="/admin/viz">Viz</Link></li>
            <li><Link to="/admin/analytics">Analytics</Link></li>
            <li><Link to="/admin/partner-revenue">Partner Revenue</Link></li>
            <li><Link to="/admin/map">Course Enrollments Map</Link></li>
            <li><Link to="/admin/projections">Projections</Link></li>
          </ul>
        </Col>

        <Col col={4} sm={6} xs={12} className="admin-tools__section">
          <h1>Other</h1>
          <ul className="admin-tools-page__menu">
            <li><Link to="/admin/users/change-institution">Change Your Institution</Link></li>
            <li><Link to="/admin/student-users/change-institution">Change Student Institution</Link></li>
            <li><Link to="/admin/students/change-email">Change Student Email</Link></li>
            <li><Link to="/admin/payments">Payments</Link></li>
            <li><Link to="/admin/annual-fees">Annual Fees</Link></li>
            <li><Link to="/admin/stripe">Stripe</Link></li>
            <li><Link to="/admin/search-index-manual-update">Search Index Manual Update</Link></li>
            <li><Link to="/admin/search-index-updates">Search Index Updates</Link></li>
            <li><Link to="/admin/mark-consortial">Mark Enrollment as Consortial</Link></li>
            <li><Link to="/admin/mark-unpaid">Mark Enrollment as Unpaid</Link></li>
            <li><Link to="/admin/change-session-term">Change Session Term</Link></li>
            <li><Link to="/admin/change-enrollment-status">Change Enrollment Status</Link></li>
            <li><Link to="/admin/users">User Accounts</Link></li>
            <li><Link to="/admin/course-sharing-groups">Course Sharing Groups</Link></li>
          </ul>
        </Col>
      </Row>
    </section>
  );
}

AdminTools.meta = () => ({
  title: 'Admin Tools'
});

AdminTools.breadcrumbs = () => ['Admin Tools'];
