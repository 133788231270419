import React from 'react';
import { useSelector } from 'react-redux';

import getLocationUrl from 'common-lib/lib/getLocationUrl';

import { Link, List, Text, Email } from '@acadeum/ui';
import { getAuthSelector } from '@acadeum/auth';
import { __STUDENTS_SITE_URL__ } from '@acadeum/consts';

import { getCourseSearchAttributeName } from '../../components/CourseSearch/getCourseSearchAttributeName';
import CourseSearch_ from '../../components/CourseSearch';
import type { AppPage } from '../../helpers/app.types';

import image from './hs4cc.jpg';

import styles from './HS4CC.module.scss';

const HS4CC_VIEW = 'sections';
const divisionAttributeName = getCourseSearchAttributeName('session.course.division', HS4CC_VIEW);

const studentHS4CCLoginUrl = getLocationUrl({
  origin: __STUDENTS_SITE_URL__,
  pathname: '/login',
  query: {
    hs4cc: '✓'
  }
});

const studentHS4CCSignUpUrl = getLocationUrl({
  origin: __STUDENTS_SITE_URL__,
  pathname: '/sign-up',
  query: {
    hs4cc: '✓'
  }
});

// https://acadeum.atlassian.net/browse/CS-400
const prefilters = {
  filters: [
    // An institution that should be excluded
    // https://acadeum.atlassian.net/browse/CS-510
    'NOT session.course.institution.id:901',
    'session.course.institution.affiliations:\'Dual Enrollment Serving Institution\'',
    `${divisionAttributeName} >= 0 AND ${divisionAttributeName} <= 299 OR ${divisionAttributeName} >= 1000 AND ${divisionAttributeName} <= 2999`
  ].join(' AND ')
};

const HS4CC: AppPage = () => {
  const { user } = useSelector(getAuthSelector);

  const CourseSearch = () => (
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    <CourseSearch_
      view={HS4CC_VIEW}
      isHS4CC
      searchFieldBanner
      searchFieldBannerFullWidth
      prefilters={prefilters}
    />
  );

  return (
    <div>
      <div className={styles.banner}>
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className={styles.columns}>
                <div>
                  <Text
                    as="h1"
                    mb="md"
                    variant="headingMd"
                  >
                    HS4CC Dual Enrollment Portal
                  </Text>
                  <Text mb="sm">
                    Unlock college savings with Homeschooling for College Credit's new Dual Enrollment Portal! Our
                    exclusive platform offers homeschooled students access to hundreds of accredited college courses at
                    a fraction of the cost and without a complicated application process.
                  </Text>
                  <Text mb="sm">
                    Eligible students must:
                  </Text>
                  <List>
                    <List.Item>Be homeschooling lawfully in the United States</List.Item>
                    <List.Item> In grades 9-12.</List.Item>
                    <List.Item>Maintain an unweighted high school GPA of 3.0 or above.</List.Item>
                  </List>
                  <Text mb="sm">
                    New to the Portal?
                    Click <Link monochrome removeUnderline={false} to={studentHS4CCSignUpUrl}>SIGN UP</Link> above and create an account.
                    Already a user?
                    Click <Link monochrome removeUnderline={false} to={studentHS4CCLoginUrl}>LOG IN</Link> to search, select and start your college course.
                    Questions? Contact <Email monochrome removeUnderline={false} address="cookderosa@hs4cc.org" />.
                  </Text>
                </div>
                <div>
                  <img
                    alt=""
                    className={styles.logo}
                    src={image}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {user ? (
        <div className="container">
          <div className="row">
            <div className="col-12">
              <CourseSearch/>
            </div>
          </div>
        </div>
      ) : (
        <CourseSearch/>
      )}
    </div>
  );
};

HS4CC.meta = () => ({
  title: 'HS4CC'
});

export default HS4CC;
