import React from 'react';

import { Page } from '@acadeum/ui';
import { CourseSharingGroupForm } from '@acadeum/shared-admin-ui';
import { useTranslate } from '@acadeum/translate';

import { useCreateCourseSharingGroupJob } from '../../api/admin/courseSharingGroups';
import actions from '../../actions';

const { goto } = actions;

const CreateCourseSharingGroup = () => {
  const t = useTranslate('CreateCourseSharingGroup');

  const createCourseSharingGroup = useCreateCourseSharingGroupJob();

  const onSubmit = async (values) => {
    if (!values.institutionIds) {
      values.institutionIds = [];
    }
    if (!values.consortiumIds) {
      values.consortiumIds = [];
    }

    await createCourseSharingGroup({body: values});
    goto('/admin/course-sharing-groups');
  };

  return (
    <Page
      title={t('title')}
      breadcrumbs={[
        [t('home', { global: true }), '/'],
        [t('adminTools', { global: true }), '/admin'],
        [t('courseSharingGroups'), '/admin/course-sharing-groups'],
        t('create', { global: true })
      ]}
    >
      <CourseSharingGroupForm
        submitText={t('create', { global: true })}
        cancelUrl="/admin/course-sharing-groups"
        onSubmit={onSubmit}
      />
    </Page>
  );
};

export default CreateCourseSharingGroup;
