import type { MutableRefObject } from 'react';
import React, { useCallback, useContext, useMemo, useRef, useState } from 'react';
import { useSelector } from 'react-redux';

import getLocationUrl from 'common-lib/lib/getLocationUrl';

import { getCommunityUrl, getDataFromStorage, saveDataToStorage } from '@acadeum/helpers';
import { getAuthSelector } from '@acadeum/auth';
import { ArrowRightFromBracketIcon, ProfileIcon, SupportIcon, VizIcon } from '@acadeum/icons';
import { __ACCOUNT_CENTER_URL__, webSiteUrls } from '@acadeum/consts';

import { useApp } from '../../providers/useApp';

import type { MenuItem } from './types';
import type { InstitutionMenuProps } from './InstitutionMenu';

export interface AppTemplateContextProps {
  minimized: boolean;
  toggleMinimized: (value?: boolean) => void;
  expanded: boolean;
  toggleExpanded: (value?: boolean) => void;
  renderMenu: 'app' | 'user';
  setRenderMenu: (type: AppTemplateContextProps['renderMenu']) => void;
  mainRef: MutableRefObject<HTMLElement>;
  buttonRef: MutableRefObject<HTMLButtonElement>;
  setExpanded: (value: boolean) => void;
  userMenu: MenuItem[];
  additionalMenu: MenuItem[];
  loginUrl: string;
  useAdminChangeOwnInstitutionMutation: InstitutionMenuProps['useAdminChangeOwnInstitutionMutation'];
}

export const AppTemplateContext = React.createContext<AppTemplateContextProps>({} as AppTemplateContextProps);

export function useAppTemplateContext() {
  const context = useContext(AppTemplateContext);
  if (!context) {
    throw new Error('useAppTemplateContext must be used within <AppTemplateProvider/>');
  }
  return context;
}

export interface AppTemplateProviderProps extends Pick<InstitutionMenuProps, 'useAdminChangeOwnInstitutionMutation'>{
  apiUrl: string;
  useRouteChangeStartListener?: (effect, deps) => void;
  children: (props: {
    buttonRef: MutableRefObject<HTMLButtonElement>
    sidebarRef: MutableRefObject<HTMLElement>
    mainRef: MutableRefObject<HTMLElement>
    onTapOutside: () => void,
    minimized: boolean
  }) => React.ReactNode;
}

const AppSidebarMinimazedStorageKey = 'app-sidebar-minimized';

export function AppTemplateProvider({
  children,
  apiUrl,
  useAdminChangeOwnInstitutionMutation
}: AppTemplateProviderProps) {
  const user = useSelector(getAuthSelector('user'));
  const { app } = useApp();

  const [expanded, setExpanded] = useState(false);
  const [minimized, setMinimized] = useState(getDataFromStorage(AppSidebarMinimazedStorageKey) === true);
  const [renderMenu, setRenderMenu] = useState<AppTemplateContextProps['renderMenu']>('app');

  const buttonRef = useRef() as MutableRefObject<HTMLButtonElement>;
  const sidebarRef = useRef() as MutableRefObject<HTMLElement>;
  const mainRef = useRef() as MutableRefObject<HTMLElement>;

  const onSidebarCollapse = () => {
    buttonRef.current.focus();
    setExpanded(false);
  };

  const onSidebarExpand = () => {
    sidebarRef.current.focus();
  };

  const toggleExpanded = useCallback((value) => {
    const isOpen = typeof value === 'boolean' ? value : !expanded;
    if (isOpen) {
      onSidebarExpand();
    } else {
      onSidebarCollapse();
    }
    setRenderMenu('app');
    setExpanded(isOpen);
  }, [expanded]);

  const toggleMinimized = useCallback((v?: boolean) => {
    const value = typeof v === 'boolean' ? v : !minimized;
    setMinimized(value);
    saveDataToStorage(AppSidebarMinimazedStorageKey, value);
  }, [minimized]);

  const onTapOutside = () => {
    if (expanded) {
      toggleExpanded(false);
    }
  };

  // TODO: Add comment why it's commented out
  // useRouteChangeStartListener?.(onTapOutside, [expanded]);

  const loginUrl = useMemo(() => {
    return getLocationUrl({
      origin: __ACCOUNT_CENTER_URL__,
      pathname: '/',
      query: {
        logIn: '✓',
        redirectTo: webSiteUrls[app]
      }
    });
  }, [app]);

  const userMenu = useMemo(() => [
    {
      icon: ProfileIcon,
      title: 'Your Profile',
      url: __ACCOUNT_CENTER_URL__ + '/me/account'
    },
    {
      icon: ArrowRightFromBracketIcon,
      title: 'Log out',
      url: '/logout'
    }
  ], []);

  const additionalMenu = useMemo(() => {
    return [
      {
        icon: SupportIcon,
        title: 'Support',
        external: true,
        url: getLocationUrl({
          origin: apiUrl,
          pathname: '/zendesk/single-sign-on'
        })
      },
      ...(user ? [
        {
          icon: VizIcon,
          title: 'Community',
          external: true,
          url: getCommunityUrl()
          // url: getLocationUrl({
          //   origin: apiUrl,
          //   pathname: '/forumbee/single-sign-on'
          // })
        }
      ] : [])
    ];
  }, [user, apiUrl]);

  const context: AppTemplateContextProps = {
    userMenu,
    additionalMenu,
    expanded,
    toggleExpanded,
    minimized,
    toggleMinimized,
    renderMenu,
    setExpanded,
    setRenderMenu,
    mainRef,
    buttonRef,
    loginUrl,
    useAdminChangeOwnInstitutionMutation
  };

  return (
    <AppTemplateContext.Provider value={context}>
      {children({ buttonRef, sidebarRef, mainRef, minimized, onTapOutside })}
    </AppTemplateContext.Provider>
  );
}
