import { useDispatch } from 'react-redux';

import {
  getUseCreateCourseSharingGroupJob,
  getUseDeactivateCourseSharingGroupJob,
  getUseUpdateCourseSharingGroupJob,
  injectAdminCourseSharingGroupEndpoints
} from '@acadeum/api';

import { rtkApi } from '../rtkApi';
import { useJob } from '../jobs';

const adminCourseSharingGroupEndpoints = injectAdminCourseSharingGroupEndpoints(rtkApi);

export const {
  useActivateCourseSharingGroupMutation,
  useFetchCourseSharingGroupsQuery,
  useDeleteCourseSharingGroupMutation
} = adminCourseSharingGroupEndpoints;

export const useCreateCourseSharingGroupJob = getUseCreateCourseSharingGroupJob(useJob);
export const useUpdateCourseSharingGroupJob = getUseUpdateCourseSharingGroupJob(useJob);
export const useDeactivateCourseSharingGroupJob = getUseDeactivateCourseSharingGroupJob(useJob);

export const useRefreshCourseSharingGroup = () => {
  const dispatch = useDispatch();
  return () => {
    dispatch(adminCourseSharingGroupEndpoints.util.invalidateTags(['courseSharingGroups']));
  };
};
