import { __DEV__ } from '@acadeum/consts';

import { COURSE_ATTRIBUTES } from './constants';

export const getCourseSearchAttributeName = (name: string, view?: 'sections' | 'courses') => {
  const attributeName = view === 'courses' ? COURSE_ATTRIBUTES[name] : name;
  if (!attributeName) {
    if (__DEV__) {
      throw new Error(`[getCourseSearchAttributeName] "attributeName" not found for CourseSearch attribute "${name}"`);
    }
    reportError(`"attributeName" not found for CourseSearch attribute "${name}"`);
  }
  return attributeName;
};
