import { useContext } from 'react';

import { ModalSelectContext, ModalUIContext, ModalDatePickerContext, ModalLexicalEditorContext } from './context';

export function useModalDatePickerContext() {
  return useContext(ModalDatePickerContext);
}

export function useModalSelect() {
  return useContext(ModalSelectContext);
}

export function useModalLexicalEditorContext() {
  return useContext(ModalLexicalEditorContext);
}

export function useModalUI() {
  const context = useContext(ModalUIContext);
  if (!context) {
    throw new Error('No Modal was provided. Your component must be wrapped in a <Modal> component.');
  }
  return context;
}
