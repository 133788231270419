import type { RtkApi } from '@acadeum/helpers';

export interface FileDataImportErrorInput {
  type: 'UPLOAD_COURSES' | 'UPLOAD_STUDENTS' | 'UPLOAD_COURSE_APPROVALS' | 'UPLOAD_MAPPINGS' | 'UPLOAD_LEARNING_PROGRAMS',
  fileName: string,
  errors: Array<{
    column: string;
    row: number;
    error: string;
    reason?: string;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    value?: any;
  }>
}

export const injectErrorEndpoints = (rtkApi: RtkApi) =>
  rtkApi.injectEndpoints({
    endpoints: build => ({
      fileDataImportError: build.mutation<void, FileDataImportErrorInput>({
        query: (data) => ({
          url: '/file-data-import-error',
          method: 'POST',
          body: data
        })
      })
    })
  });

export type UseFileDataImportErrorMutation = ReturnType<typeof injectErrorEndpoints>['useFileDataImportErrorMutation']
