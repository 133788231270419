import type { FC } from 'react';
import { toast } from '@acadeum/ui';

import { waitForModalToClose, ConfirmActionModal } from '@acadeum/ui';
import type { FetchCourseSharingGroupsOutput, UseDeleteCourseSharingGroupMutation } from '@acadeum/api';

interface DeleteCourseSharingGroupModalProps {
  show: boolean;
  onHide: (hide: false) => void;
  courseSharingGroup: FetchCourseSharingGroupsOutput['results'][number];
  useDeleteCourseSharingGroupMutation: UseDeleteCourseSharingGroupMutation;
}

export const DeleteCourseSharingGroupModal: FC<DeleteCourseSharingGroupModalProps> = ({
  show,
  onHide,
  courseSharingGroup,
  useDeleteCourseSharingGroupMutation
}) => {
  const [deleteCourseSharingGroup] = useDeleteCourseSharingGroupMutation();

  const onDelete = async () => {
    await deleteCourseSharingGroup({ id: courseSharingGroup.id });
    toast.success(`Course sharing group "${courseSharingGroup.name}" has been deleted successfully`);
    await waitForModalToClose();
    onHide(false);
  };

  const onHideConfirmActionModal = async () => {
    await waitForModalToClose();
    onHide(false);
  };

  return (
    <ConfirmActionModal
      danger
      show={show}
      onHide={onHideConfirmActionModal}
      onCancel={onHideConfirmActionModal}
      onSubmit={onDelete}
      title="Delete Course Sharing Group"
      description={(
        <>
          Are you sure you want to delete course sharing group "{courseSharingGroup.name}"?
          <span style={{display: 'block'}} className="danger">This action cannot be undone.</span>
        </>
      )}
    />
  );
};
