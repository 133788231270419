import React, { useEffect, useMemo, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import {
  Alert,
  Checkbox,
  Col,
  ContentSection,
  DataBlock,
  Form,
  FormField,
  FormFooter,
  FormSubmit,
  Row,
  Separator,
  StickyFormFooter,
  Text
} from '@acadeum/ui';
import { useTranslate } from '@acadeum/translate';
import { getErrorData } from '@acadeum/helpers';

import CourseOverviewSection from '../CourseOverviewSection';

import scrollTo from '../../../../helpers/scrollTo';
import { getEnrollableSessions } from '../../../../helpers/course';

import actions from '../../../../actions';

import './CourseOverview.sass';

const {
  addCourseSubstitute,
  fetchCourseSubstitutes,
  saveAdvisorRecommendationCreationState,
  notify,
  goto
} = actions;

export default function CourseOverview({ navigation, sectionId }) {
  const { course } = useSelector(state => state.courses);
  const { substitutes } = useSelector(state => state.courseSubstitutes);
  const { advisorRecommendationCreationState } = useSelector(state => state.advisorRecommendations);
  const [ recommendAllSections, setRecommendAllSections] = useState(false);

  const t = useTranslate('CreateAdvisorRecommendation');

  function getInitialValues() {
    if (advisorRecommendationCreationState) {
      return {
        initialSelectedSectionIds: advisorRecommendationCreationState.sectionIds,
        initialCourseSubstituteId: advisorRecommendationCreationState.courseSubstituteId
      };
    }

    return {
      initialSelectedSectionIds: sectionId ? [sectionId] : [],
      initialCourseSubstituteId: undefined
    };
  }

  const {
    initialSelectedSectionIds,
    initialCourseSubstituteId
  } = getInitialValues();

  const [selectedSectionIds, setSelectedSectionIds] = useState(initialSelectedSectionIds);
  const [selectedSectionIdsRequired, setSelectedSectionIdsRequired] = useState();
  const [expandAll, setExpandAll] = useState(!sectionId);

  const selectCourseSectionsSection = useRef();

  useEffect(() => {
    setExpandAll(false);
  }, []);

  const isValid = () => {
    let isValid = true;
    let isFirstInvalidField = true;

    // Validate that some sections are selected.
    if (selectedSectionIds.length === 0) {
      setSelectedSectionIdsRequired(true);
      isValid = false;
      if (isFirstInvalidField) {
        scrollTo(selectCourseSectionsSection.current, { margin: true });
        isFirstInvalidField = false;
      }
    } else {
      setSelectedSectionIdsRequired(false);
    }

    return isValid;
  };

  const { onDemand } = course;

  const onSubmit = async (values) => {
    const { substituteId } = values;

    if (!isValid()) {
      return;
    }
    saveAdvisorRecommendationCreationState({
      courseId: course.id,
      // On-Demand Courses have only single section
      sectionIds: selectedSectionIds,
      courseSubstituteId: substituteId,
      currentStepId: 'createAdvisorRecommendationEnrollStudents'
    });
    navigation.next();
  };

  const sections = useMemo(() => {
    if (course) {
      return getEnrollableSessions(course)
        .reduce((all, session) => all.concat(
          session.sections.map((section) => ({
            ...section,
            session
          }))
        ), []);
    }
  }, [course]);

  const onChangeSectionCheckbox = (e) => {
    const { checked, value } = e.target;

    setSelectedSectionIds(prevState => {
      if (checked) {
        return [
          ...prevState,
          Number(value)
        ];
      } else {
        return prevState.filter(_ => _ !== Number(value));
      }
    });
  };

  const onChangeTermCheckbox = (e) => {
    const { checked, value } = e.target;

    if (!checked) {
      const currentTermSections = sections.filter(section => section.session.term === value);
      setSelectedSectionIds(prevState => {
        currentTermSections.map(section => {
          prevState = prevState.filter(_ => _ !== section.id);
        });

        return prevState;
      });
    }
  };

  const substituteForm = () => {
    return (
      <div>
        <FormField
          required
          name="code"
          label={t('substituteForm.code.label')}
          placeholder={t('substituteForm.code.placeholder')}
        />
        <FormField
          required
          name="title"
          label={t('substituteForm.title.label')}
          placeholder={t('substituteForm.title.placeholder')}
        />
        <FormFooter>
          <FormSubmit>
            {t('save', { global: true })}
          </FormSubmit>
        </FormFooter>
      </div>
    );
  };

  const createNewSubstitutionOptionProps = {
    label: t('substituteForm.createNew'),
    children: substituteForm,
    onSubmit: async (values) => {
      if (!values.code || !values.title) {
        throw new Error(t('substituteForm.substituteError'));
      }

      let substitute;
      try {
        substitute = await addCourseSubstitute({
          homeCourseCode: values.code,
          homeCourseTitle: values.title,
          courseId: course.id
        });
        await fetchCourseSubstitutes(course.id);
      }
      catch (error) {
        if (getErrorData(error).message === 'duplicate_course_substitute') {
          return notify(t('substituteDuplicateError'));
        }
        throw error;
      }

      return {
        value: substitute.id,
        label: `${values.code} ${values.title}`
      };
    }
  };

  const getSubstituteOptions = () => {
    return substitutes.map(({ id, equivalentCourse }) => ({
      value: id,
      label: `${equivalentCourse.code} ${equivalentCourse.title}`
    }));
  };

  const groupTermsFromSections = () => {
    const terms = [];
    sections.map(section => {
      if (!terms.includes(section.session.term)) {
        terms.push(section.session.term);
      }
    });

    return terms;
  };

  const onRecommendAllSectionsChange = () => {
    setRecommendAllSections(prevState => {
      const newState = !prevState;

      if (newState) {
        setSelectedSectionIds(sections.map(section => section.id));
      } else {
        setSelectedSectionIds([]);
      }

      return newState;
    });
  };

  const terms = groupTermsFromSections();

  return (
    <ContentSection border={false} padding="none">
      <Form onSubmit={onSubmit}>
        <ContentSection className="CourseOverview">
          <ContentSection
            className="CourseOverview__subsection"
            border={false}
            title={t('courseOverview')}
          >
            <CourseOverviewSection course={course}/>
          </ContentSection>
          <Separator />
          <ContentSection
            className="CourseOverview__subsection"
            border={false}
            title={t('sections')}
          >
            {onDemand ? (
              <>
                <Text className="CourseOverview__subsectionDescription">
                  {t('onDemandSectionsDescription')}
                </Text>
                <ContentSection padding="md">
                  <Text className="CourseOverview__subsectionTitle">
                    {t('section')}
                  </Text>
                  <Row>
                    <Col md={12}>
                      <Text>
                        {t('dropDate')}
                      </Text>
                      <Text className="CourseOverview__columnValue">
                        {t('onDemandCourseDropDateDaysAfterStartDate', { count: course.institution.onDemandCourseDropDateDaysAfterStartDate })}
                      </Text>
                    </Col>
                  </Row>
                  <Separator mt="md" mb="md" />
                  <Row>
                    <Col md={12}>
                      <Text>
                        {t('endDate')}
                      </Text>
                      <Text className="CourseOverview__columnValue">
                        {t('onDemandCourseEndDateDaysAfterStartDate', { count: course.institution.onDemandCourseEndDateDaysAfterStartDate })}
                      </Text>
                    </Col>
                  </Row>
                </ContentSection>
              </>
            ) : (
              <div ref={selectCourseSectionsSection}>
                {selectedSectionIdsRequired ? (
                  <Alert variant="warn" className="CourseOverview__alert">
                    {t('sectionsDescription')}
                  </Alert>
                ) : (
                  <Text className="CourseOverview__subsectionDescription">
                    {t('sectionsDescription')}
                  </Text>
                )}
                <ContentSection className="CourseOverview__subsection" border={false}>
                  <Checkbox
                    type="switch"
                    name="recommendAllCourseSections"
                    label={t('recommendAllCourseSections')}
                    checked={recommendAllSections}
                    onChange={onRecommendAllSectionsChange}
                  />
                  {!recommendAllSections && (
                    <>
                      {terms.map((term, index) => (
                        <TermSection
                          key={`term_${index}`}
                          term={term}
                          index={index}
                          initExpandAll={expandAll}
                          sections={sections}
                          selectedSectionIds={selectedSectionIds}
                          onChangeSection={onChangeSectionCheckbox}
                          onChangeTerm={onChangeTermCheckbox}
                        />
                      ))}
                    </>
                  )}
                </ContentSection>
              </div>
            )}
          </ContentSection>
          <Separator />
          <ContentSection
            className="CourseOverview__subsection"
            border={false}
            title={t('courseSubstitution')}
          >
            <Text className="CourseOverview__subsectionDescription">
              {t('courseSubstitutionDescription')}
            </Text>
            <FormField
              required
              noMargin
              type="select"
              name="substituteId"
              label={t('form.label')}
              defaultValue={initialCourseSubstituteId}
              options={getSubstituteOptions()}
              createOptionProps={createNewSubstitutionOptionProps}
            />
          </ContentSection>
        </ContentSection>
        <StickyFormFooter
          onCancelProps={{
            children: t('cancel', { global: true }),
            onClick: () => {
              if (sectionId) {
                goto(`/sections/${sectionId}`);
              } else {
                goto(`/courses/${course.id}`);
              }
            }
          }}
          submitProps={{
            action: 'submit',
            children: t('next', { global: true })
          }}
        />
      </Form>
    </ContentSection>
  );
}

CourseOverview.propTypes = {
  navigation: PropTypes.shape({
    previous: PropTypes.func,
    next: PropTypes.func,
    go: PropTypes.func
  }),
  sectionId: PropTypes.number
};

const TermSection = ({
  term,
  index,
  initExpandAll,
  sections,
  selectedSectionIds,
  onChangeSection,
  onChangeTerm
}) => {
  const [showSections, setShowSections] = useState(hasSelected());

  const t = useTranslate('CreateAdvisorRecommendation');

  const onChangeTerm_ = (e) => {
    onChangeTerm(e);
    setShowSections(prevState => !prevState);
  };

  function hasSelected() {
    if (initExpandAll) {
      return true;
    }

    const selectedSection = sections.map(section => {
      if (section.session.term === term && selectedSectionIds.includes(section.id)) {
        return true;
      }

      return false;
    });

    return selectedSection.some(_ => _ === true);
  }

  return (
    <>
      <Separator/>
      <Checkbox
        className="CourseOverview__switch"
        type="switch"
        name={`term_${index}`}
        label={t('termTitle', { name: term })}
        value={term}
        checked={showSections}
        onChange={onChangeTerm_}
      />
      {showSections && sections.map(section => {
        if (section.session.term === term) {
          return (
            <ContentSection key={section.id}>
              <Checkbox
                className="CourseOverview__checkbox"
                name={`sections.${section.index}`}
                label={t('sectionTitle', { name: section.session.name })}
                value={section.id.toString()}
                checked={selectedSectionIds.includes(section.id)}
                onChange={e => onChangeSection(e)}
              />
              <div className="CourseOverview__sectionContainer">
                <Row>
                  <Col md={3}>
                    <Text>
                      {t('term')}
                    </Text>
                    <Text className="CourseOverview__columnValue">
                      {section.session.term}
                    </Text>
                  </Col>
                  <Col md={3}>
                    <Text>
                      {t('session')}
                    </Text>
                    <Text className="CourseOverview__columnValue">
                      {section.session.name}
                    </Text>
                  </Col>
                  <Col md={6}>
                    <Text>
                      {t('startAndEndDates')}
                    </Text>
                    <div className="CourseOverview__columnValue">
                      <DataBlock
                        utc
                        type="date"
                        month="short"
                        date={section.session.startDate}
                      />
                      {' - '}
                      <DataBlock
                        utc
                        type="date"
                        month="short"
                        date={section.session.endDate}
                      />
                    </div>
                  </Col>
                </Row>
                <Separator mt="md" mb="md" />
                <Row>
                  <Col md={3}>
                    <Text>
                      {t('addDate')}
                    </Text>
                    <div className="CourseOverview__columnValue">
                      <DataBlock
                        utc
                        type="date"
                        month="short"
                        date={section.session.lastAddDate}
                      />
                    </div>
                  </Col>
                  <Col md={9}>
                    <Text>
                      {t('dropDate')}
                    </Text>
                    <div className="CourseOverview__columnValue">
                      <DataBlock
                        utc
                        type="date"
                        month="short"
                        date={section.session.lastDropDate}
                      />
                    </div>
                  </Col>
                </Row>
              </div>
            </ContentSection>
          );
        }
      })}
    </>
  );
};

TermSection.propTypes = {
  sections: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string,
    number: PropTypes.string,
    schedule: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
    session: PropTypes.shape({
      id: PropTypes.number,
      cost: PropTypes.number,
      internalName: PropTypes.string,
      internalTerm: PropTypes.string,
      name: PropTypes.string,
      term: PropTypes.string
    })
  })).isRequired,
  term: PropTypes.string.isRequired,
  index: PropTypes.number.isRequired,
  initExpandAll: PropTypes.bool,
  selectedSectionIds: PropTypes.arrayOf(PropTypes.number),
  onChangeSection: PropTypes.func.isRequired,
  onChangeTerm: PropTypes.func.isRequired
};
