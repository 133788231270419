import type { CSSProperties, HTMLAttributes, MutableRefObject } from 'react';
import React, { Fragment, useMemo, useRef } from 'react';
import type {
  Cell,
  Column, ColumnPinningState,
  FilterFn,
  Row,
  RowData,
  Table as TableType,
  TableMeta,
  TableOptions
} from '@tanstack/react-table';
import {
  getCoreRowModel,
  getExpandedRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable
} from '@tanstack/react-table';
import type { RankingInfo } from '@tanstack/match-sorter-utils';
import classNames from 'classnames';
import { isEmpty, omit } from 'lodash';

import { isDev } from '@acadeum/helpers';
import { useRect } from '@acadeum/hooks';
import { useTranslate } from '@acadeum/translate';

import type { TopToolbarProps } from './ui/TopToolbar';
import { TopToolbar } from './ui/TopToolbar';
import type { ExportPropOptions } from './hooks/useDataExport';
import { useDataExport } from './hooks/useDataExport';

import { CloseButton } from '../CloseButton';
import type { AlertProps } from '../Alert';
import { Alert } from '../Alert';
import { Sticky } from '../Sticky';
import { Blank } from '../Blank';
import { Text } from '../Text';

import { Pagination } from './ui/Pagination';
import { THead } from './ui/THead';
import { TBody } from './ui/TBody';
import { TFoot } from './ui/TFoot';
import type { RowSelectionType } from './hooks/usePreparedColumnsAndSelectionStateStore';
import { usePreparedColumnsAndSelectionStateStore } from './hooks/usePreparedColumnsAndSelectionStateStore';
import { useGetSelectedRows } from './hooks/useGetSelectedRows';
import { useColumnVisibility } from './hooks/useColumnVisibility';
import type { UseTableStateOptions } from './hooks/useTableState';
import { useTableState } from './hooks/useTableState';

import type { FilterItem } from './clientFilters';
import { useClientFilters } from './clientFilters';

import { EXPANDING_COLUMN_ID, SELECT_COLUMN_ID } from './utils/consts';
import { parseCSSVarId } from './utils/column';
import { fuzzyFilter } from './utils/filterFns';
import type { DownloadRowFn } from './utils/getExportRowFn';
import { getExportRowFn } from './utils/getExportRowFn';
import { TableContextProvider, useTableContext } from './context';

import styles from './Table.module.scss';

declare module '@tanstack/table-core' {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  interface TableMeta<TData extends RowData> {
    /** Must be memoized (wrapped in useCallback) */
    getRowHasError?: ({ row }: { row: Row<TData> }) => boolean;
    /** Must be memoized (wrapped in useCallback) */
    getRowIsDisabled?: ({ row }: { row: Row<TData> }) => boolean;
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  interface ColumnMeta<TData extends RowData, TValue> {
    headerTooltip?: string;
    // Custom filter component for column
    FilterComponent?: React.FC<{
      setFilterValue: Column<TData>['setFilterValue'];
      getFilterValue: Column<TData>['getFilterValue'];
    }>;
    getCellAttributes?: ({
      row,
      cell
    }: { row: Row<TData>; cell: Cell<TData, unknown> }) => HTMLAttributes<HTMLTableDataCellElement>;
    getCellHasError?: ({ row, cell }: { row: Row<TData>; cell: Cell<TData, unknown> }) => boolean;
    getCellIsDisabled?: ({ row }: { row: Row<TData> }) => boolean;
    emptyWhen?: ({ row }: { row: Row<TData>['original'] }) => boolean;
    // eslint-disable-next-line
    exportValue?: ({ row }: { row: Row<TData>['original'] }) => any;
    columnVisibilityTitle?: string;
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  interface FilterFns {
    fuzzy: FilterFn<unknown>;
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  interface FilterMeta {
    itemRank: RankingInfo;
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  interface CellContext<TData extends RowData, TValue> {
    downloadRow: DownloadRowFn;
  }
}

export interface TableTranslate<TData> {
  rearrangeableNoDataMessage?: string;
  resultText?: TopToolbarProps<TData>['resultText'];
  selectedResultText?: TopToolbarProps<TData>['selectedResultText'];
  searchPlaceholder?: TopToolbarProps<TData>['searchPlaceholder'];
  searchAriaLabel?: TopToolbarProps<TData>['searchAriaLabel'];
  noDataMessage?: string;
}

export type {
  ExportDataColumns as TableExportDataColumns,
  ExportPropOptions as TableExportOptionsProp
} from './hooks/useDataExport';

export interface TableProps<TData> extends UseTableStateOptions<TData> {
  id?: string;
  /** Must be memoized */
  columns: TableOptions<TData>['columns'];
  data?: TableOptions<TData>['data'];
  getRowId?: TableOptions<TData>['getRowId'];
  meta?: TableMeta<TData>;
  loading?: boolean;
  isFetching?: boolean;
  hasColumnVisibility?: boolean;
  emptyRows?: number;
  exportOptions?: ExportPropOptions<TData>;
  translate?: TableTranslate<TData>;
  clientFilters?: FilterItem<TData>[] | FilterItem<TData>[][];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  useQueryState?: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  useQueryStates?: any;
  columnPinningLeft?: string[]; // column.id[]
  columnPinningRight?: string[]; // column.id[]
  className?: string;
  alertProps?: AlertProps;
  style?: CSSProperties;
  // Filters
  manualFiltering?: TableOptions<TData>['manualFiltering'];
  enableGlobalFilter?: TableOptions<TData>['enableGlobalFilter'];
  enableColumnFilters?: TableOptions<TData>['enableColumnFilters'];
  getColumnCanGlobalFilter?: TableOptions<TData>['getColumnCanGlobalFilter'];
  // Sort
  enableSorting?: TableOptions<TData>['enableSorting'];
  enableSortingRemoval?: boolean;
  isSortSelectedFirst?: boolean;
  // Pagination
  hidePagination?: boolean;
  // Toolbar
  hideTotalResultsCount?: boolean;
  stickyToolbar?: boolean;
  renderTopLeftToolbarCustomActions?: (props: {
    table: TableType<TData>,
    selectedRows: TData[],
    downloadRows?: () => Promise<void>
  }) => React.ReactNode;
  renderTopRightToolbarCustomActions?: () => React.ReactNode;
  renderTopRow?: ((props: {
    table: TableType<TData>
  }) => React.ReactNode);
  // Row Selection
  rowSelectionType?: RowSelectionType;
  // Rearrangeable
  // This is inner property, and not allowed when using Table
  rearrangeableInner?: never;
  rearrangeable?: boolean;
  /** Must be memoized */
  rearrangeableColumns?: TableOptions<TData>['columns'];
  // Sub Rows / Expanding
  enableSubRowSelection?: TableOptions<TData>['enableRowSelection'];
  enableRowSelection?: TableOptions<TData>['enableRowSelection'];
  getSubRows?: TableOptions<TData>['getSubRows'];
  getRowCanExpand?: TableOptions<TData>['getRowCanExpand'];
  enableExpanding?: TableOptions<TData>['enableExpanding'];
  maxLeafRowFilterDepth?: TableOptions<TData>['maxLeafRowFilterDepth'];
  autoResetPageIndex?: TableOptions<TData>['autoResetPageIndex'];
  withFooter?: boolean;
}

const PAGE_HEADER_HEIGHT = 64;
const STICKY_FORM_FOOTER_HEIGHT = 64;

const TableComponent = <TData extends RowData>({
  id,
  exportOptions,
  style,
  loading,
  isFetching,
  hasColumnVisibility,
  getRowId = (originalRow) => originalRow['id'],
  renderTopLeftToolbarCustomActions,
  renderTopRightToolbarCustomActions,
  getColumnCanGlobalFilter,
  translate: propsTranslate,
  sortingOptions,
  paginationOptions,
  globalFilterOptions,
  enableSortingRemoval,
  hidePagination,
  hideTotalResultsCount,
  emptyRows,
  isSortSelectedFirst,
  rowSelectionType,
  stickyToolbar,
  columnFiltersOptions,
  columnPinningLeft,
  columnPinningRight,
  clientFilters,
  useQueryStates,
  useQueryState,
  renderTopRow,
  alertProps,
  rearrangeable,
  rearrangeableInner,
  rearrangeableColumns,
  enableSubRowSelection = false,
  getSubRows,
  getRowCanExpand,
  enableExpanding,
  maxLeafRowFilterDepth,
  rowSelectionOptions,
  manualFiltering: propsManualFiltering,
  enableGlobalFilter: propsEnableGlobalFilter = false,
  enableColumnFilters: propsEnableColumnFilters = false,
  enableSorting: propsEnableSorting = false,
  enableRowSelection: propsEnableRowSelection = false,
  meta,
  className,
  data: propsData,
  columns: propsColumns,
  autoResetPageIndex = false,
  withFooter = true
}: TableProps<TData>) => {
  const t = useTranslate('ui.Table');

  if (!id && hasColumnVisibility) {
    if (isDev()) {
      throw new Error('Missing Required Prop "id" in <Table/> Component');
    }
  }

  const translate = Object.assign({}, {
    resultText: ({ totalCount }) => t('resultText', { totalCount }),
    selectedResultText: ({ totalCount, selectedRowsCount }) => t('selectedResultText', {
      totalCount,
      selectedRowsCount
    }),
    searchPlaceholder: t('searchPlaceholder'),
    noDataMessage: t('noDataMessage'),
    rearrangeableNoDataMessage: 'Once added, your courses will be displayed here.',
    searchAriaLabel: t('searchAriaLabel')
  }, propsTranslate);

  const {
    resultText,
    selectedResultText,
    searchPlaceholder,
    searchAriaLabel
  } = translate;

  const {
    manualFiltering = propsManualFiltering,
    // Pagination options
    manualPagination,
    pageSizeOptions,
    totalCount: controlledTotalCount,
    pageCount,
    pagination,
    onPaginationChange,
    // Sorting options
    enableSorting = propsEnableSorting,
    manualSorting,
    sorting,
    onSortingChange,
    // Global Filter/Search options
    enableGlobalFilter = propsEnableGlobalFilter,
    globalFilter,
    onGlobalFilterChange,
    // Column Filters options
    enableColumnFilters = propsEnableColumnFilters,
    columnFilters,
    onColumnFiltersChange,
    // Row Selection options
    enableRowSelection = propsEnableRowSelection,
    rowSelection,
    onRowSelectionChange
  } = useTableState({
    rowSelectionOptions,
    columnFiltersOptions,
    globalFilterOptions,
    sortingOptions,
    paginationOptions
  }, useQueryStates, useQueryState);

  const allRows = useMemo(() => {
    const data = propsData ?? [];

    if (!isSortSelectedFirst) {
      return data;
    }

    return [...data].sort((a, b) => {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      const aId = getRowId(a), bId = getRowId(b);
      if (rowSelection[aId] && rowSelection[bId]) {
        return 0;
      }
      if (rowSelection[aId]) {
        return -1;
      }
      if (rowSelection[bId]) {
        return 1;
      }
      return 0;
    });
  }, [propsData, rowSelection, isSortSelectedFirst]);

  const {
    filteredRows,
    renderFilters
  } = useClientFilters({
    filters: clientFilters,
    rows: allRows,
    onRowSelectionChange,
    useQueryStates
  });

  const columns = usePreparedColumnsAndSelectionStateStore({
    enableExpanding,
    enableRowSelection,
    rowSelectionType,
    columns: propsColumns,
    canSelectAll: typeof controlledTotalCount !== 'number'
  });

  const paginationState = useMemo(() => {
    if (hidePagination) {
      return { pageIndex: 0, pageSize: filteredRows.length };
    }
    return pagination;
  }, [hidePagination, filteredRows.length, pagination.pageSize, pagination.pageIndex]);

  const columnPinning = useMemo(() => {
    const columnPinning: ColumnPinningState = {
      left: [],
      right: []
    };

    if (columnPinningLeft) {
      if (enableExpanding) {
        columnPinning.left?.push(EXPANDING_COLUMN_ID);
      }

      if (enableRowSelection || rowSelectionOptions?.enableRowSelection) {
        columnPinning.left?.push(SELECT_COLUMN_ID);
      }

      columnPinning.left?.push(...columnPinningLeft);
    }

    if (columnPinningRight) {
      columnPinning.right?.push(...columnPinningRight);
    }

    return columnPinning;
  }, [
    columnPinningLeft,
    columnPinningRight,
    enableExpanding,
    enableRowSelection,
    rowSelectionOptions
  ]);

  const { columnVisibility, onColumnVisibilityChange } = useColumnVisibility(id);

  const defaultColumn = useMemo<TableOptions<TData>['defaultColumn']>(() => ({
    enableSorting,
    cell: ({ renderValue, column, row }) => {
      const value = renderValue();

      const isEmpty = column.columnDef.meta?.emptyWhen?.({ row: row.original });
      if (value === null || value === undefined || isEmpty) {
        return <Blank/>;
      }

      return value?.toString() ?? null;
    }
  }), [enableSorting]);

  const table = useReactTable({
    debugTable: false,
    meta,
    data: filteredRows,
    columns,
    pageCount: manualPagination ? pageCount : undefined,
    defaultColumn,
    maxLeafRowFilterDepth,
    autoResetPageIndex,
    filterFns: {
      fuzzy: fuzzyFilter
    },
    state: {
      sorting,
      globalFilter,
      pagination: paginationState,
      rowSelection,
      columnFilters,
      columnPinning,
      columnVisibility
    },
    enableSortingRemoval,
    enableSubRowSelection,
    enableRowSelection,
    enableExpanding,
    enableGlobalFilter,
    enableColumnFilters,
    enablePinning: true,
    manualPagination,
    manualSorting,
    manualFiltering,
    onColumnVisibilityChange,
    onSortingChange,
    onColumnFiltersChange,
    onGlobalFilterChange,
    onPaginationChange,
    onRowSelectionChange,
    getColumnCanGlobalFilter,
    getRowCanExpand,
    getSubRows,
    getPaginationRowModel: getPaginationRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getExpandedRowModel: getExpandedRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getCoreRowModel: getCoreRowModel(),
    getRowId: getRowId
  });

  const totalCount = controlledTotalCount ?? table.getFilteredRowModel().rows.length;

  /**
   * @description: VERY IMPORTANT!!!
   * There may not be all the data rows here if the `rowSelection` contains rows
   * that have not been loaded into table before.
   * There have been no cases when it broke something, but you need to remember this
   * */
  const selectedRows = useGetSelectedRows({ table, rowSelection });

  const dataExportProps = useDataExport(exportOptions, {
    columns,
    selectedRows,
    rowSelection,
    table,
    getSubRows
  });

  const exportRowFn = getExportRowFn({ exportOptions });

  const {
    columnSizing,
    columnSizingInfo
  } = table.getState();

  const tableWrapperRef = useRef() as MutableRefObject<HTMLDivElement>;
  const tableRef = useRef() as MutableRefObject<HTMLTableElement>;

  const tableRect = useRect(tableRef);
  const tableWrapperRect = useRect(tableWrapperRef);

  const columnSizeVars = useMemo(() => {
    const headers = table.getFlatHeaders();
    const colSizes: { [key: string]: number } = {};
    for (let i = 0; i < headers.length; i++) {
      const header = headers[i];
      const colSize = header.getSize();
      colSizes[`--header-${parseCSSVarId(header.id)}-size`] = colSize;
      colSizes[`--col-${parseCSSVarId(header.column.id)}-size`] = colSize;
    }
    return colSizes;
  }, [columns, columnSizing, columnSizingInfo, columnVisibility]);

  const tableStyle = {
    ...columnSizeVars,
    '--table-width': `calc(${tableRect.width ?? 0} * 1px)`,
    '--tableWrapper-width': `calc(${tableWrapperRect.width ?? 0} * 1px)`
  } as CSSProperties;

  const toolbar = (
    <TopToolbar
      rearrangeable={rearrangeable}
      table={table}
      totalCount={totalCount}
      isLoading={loading}
      isFetching={isFetching}
      enableGlobalFilter={enableGlobalFilter}
      hasColumnVisibility={hasColumnVisibility}
      dataExportProps={dataExportProps}
      rowSelection={rowSelection}
      resultText={resultText}
      selectedResultText={selectedResultText}
      searchPlaceholder={searchPlaceholder}
      searchAriaLabel={searchAriaLabel}
      leftCustomActions={renderTopLeftToolbarCustomActions?.({
        table,
        selectedRows,
        downloadRows: dataExportProps.exportData
      })}
      rightCustomActions={renderTopRightToolbarCustomActions?.()}
      hideTotalResultsCount={hideTotalResultsCount}
    />
  );

  const getClientHeight = () => {
    // Calculate the used page height by page header, sticky form footer and 20px more for the comfort table view
    const averageUsedHeight = PAGE_HEADER_HEIGHT + STICKY_FORM_FOOTER_HEIGHT + 20;
    return (document.documentElement.clientHeight || window.innerHeight || 0) - averageUsedHeight;
  };

  const RearrangeableWrapperComponent = rearrangeable ? 'div' : Fragment;

  const rearrangeableSelectedRows = table.getSelectedRowModel().rows;
  const rearrangeableOriginalSelectedRows = useMemo(() => {
    return rearrangeableSelectedRows.map(_ => _.original);
  }, [rearrangeableSelectedRows]);

  const rearrangeableColumns_ = useMemo(() => {
    if (!rearrangeableColumns) {
      return [];
    }
    return rearrangeableColumns.concat([
      {
        id: 'actions',
        cell: ({ row }) => {
          const onClick = () => {
            table.setRowSelection((prev) => omit(prev, [row.id]));
          };
          return (
            <CloseButton onClick={onClick}/>
          );
        }
      }
    ]);
  }, [table, rearrangeableColumns]);

  const { showHorizontalScroll } = useTableContext();

  return (
    <div
      data-loading={Boolean(loading || isFetching)}
      className={classNames(styles.Table, className)}
      style={style}
    >
      {renderFilters()}

      <RearrangeableWrapperComponent
        {...(rearrangeable ? {
          className: styles.rearrangeableWrapper
        } : undefined)}
      >

        <RearrangeableWrapperComponent
          {...(rearrangeable ? {
            className: styles.rearrangeableInner,
            style: { flex: '3' }
          } : undefined)}
        >
          {stickyToolbar ? (
            <Sticky position="top">
              {toolbar}
            </Sticky>
          ) : toolbar}

          {!isEmpty(alertProps) && (
            <Alert {...alertProps} />
          )}

          {table.getIsSomeColumnsVisible() && (
            <div className={styles.tableWrapper}>
              <div
                ref={tableWrapperRef}
                className={classNames(styles.tableInner, {
                  [styles.withScroll]: showHorizontalScroll
                })}
                style={{ maxHeight: getClientHeight() }}
              >
                <table
                  ref={tableRef}
                  className={styles.table}
                  style={tableStyle}
                >
                  <THead table={table}/>
                  <TBody
                    exportRowFn={exportRowFn}
                    topRow={renderTopRow?.({ table })}
                    table={table}
                    emptyRows={emptyRows}
                    totalCount={totalCount}
                    loading={loading}
                    translate={translate}
                    rearrangeableInner={rearrangeableInner}
                  />
                  {withFooter && <TFoot table={table}/>}
                </table>
              </div>
              {!hidePagination && totalCount > 0 && (
                <Pagination
                  table={table}
                  pageSizeOptions={pageSizeOptions}
                />
              )}
            </div>
          )}
        </RearrangeableWrapperComponent>

        {rearrangeable && (
          <div
            style={{
              flex: '2',
              display: 'flex',
              flexDirection: 'column'
            }}
          >
            <Text
              variant="subtitle2"
              alignment="center"
              style={{
                marginTop: '.5rem',
                marginBottom: '2.25rem'
              }}
            >
              {rearrangeableOriginalSelectedRows.length}
              {' '}
              item{rearrangeableOriginalSelectedRows.length === 1 ? '' : 's'} selected
            </Text>
            <Table
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              rearrangeableInner
              columns={rearrangeableColumns_}
              data={selectedRows}
              hideTotalResultsCount
              translate={translate}
              style={{ flex: 1 }}
            />
          </div>
        )}
      </RearrangeableWrapperComponent>
    </div>
  );
};

export const Table = <TData extends RowData>(
  props: TableProps<TData>
) => {
  return (
    <TableContextProvider>
      <TableComponent {...props} />
    </TableContextProvider>
  );
};
