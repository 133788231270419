import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { useTranslate } from '@acadeum/translate';
import { getErrorData } from '@acadeum/helpers';

import ResetPassword from '../ResetPassword';
import ResetPasswordConfirmationModal from '../ResetPasswordConfirmationModal';
import Modal from '../Modal';
import InstitutionUsesSingleSignOnAuthentication from '../InstitutionUsesSingleSignOnAuthentication';
import RequestPasswordReset from '../RequestPasswordReset';
import RequestPasswordResetConfirmationModal from '../RequestPasswordResetConfirmationModal';

import useQueryParameters from '../../hooks/useQueryParameters';

import actions from '../../actions';

const {
  notifyError,
  checkPasswordResetToken,
  showSignInModal
} = actions;

export default function ResetPasswordModal ({
  setIsPasswordResetLink
}) {
  const [showResetPasswordModal, setShowResetPasswordModal] = useState();
  const [showResetPasswordConfirmation, setShowResetPasswordConfirmation] = useState();

  const [resetPasswordToken, setResetPasswordToken] = useState();
  const [resetPasswordUserId, setResetPasswordUserId] = useState();
  const [resetPasswordUserEmail, setResetPasswordUserEmail] = useState();
  const [redirectTo, setRedirectTo] = useState();

  const [showResendTokenModal, setShowResendTokenModal] = useState();
  const [showRequestPasswordResetConfirmationModal, setShowRequestPasswordResetConfirmationModal] = useState();
  const [showPasswordResetAttemptedOnSingleSignOnInstitutionModal, setShowPasswordResetAttemptedOnSingleSignOnInstitutionModal] = useState();
  const [loginError, setLoginError] = useState();

  const t = useTranslate();

  useQueryParameters(async ({
    resetPassword,
    userId,
    email,
    token,
    redirectTo
  }, { removeQueryParameters }) => {
    setIsPasswordResetLink(Boolean(resetPassword));

    if (resetPassword) {
      removeQueryParameters({
        resetPassword,
        userId,
        email,
        token,
        redirectTo
      });

      userId = parseInt(userId);

      let error;

      try {
        await checkPasswordResetToken({
          userId,
          token
        });
      } catch (error_) {
        console.error(error_);
        error = getErrorData(error_);
      }

      if (error) {
        switch (error.code) {
          case 'token_expired':
          case 'token_mismatch':
          case 'password_reset_not_requested':
            return setShowResendTokenModal(true);
          default:
            return notifyError(t('errorVerbose', { global: true }));
        }
      }

      setResetPasswordToken(token);
      setResetPasswordUserId(userId);
      setResetPasswordUserEmail(email);
      setRedirectTo(redirectTo);
      setShowResetPasswordModal(true);
    }
  });

  const onAfterSubmit = ({ loginError }) => {
    setLoginError(loginError);
    setShowResetPasswordModal(false);
    setShowResetPasswordConfirmation(true);
  };

  const onHideResetPasswordConfirmationModal = () => {
    setShowResetPasswordConfirmation(false);

    if (loginError) {
      showSignInModal();
    }
  };

  return (
    <>
      <Modal
        show={showResetPasswordModal}
        onHide={() => setShowResetPasswordModal(false)}>
        {showResetPasswordModal
          ? <ResetPassword
            token={resetPasswordToken}
            userId={resetPasswordUserId}
            email={resetPasswordUserEmail}
            redirectTo={redirectTo}
            onPasswordResetAttemptedOnSingleSignOnInstitution={() => {
              setShowResetPasswordModal(false);
              setShowPasswordResetAttemptedOnSingleSignOnInstitutionModal(true);
            }}
            onAfterSubmit={onAfterSubmit}
          />
          : <React.Fragment/>
        }
      </Modal>

      <ResetPasswordConfirmationModal
        show={showResetPasswordConfirmation}
        onHide={onHideResetPasswordConfirmationModal}
      />

      <Modal
        show={showResendTokenModal}
        onHide={() => setShowResendTokenModal(false)}>
        <RequestPasswordReset
          onPasswordResetAttemptedOnSingleSignOnInstitution={() => {
            setShowResendTokenModal(false);
            setShowPasswordResetAttemptedOnSingleSignOnInstitutionModal(true);
          }}
          onAfterSubmit={() => {
            setShowResendTokenModal(false);
            setShowRequestPasswordResetConfirmationModal(true);
          }}
        />
      </Modal>

      <RequestPasswordResetConfirmationModal
        show={showRequestPasswordResetConfirmationModal}
        onHide={() => setShowRequestPasswordResetConfirmationModal(false)}
      />

      <InstitutionUsesSingleSignOnAuthentication
        show={showPasswordResetAttemptedOnSingleSignOnInstitutionModal}
        onHide={() => setShowPasswordResetAttemptedOnSingleSignOnInstitutionModal()}
      />
    </>
  );
}

ResetPasswordModal.propTypes = {
  setIsPasswordResetLink: PropTypes.func.isRequired
};
