import type { FC, MutableRefObject } from 'react';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import type { ColumnDef } from '@tanstack/react-table';
import { useSelector } from 'react-redux';

import { getAuthSelector } from '@acadeum/auth';
import { useTranslate } from '@acadeum/translate';
import { Form, InstitutionAutocomplete, Table } from '@acadeum/ui';
import type {
  FetchAllCourseSubstitutesOutput,
  FetchInstitutionOutput,
  UseLazyFetchAllCourseSubstitutesQuery,
  UseLazyFetchInstitutionQuery
} from '@acadeum/api';

import styles from './CourseMappingsTable.module.scss';

type CourseSubstitute = FetchAllCourseSubstitutesOutput[number]['courseSubstitutes'][number];

interface TableRow extends CourseSubstitute {
  course: Omit<FetchAllCourseSubstitutesOutput[number], 'courseSubstitutes'>,
  homeInstitution: FetchInstitutionOutput
}

export interface CourseMappingsTableProps {
  isAdmin?: boolean;
  useLazyFetchAllCourseSubstitutesQuery: UseLazyFetchAllCourseSubstitutesQuery;
  useLazyFetchInstitutionQuery: UseLazyFetchInstitutionQuery;
}

export const CourseMappingsTable: FC<CourseMappingsTableProps> = ({
  isAdmin,
  useLazyFetchInstitutionQuery,
  useLazyFetchAllCourseSubstitutesQuery
}) => {
  const t = useTranslate('shared-admin-ui.CourseMappingsTable');

  const [isLoading, setIsLoading] = useState(false);
  const [rows, setRows] = useState<TableRow[]>();

  const columns = useColumns(t, isAdmin);
  const formRef = useRef() as MutableRefObject<HTMLFormElement>;

  const user = useSelector(getAuthSelector('user'));
  const institutionId = user?.institution.id;

  const [lazyFetchAllCourseSubstitutesQuery] = useLazyFetchAllCourseSubstitutesQuery();
  const [lazyFetchInstitutionQuery] = useLazyFetchInstitutionQuery();

  const onGetCourseMappings = async ({ institutionId }) => {
    try {
      setIsLoading(true);

      const [
        homeInstitution,
        courseSubstitutes
      ] = await Promise.all([
        lazyFetchInstitutionQuery({ id: institutionId }).unwrap(),
        lazyFetchAllCourseSubstitutesQuery({ institutionId }).unwrap()
      ]);

      const preparedRows: TableRow[] = courseSubstitutes.reduce<TableRow[]>((result, course) => {
        return result.concat(course.courseSubstitutes.map((substitute) => ({
          ...substitute,
          homeInstitution,
          course
        })));
      }, []);

      setRows(preparedRows);
      // eslint-disable-next-line no-useless-catch
    } catch (error: unknown) {
      throw error;
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (!isAdmin && institutionId) {
      void onGetCourseMappings({ institutionId });
    }
  }, [isAdmin]);

  return (
    <>
      {isAdmin && (
        <Form
          className={styles.CourseMappingsTable__form}
          ref={formRef}
          onSubmit={onGetCourseMappings}
        >
          <InstitutionAutocomplete
            type="institution"
            onChange={() => formRef.current.submit()}
          />
        </Form>
      )}
      <Table
        id="courseMappings"
        enableSorting
        enableColumnFilters
        enableRowSelection
        columns={columns}
        data={rows}
        loading={isLoading}
        exportOptions={{
          type: 'csv',
          fileName: t('fileName')
        }}
        translate={{
          noDataMessage: t('notLoadedLabel'),
          resultText: ({ totalCount }) => t('resultText', { totalCount }),
          selectedResultText: ({ totalCount, selectedRowsCount }) => t('selectedResultText', {
            totalCount,
            selectedRowsCount
          })
        }}
      />
    </>
  );
};

function useColumns(t, isAdmin) {
  return useMemo<ColumnDef<TableRow>[]>(() => {
    const columns: ColumnDef<TableRow>[] = [
      { header: t('columns.homeInstitution.name'), accessorKey: 'homeInstitution.name' },
      { header: t('columns.homeInstitution.courseCode'), accessorKey: 'equivalentCourse.code' },
      { header: t('columns.homeInstitution.courseTitle'), accessorKey: 'equivalentCourse.title' },
      ...(isAdmin ? [{ header: t('columns.teachingInstitution.id'), accessorKey: 'course.institution.id' }] : []),
      { header: t('columns.teachingInstitution.name'), accessorKey: 'course.institution.name' },
      { header: t('columns.teachingInstitution.courseCode'), accessorKey: 'course.code' },
      { header: t('columns.teachingInstitution.courseTitle'), accessorKey: 'course.title' },
      { header: t('columns.level'), accessorKey: 'course.level' },
      { header: t('columns.creditHours'), accessorKey: 'course.hours' },
      { header: t('columns.createdAt'), accessorKey: 'createdAt' }
    ];

    if (isAdmin) {
      columns.unshift(
        { header: t('columns.id'), accessorKey: 'id', filterFn: 'fuzzy' }, 
        { header: t('columns.homeInstitution.id'), accessorKey: 'homeInstitution.id' }
      );
    }

    return columns;
  }, []);
}
