// Converts `Error` instance into a plain JSON object
// for storing it in Redux state.

/**
 * @deprecated use `isHttpError` instead
 *  Example:
 *  ```
 *  if (isHttpError(error)) {
 *    console.log(error.status);
 *    console.log(error.data.message);
 *    console.log(error.data.code);
 *  }
 *  ```
 * */
export const getErrorData = (error): {
  message?: string;
  status?: number;
  code?: string;
  field?: string;
  type?: string;
  // eslint-disable-next-line
  [key: string]: any;
} => {
  const {
    status,
    message,
    data
  } = error;

  let errorData = {
    status,
    message
  };

  // `data` is a standard property of an HTTP response.
  // It is set by `react-website`'s `httpClient`.
  // When HTTP response content type is `application/json`
  // then `data` is the response JSON object.
  if (data) {
    const {
      // Sometimes AWS Lambda throws its own errors like "response body too large"
      // or code syntax error or rate limit exceeded, etc.
      // Those errors have an "errorMessage" property.
      errorMessage,
      ...rest
    } = data;
    errorData = {
      ...errorData,
      ...rest
    };
    if (errorMessage) {
      errorData.message = errorMessage;
    }
  }

  return errorData;
};
