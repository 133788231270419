import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import config from 'config';

import { getErrorData } from '@acadeum/helpers';

import { useTranslate } from '@acadeum/translate';
import TextButton from '../TextButton';
import Form from '../Form';
import FormField from '../FormField';
import FormHeader from '../FormHeader';
import FormFooter from '../FormFooter';
import FormTitle from '../FormTitle';
import FormDescription from '../FormDescription';
import FormSubmit from '../FormSubmit';
import { waitForModalToClose } from '../Modal';
import SingleSignOnEnabled from '../SingleSignOnEnabled';
import RequestMoreInfoButton from '../RequestMoreInfoButton';
import useFindInstitutionByEmailDomain from '../../hooks/useFindInstitutionByEmailDomain';
import useRequestMoreInfoPublicMarketingPageType from '../../hooks/useRequestMoreInfoPublicMarketingPageType';

import getRedirectToThisPageUrl from '../../helpers/getRedirectToThisPageUrl';

import getLocationUrl from 'common-lib/lib/getLocationUrl';

import { gaEvent } from '@acadeum/helpers';
import actions from '../../actions';

const {
  hideSignInModal,
  showRequestPasswordResetModal,
  signIn
} = actions;

export default function Login (props) {
  const preConfiguredInstitution = useSelector(state => state.signInModal.institution);
  return (
    <LoginForm
      {...props}
      institution={preConfiguredInstitution}
    />
  );
}

function LoginForm ({
  onAfterSubmit,
  onError,
  onAccountNotVerified,
  onPasswordNotSet,
  onPasswordLoginAttemptedOnSingleSignOnInstitution,
  onAccountInactive,
  onInstitutionInactive,
  onTooManyLoginAttempts,
  institution: preConfiguredInstitution
}) {
  const t = useTranslate('Login');

  const [institution, setInstitution] = useState(preConfiguredInstitution);

  const [userNotFound, setUserNotFound] = useState();
  const [passwordIsIncorrect, setPasswordIsIncorrect] = useState();

  const [searchingInstitutionByEmail, setSearchingInstitutionByEmail] = useState();

  const hideModal = hideSignInModal;

  // const onCreateAccount = () => {
  //   hideModal();
  //   goto('/sign-up');
  // };

  const onForgotPassword = async () => {
    hideModal();
    await waitForModalToClose();
    showRequestPasswordResetModal();
  };

  const onSubmit = async ({ email, password, rememberMe }) => {
    gaEvent({
      category: 'Buttons',
      action: 'Clicked',
      label: 'Login'
    });

    const expires = !rememberMe;

    // dispatch(startAuthentication());
    // https://github.com/Acadeum/Acadeum-Student-Portal/issues/96
    if (rememberMe) {
      localStorage.setItem('user.email', email);
    } else {
      localStorage.removeItem('user.email');
    }

    // Single Sign On flow.
    if (!password) {
      // The actual email address entered by the user doesn't matter:
      // only the home institution matters.
      // For example, a student could have entered their classmate's email,
      // and then they'd be redirected to their institution's Single Sign On page,
      // on which the student will only be able to choose their own email,
      // and then the Sign Up process proceeds with the student's own email.
      window.location = getLocationUrl({
        origin: config.apiUrl,
        pathname: '/single-sign-on',
        query: {
          institutionId: institution.id,
          redirectTo: getRedirectToThisPageUrl(),
          expires: rememberMe ? undefined : '✓',
          email
        }
      });
      return;
    }

    setUserNotFound(false);
    setPasswordIsIncorrect(false);

    let result;
    let error;

    try {
      result = await signIn({ email, password, expires });
    } catch (error_) {
      console.error(error_);
      error = getErrorData(error_);
    }

    if (error) {
      if (error.type === 'not_found') {
        return setUserNotFound(true);
      }
      if (error.type === 'rate_limit') {
        return onTooManyLoginAttempts(error.value);
      }
      switch (error.code) {
        case 'account_not_verified':
          return onAccountNotVerified(email);
        case 'account_inactive':
          return onAccountInactive();
        case 'institution_inactive':
          return onInstitutionInactive();
        case 'password_not_set':
          return onPasswordNotSet();
        case 'incorrect_password':
          return setPasswordIsIncorrect(true);
        case 'single_sign_on_authentication_required':
          return onPasswordLoginAttemptedOnSingleSignOnInstitution();
        default:
          return onError(error, { institution });
      }
    }

    console.log('Logged in as', result.user);

    if (onAfterSubmit) {
      onAfterSubmit();
    }
  };

  // https://github.com/Acadeum/Acadeum-Student-Portal/issues/96
  const defaultEmail = useMemo(() => localStorage.getItem('user.email'), []);
  const isRememberMe = Boolean(defaultEmail);

  const singleSignOn = institution && institution.singleSignOn;

  const findInstitutionByEmail = useFindInstitutionByEmailDomain({
    getInstitutionResultObject: (institution) => ({
      id: institution.id,
      singleSignOn: institution.adminSingleSignOn
    }),
    onInstitutionFound(institution) {
      // Should use the passed `institution` argument
      // instead of creating a new object from it:
      // it if created a new object from it
      // then the component would re-render indefinitely.
      setInstitution(institution);
    },
    onInstitutionNotFound() {
      setInstitution();
    },
    onSearchStart() {
      setSearchingInstitutionByEmail(true);
    },
    onSearchEnd() {
      setSearchingInstitutionByEmail(false);
    }
  });

  useEffect(() => {
    // FormField's `onChange` function doesn't run on initial mount.
    // Perhaps that could be changed in `FormField.js` code
    // via a similar `useEffect()` hook.
    if (defaultEmail) {
      findInstitutionByEmail(defaultEmail);
    }
  }, []);

  const onEmailChange = useCallback((value) => {
    setUserNotFound(false);
    findInstitutionByEmail(value);
  }, []);

  const onPasswordChange = useCallback(() => {
    setPasswordIsIncorrect(false);
  }, []);

  const isRequestMoreInfoMarketingPage = Boolean(useRequestMoreInfoPublicMarketingPageType());

  const singleSignOnBadgeOrForgotPasswordButton = singleSignOn
    ? (
      <SingleSignOnEnabled/>
    )
    : (
      <TextButton
        caret
        onClick={onForgotPassword}>
        {t('forgotPassword')}
      </TextButton>
    );

  return (
    <section className="Login">
      <FormHeader>
        <FormTitle>
          {t('title')}
        </FormTitle>
        <FormDescription>
          {t('description')}
        </FormDescription>
      </FormHeader>

      <Form onSubmit={onSubmit} className="Form--alternativeFieldLabelStyle">
        <FormField
          required
          type="email"
          name="email"
          defaultValue={defaultEmail}
          error={userNotFound ? t('userNotFound') : undefined}
          label={t('form.email.label')}
          placeholder={t('form.email.hint')}
          loading={searchingInstitutionByEmail}
          onChange={onEmailChange}
        />

        {!singleSignOn && (
          <FormField
            required
            type="password"
            name="password"
            label={t('form.password.label')}
            placeholder={t('form.password.hint')}
            error={passwordIsIncorrect ? t('wrongPassword') : undefined}
            onChange={onPasswordChange}
          />
        )}

        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
          <FormField
            type="checkbox"
            name="rememberMe"
            label={t('form.remember')}
            defaultValue={isRememberMe}
          />
          {isRequestMoreInfoMarketingPage
            ? singleSignOnBadgeOrForgotPasswordButton
            : null
          }
        </div>

        <FormFooter>
          <FormFooter.Actions>
            {isRequestMoreInfoMarketingPage
              ? (
                <RequestMoreInfoButton
                  onClick={async () => {
                    hideModal();
                    await waitForModalToClose();
                  }}
                />
              )
              : singleSignOnBadgeOrForgotPasswordButton
            }
            <FormSubmit>
              {t('form.submit')}
            </FormSubmit>
          </FormFooter.Actions>
        </FormFooter>
      </Form>
    </section>
  );
}

LoginForm.propTypes = {
  onAfterSubmit: PropTypes.func,
  onError: PropTypes.func.isRequired,
  onAccountNotVerified: PropTypes.func.isRequired,
  onPasswordNotSet: PropTypes.func.isRequired,
  onPasswordLoginAttemptedOnSingleSignOnInstitution: PropTypes.func.isRequired,
  onAccountInactive: PropTypes.func.isRequired,
  onInstitutionInactive: PropTypes.func.isRequired,
  onTooManyLoginAttempts: PropTypes.func.isRequired,
  institution: PropTypes.shape({
    id: PropTypes.number.isRequired,
    singleSignOn: PropTypes.bool
  })
};
