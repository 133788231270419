// This is a fake attribute name, i.e. it doesn't exist in Algolia.
// It's just some dummy placeholder that is used to track "Start & End Dates" filter visibility.
export const START_DATE_AND_END_DATE_FILTER_ATTRIBUTE_PLACEHOLDER = 'startDateAndEndDate';

export const COURSE_ATTRIBUTES = {
  'session.course.approvedBy': 'approvedBy',
  'session.course.onDemand': 'onDemand',
  'session.course.institution.consortialEnrollingInstitutionIds': 'institution.consortialEnrollingInstitutionIds',
  'session.course.institution.regionallyAccredited': 'institution.regionallyAccredited',
  'session.course.institution.qualityMattersDesignation': 'institution.qualityMattersDesignation',
  'session.course.institution.consortiaNames': 'institution.consortiaNames',
  'session.course.categories': 'categories',
  'session.term': 'sessions.term',
  'session.name': 'sessions.name',
  'session.lastAddDate': 'sessions.lastAddDate',
  'session.earliestStartDate': 'sessions.earliestStartDate',
  'session.earliestEndDate': 'sessions.earliestEndDate',
  'session.startDate': 'sessions.startDate',
  'session.endDate': 'sessions.endDate',
  'session.course.level': 'level',
  'session.course.hours': 'hours',
  // Changed the `cost` attribute from `cost.sessions[].cost`
  // to `course.cost` that is set from `cost.sessions[0].cost`
  // when generating Algolia course object.
  // Introduced `course.cost` property because it's required
  // in order to sort courses by cost in `courses_price_asc` Algolia index.
  // 'session.cost': 'sessions.cost',
  'session.cost': 'cost',
  'session.course.institution.id': 'institution.id',
  'session.course.institution.name': 'institution.name',
  'session.course.institution.level': 'institution.level',
  'session.course.institution.control': 'institution.control',
  'session.course.institution.affiliations': 'institution.affiliations',
  'session.course.institution.accreditations': 'institution.accreditations',
  'session.course.institution.learningManagementSystem': 'institution.learningManagementSystem',
  'session.course.institution.state': 'institution.state',
  'session.course.institution.country': 'institution.country',
  'session.course.hasPrerequisites': 'hasPrerequisites',
  'session.course.zeroTextbookCost': 'zeroTextbookCost',
  'session.course.hasFacultyCredentials': 'hasFacultyCredentials',
  'session.course.hasLearningAssessments': 'hasLearningAssessments',
  'session.course.courseSharingGroups.id': 'courseSharingGroups.id',
  'session.course.courseSharingGroups.name': 'courseSharingGroups.name',
  'session.course.division': 'division'
};

// `filterNames` (ARIA-related) are passed to `<InstantSearch/>`.
// Toggle filter refinement labels are the same as the filters' names.
// "Multi Select" filter refinement labels though don't have the actual filter name in them,
// and a "screen reader" user might not always get the correct context
// just by hearing the selected value of a "Multi Select" filter.
// That's the reason why `filterNames` are passed for such filters.
export const FILTER_NAMES = {
  'session.course.division': 'Course Division',
  'session.course.institution.consortiaNames': 'Consortium',
  'session.course.categories': 'Categories',
  'session.term': 'Term',
  'session.name': 'Session',
  'session.cost': 'Price',
  'session.course.level': 'Course Level',
  'session.course.hours': 'Credits',
  'session.course.institution.name': 'Teaching Institution',
  'session.course.institution.level': 'Institution Level',
  'session.course.institution.control': 'Institution Type',
  'session.course.institution.affiliations': 'Affiliations',
  'session.course.institution.accreditations': 'Accreditations',
  'session.course.institution.learningManagementSystem': 'LMS',
  'session.course.institution.state': 'State',
  'session.course.institution.country': 'Institution Country',
  'session.course.courseSharingGroups.id': 'Course Sharing Groups',
  'session.course.courseSharingGroups.name': 'Course Sharing Groups',
  'session.course.approvedBy': 'Approved',
  'session.course.onDemand': 'On-Demand',
  'session.course.institution.consortialEnrollingInstitutionIds': 'Consortial',
  'session.course.institution.id': 'My Teaching Courses',
  'session.course.institution.regionallyAccredited': 'Regionally Accredited',
  'session.course.institution.qualityMattersDesignation': 'QM Online Course Sharing',
  'session.course.hasPrerequisites': 'No Prerequisites',
  'session.course.hasFacultyCredentials': 'Has Faculty Credentials',
  'session.course.hasLearningAssessments': 'Has Learning Assessments',
  'session.course.zeroTextbookCost': 'Zero Textbook Cost'
};

// Not all filter components require a special ARIA label.
// For example, "toggles" don't seem to require one.
// (Perhaps because it's generated implicitly there)
// for (const attribute of Object.keys(COURSE_ATTRIBUTES)) {
//   if (!FILTER_NAMES[attribute]) {
//     reportError(`No filter name has been configured for attribute "${attribute}"`);
//   }
// }

export const SECTION_RANGES = [
  {
    type: 'currency',
    attribute: 'session.cost',
    title: 'Price',
    fromPlaceholder: 'Any From Price',
    toPlaceholder: 'Any To Price'
  }, {
    type: 'date',
    fromAttributeName: 'session.startDate',
    toAttributeName: 'session.earliestEndDate',
    title: 'Start & End Dates',
    fromPlaceholder: 'Any Start Date',
    toPlaceholder: 'Any End Date'
  }
];

// `sections` index is used for "get by id" operations.
// It is also sorted by course title ascending.
// This way, it can be reused to sort by "Course Title Ascending",
// saving some dollars, because Algolia charges about $0.9 per 1000 records,
// and in mid-2020 there're about 30,000 records which is about $30 per index,
// multiplied by 3 because there're 3 stages (`develop`, `demo`, `production`).
// https://github.com/Acadeum/Tickets/issues/344
export const SECTION_INDEXES_WITH_PRICE = [
  { value: 'sections', label: 'Course Title (A-Z)' },
  { value: 'sections_price_asc', label: 'Cost (Low to High)' },
  { value: 'sections_institution_asc', label: 'Institutions (A-Z)' },
  { value: 'sections_startDate_asc', label: 'Start Date (Earliest to Furthest)' },
  { value: 'sections_startDate_desc', label: 'Start Date (Furthest to Earliest)' }
];

// `public-sections` index is used for "get by id" operations (with prices hidden).
// It is also sorted by course title ascending.
// This way, it can be reused to sort by "Course Title Ascending",
// saving some dollars, because Algolia charges about $0.9 per 1000 records,
// and in mid-2020 there're about 30,000 records which is about $30 per index,
// multiplied by 3 because there're 3 stages (`develop`, `demo`, `production`).
// https://github.com/Acadeum/Tickets/issues/344
export const SECTION_INDEXES = [
  { value: 'public-sections', label: 'Course Title (A-Z)' },
  { value: 'public-sections_institution_asc', label: 'Institutions (A-Z)' }
];

// `courses` index is used for "get by id" operations.
// It is also sorted by course title ascending.
// This way, it can be reused to sort by "Course Title Ascending",
// saving some dollars, because Algolia charges about $0.9 per 1000 records,
// and in mid-2020 there're about 10,000 records which is about $10 per index,
// multiplied by 3 because there're 3 stages (`develop`, `demo`, `production`).
// https://github.com/Acadeum/Tickets/issues/344
export const COURSE_INDEXES_WITH_PRICE = [
  { value: 'courses', label: 'Course Title (A-Z)' },
  { value: 'courses_price_asc', label: 'Cost (Low to High)' },
  { value: 'courses_institution_asc', label: 'Institutions (A-Z)' },
  { value: 'courses_section_startDate_asc', label: 'Start Date (Earliest to Furthest)' },
  { value: 'courses_section_startDate_desc', label: 'Start Date (Furthest to Earliest)' }
];

// `public-courses` index is used for "get by id" operations.
// It is also sorted by course title ascending.
// This way, it can be reused to sort by "Course Title Ascending",
// saving some dollars, because Algolia charges about $0.9 per 1000 records,
// and in mid-2020 there're about 10,000 records which is about $10 per index,
// multiplied by 3 because there're 3 stages (`develop`, `demo`, `production`).
// https://github.com/Acadeum/Tickets/issues/344
export const COURSE_INDEXES = [
  { value: 'public-courses', label: 'Course Title (A-Z)' }
  // { value: 'public-courses_institution_asc', label: 'Institutions (A-Z)' }
];

export const DUMMY_SECTION = {
  id: 0,
  number: '',
  session: {
    id: 0,
    name: 'Session',
    term: 'Term',
    earliestStartDate: new Date(0),
    earliestEndDate: new Date(0),
    startDate: new Date(0),
    endDate: new Date(0),
    cost: 0,
    // scheduledBy: [],
    course: {
      id: 0,
      hours: 0,
      code: '',
      title: '',
      categories: [],
      approvedBy: [],
      customSchedules: {},
      facultyCredentials: [],
      learningAssessments: [],
      hasFacultyCredentials: false,
      hasLearningAssessments: false,
      onDemand: false,
      qualityMattersDesignation: false,
      syllabusUrl: 'https://example.com',
      institution: {
        id: 0,
        name: '',
        logoUrl: '',
        accreditations: []
      }
    }
  }
};
