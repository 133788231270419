import React from 'react';

import { Form as FormCoreUI } from '@acadeum/core-ui';
import type { FormProps as FormPropsCoreUI, FormRef } from '@acadeum/core-ui';
export type { OnSubmit } from '@acadeum/core-ui';

import { AppLoading } from '../AppLoading';
import { toast } from '../toast';

export type FormProps<V extends object = any> = Omit<FormPropsCoreUI<V>, 'loader' | 'notification'>;
export type { FormRef } from '@acadeum/core-ui';

export const Form = React.forwardRef((props: FormProps, ref: React.ForwardedRef<FormRef>) => {
  return (
    <FormCoreUI
      {...props}
      ref={ref}
      loading={AppLoading}
      notification={{
        loading: (message) => toast.loading(message),
        error: (message) => toast.error(message),
        toastDismiss: (id: number) => {
          toast.dismiss(id);
        }
      }}
    />
  );
});

/**
 * @param {Object} values
 * @param {String[]} fieldNames
 * @return {{ values, roleIds }}
 */
export function extractRoleIdsFromValues(values, fieldNames) {
  return fieldNames.reduce((result, name) => {
    if (typeof result.values[name] === 'number') {
      result.roleIds.push(result.values[name]);
      delete result.values[name];
    }
    if (typeof result.values[name] === 'string') {
      result.roleIds.push(Number(result.values[name]));
      delete result.values[name];
    }
    if (Array.isArray(result.values[name])) {
      if (result.values[name].length > 0) {
        if (typeof result.values[name][0] === 'number') {
          result.roleIds = result.roleIds.concat(result.values[name]);
        }
        if (typeof result.values[name][0] === 'string') {
          result.roleIds = result.roleIds.concat(result.values[name].map(Number));
        }
      }
      delete result.values[name];
    }
    if (typeof result.values[name] === 'boolean') {
      delete result.values[name];
    }
    return result;
  }, {
    values,
    roleIds: []
  });
}
