import type { RtkApi } from '@acadeum/helpers';
import type { Course, CourseEnrollment, Id, Institution, Section as SectionDefault, Student } from '@acadeum/types';

interface FetchTeachingStudentCourseEnrollmentsListItem extends Pick<CourseEnrollment, 
  'enrollReason' |
  'enrollReasonNotes' |
  'homeInstitutionId' |
  'letterGrade' | 
  'paid' |
  'startedAt' |
  'status'
>{
  course: Pick<Course, 'id' | 'hours' | 'code' | 'title'> & {
    sessions: Pick<SectionDefault, 'endDate' | 'lastAddDate' | 'lastDropDate' | 'name' |  'startDate' | 'term'> & {
      internalName?: string;
      sections: {number: string}[];
    }[];
  };
}

export interface FetchTeachingStudentCourseEnrollmentsOutput {
  page: number;
  pageSize: number;
  totalCount: number;
  results: FetchTeachingStudentCourseEnrollmentsListItem[];
}

export interface FetchTeachingStudentCourseEnrollmentsInput {
  id: Id;
  page?: number;
  pageSize?: number;
  filters?: {
    id?: Id;
    course?: string;
    type?: 'live' | 'accepted';
    teachingInstitution?: string;
    term?: string;
    session?: string;
    section?: string;
    startDate?: Date;
    endDate?: Date;
    grade?: string;
    status?: 'REQUESTED' | 'COMPLETED' | 'REMOVED' | 'DUE' | 'DROPPED' | 'WITHDRAWN' | 'DENIED' | 'ACTIVE' | 'PENDING';
  }
}

interface FetchTeachingStudentInput{
  id: Id;
}

export interface FetchTeachingStudentOutput extends Student {
  institution: Pick<Institution, 'name' | 'id' | 'vanityUrl' | 'logoUrl'>;
}

export const injectTeachingStudentsEndpoints = (rtkApi: RtkApi) => rtkApi
  .enhanceEndpoints({
    addTagTypes: ['teachingStudents', 'teachingStudentsEnrollments']
  })
  .injectEndpoints({
    endpoints: build => ({
      fetchTeachingStudent: build.query<FetchTeachingStudentOutput, FetchTeachingStudentInput>({
        query: ({ id })=>({
          url: `/students-TM/${id}`
        }),
        providesTags: (result, error, arg) => [{ type: 'teachingStudents', id: arg.id }]
      }),
      fetchTeachingStudentCourseEnrollments: build.query<FetchTeachingStudentCourseEnrollmentsOutput, FetchTeachingStudentCourseEnrollmentsInput>({
        query: ({ id, ...params })=>({
          url: `/students-TM/${id}/enrollments`,
          params
        }),
        providesTags: (result, error, arg) => [{ type: 'teachingStudentsEnrollments', id: arg.id }]
      })
    })
  });
