import type { Id } from '@acadeum/types';
import { AppLoading, Breadcrumbs } from '@acadeum/ui';

import type { AppPage } from '../../helpers/app.types';

import { Student } from '../Student';
import { useExportDataColumns } from '../Students/ui/TeachingStudents';

import { useFetchTeachingStudentQuery } from '../../api/teachingStudents';
import { formatName } from '../../helpers/format';

interface TeachingStudentProps {
  id: Id;
}

const TeachingStudent: AppPage<TeachingStudentProps> = ({ id }) => {
  const { data: student, isLoading } = useFetchTeachingStudentQuery({ id });

  return (
    <>
      <Breadcrumbs children={[
        ['Home', '/'],
        ['Teaching Students', '/students/teaching'],
        student ? `${formatName(student)}` : ''
      ]} />
      {!student || isLoading ? <AppLoading circle /> : (
        <Student
          type="TM"
          student={student}
          useExportDataColumns={useExportDataColumns}
        />
      )}
    </>
  );
};

TeachingStudent.meta = () => {
  return {
    title: 'Student TM'
  };
};

TeachingStudent.load = async ({ params }) => {
  return {
    props: params
  };
};

export default TeachingStudent;
