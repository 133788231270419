import React from 'react';
import type { SelectProps } from '../../Select';
import { Select } from '../../Select';

import type { BaseFilterProps } from '../context';
import { useRegisterFilter } from '../context';

export type SelectFilterProps<V> =  BaseFilterProps & SelectProps<V>;

export const SelectFilter = <V extends string>({
  name,
  label,
  multiple,
  getFilterLabel: propsGetFilterLabel,
  onChange: propsOnChange,
  ...rest
}: SelectFilterProps<V>) => {
  const getFilterLabel = (value, item) => {
    if (propsGetFilterLabel) {
      return propsGetFilterLabel(value, item);
    }
    if (multiple) {
      return item.options.find(_ => _.value === value)?.label;
    }
    return item.label;
  };

  const { option, onChange: onChange_ } = useRegisterFilter({ name, label, propsOnChange, getFilterLabel });

  const onChange = (value, option) => {
    onChange_(value, {
      [multiple ? 'options' : 'option']: option
    });
  };

  return (
    <Select
      {...rest}
      isFilter
      label={label}
      multiple={multiple}
      value={option ? option.value : undefined}
      onChange={onChange}
    />
  );
};
