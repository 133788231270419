import common from '../../../src/common.en.json';

import feeType from '../../../src/options/feeType.en.json';
import stripe from '../../../src/options/stripe.en.json';

const localization = {
  common,
  feeType,
  stripe
};

const locale = {
  'selection-options': localization
};

export default locale;
