import LABELS_US_EN from '../messages/en/CountryRegion.US.json' assert { type: 'json' };
import LABELS_CA_EN from '../messages/en/CountryRegion.CA.json' assert { type: 'json' };

const LABELS_EN = {
  US: LABELS_US_EN,
  CA: LABELS_CA_EN
};

export default function formatCountryRegion(country, region, { language }) {
  const LABELS = LABELS_EN;
  return (LABELS[country] && LABELS[country][region]) || region;
}
