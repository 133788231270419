import type { RtkApi } from '@acadeum/helpers';
import type { Id, Institution, SortByState, User, UserRole } from '@acadeum/types';

interface ChangeAdminInstitutionInput {
  institutionId: Institution['id'];
}

export interface FetchUserAdminOutput extends User {
  /**
   * Whether the user has set up their password
   * */
  hasPassword: boolean;
  /**
   * Whether the user's institution uses Single Sign On for authentication
   * */
  singleSignOn: boolean;
  roles: Pick<UserRole, 'name'>[];
  institution: Pick<Institution, 'id' | 'name' | 'vanityUrl'>;
}

interface FetchAdminUsersInput {
  page?: number;
  pageSize?: number;
  search?: string;
  sort?: SortByState;
  filters?: {
    roleId?: Id | Id[];
  };
}

type FetchAdminUsersListItem =
  Pick<User, 'id' | 'firstName' | 'lastName' | 'title' | 'email' | 'emailVerified' | 'isActive' | 'latestLoginDate'>
  & {
  hasPassword: boolean;
  singleSignOn: boolean;
  institution: Pick<Institution, 'id' | 'name' | 'vanityUrl'>;
  roles: Pick<UserRole, 'id' | 'name'>;
}

interface FetchAdminUsersOutput {
  page: number;
  pageSize: number;
  totalCount: number;
  results: FetchAdminUsersListItem[];
}

export const injectAdminUsersEndpoints = (rtkApi: RtkApi) => rtkApi
  .injectEndpoints({
    endpoints: build => ({
      adminChangeOwnInstitution: build.mutation<void, ChangeAdminInstitutionInput>({
        query: (body) => ({
          url: '/admin/users/institution',
          method: 'PUT',
          body
        })
      }),
      fetchUserAdmin: build.query<FetchUserAdminOutput, Id>({
        query: (id) => `/admin/users/${id}`
      }),
      fetchAdminUsers: build.query<FetchAdminUsersOutput, FetchAdminUsersInput>({
        query: (params) => ({
          url: '/admin/users',
          params
        })
      }),
      adminRequestUserEmailVerification: build.mutation<void, { id: Id }>({
        query: ({ id }) => ({
          url: `/admin/users/${id}/request-verify-email`,
          method: 'POST'
        })
      }),
      adminVerifyUserEmail: build.mutation<void, { id: Id }>({
        query: ({ id }) => ({
          url: `/admin/users/${id}/verify-email`,
          method: 'POST'
        })
      }),
      adminRequestUserPasswordReset: build.mutation<void, { id: Id }>({
        query: ({ id }) => ({
          url: `/admin/users/${id}/request-password-reset`,
          method: 'POST'
        })
      }),
      adminResetUserPassword: build.mutation<void, { id: Id, password: string }>({
        query: ({ id, password }) => ({
          url: `/admin/users/${id}/reset-password`,
          method: 'POST',
          body: { password }
        })
      })
    })
  });

export type UseAdminChangeOwnInstitutionMutation = ReturnType<typeof injectAdminUsersEndpoints>['useAdminChangeOwnInstitutionMutation'];
export type UseFetchUserAdminQuery = ReturnType<typeof injectAdminUsersEndpoints>['useFetchUserAdminQuery'];
export type UseFetchAdminUsersQuery = ReturnType<typeof injectAdminUsersEndpoints>['useFetchAdminUsersQuery'];
export type UseAdminRequestUserEmailVerificationMutation = ReturnType<typeof injectAdminUsersEndpoints>['useAdminRequestUserEmailVerificationMutation'];
export type UseAdminVerifyUserEmailMutation = ReturnType<typeof injectAdminUsersEndpoints>['useAdminVerifyUserEmailMutation'];
export type UseAdminRequestUserPasswordResetMutation = ReturnType<typeof injectAdminUsersEndpoints>['useAdminRequestUserPasswordResetMutation'];
export type UseAdminResetUserPasswordMutation = ReturnType<typeof injectAdminUsersEndpoints>['useAdminResetUserPasswordMutation'];
