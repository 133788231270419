import { useMemo, useState } from 'react';

import flatten from 'lodash/flatten';
import uniq from 'lodash/uniq';
import cloneDeep from 'lodash/cloneDeep';

import { DateRangePicker, Link, Select, Text } from '@acadeum/ui';

import { getEnrollableSessions } from '../../../../helpers/course';

import { CourseSection } from '../CourseSection';

import styles from './MultipleCourseSections.module.scss';

export function MultipleCourseSections({
  course,
  onRecommend
}) {
  const { onDemand } = course;

  const [term, setTerm] = useState();
  const [fromDate, setFromDate] = useState();
  const [toDate, setToDate] = useState();

  const { sections, terms } = useMemo(() => {
    const course_ = cloneDeep(course);

    // Set `session` for each `section`.
    for (const session of course_.sessions) {
      for (const section of session.sections) {
        section.session = session;
      }
    }

    let sessions = getEnrollableSessions(course_);

    if (term) {
      sessions = sessions.filter(_ => _.term === term);
    }

    if (fromDate) {
      sessions = sessions.filter(_ => _.startDate && _.startDate >= fromDate);
    }

    if (toDate) {
      sessions = sessions.filter(_ => _.startDate && _.startDate <= toDate);
    }

    const sections = flatten(sessions.map(session => session.sections.map(section => ({
      ...section,
      session,
      // This one is for `<CourseSection/>` convenience only.
      course
    }))));

    const terms = uniq(sessions.map(_ => _.term));

    return { sections, sessions, terms };
  }, [course, term, fromDate, toDate]);

  return (
    <div>
      {!onDemand && (
        <div className={styles.filters}>
          <Select
            placeholder="Term"
            value={term}
            onChange={setTerm}
            options={[{ label: 'All' }].concat(terms.map((term) => ({
              value: term,
              label: term
            })))}
          />
          <DateRangePicker
            style={{ flexGrow: 1 }}
            appearance="white"
            value={[fromDate, toDate]}
            onChange={([fromDate, toDate]) => {
              setFromDate(fromDate);
              setToDate(toDate);
            }}
          />
        </div>
      )}

      <section>
        <Text as="h4" variant="subtitle1" color="black" mb="sm">
          Course Section{sections.length === 1 ? '' : 's'}
        </Text>

        {sections.length === 0 ? (
          <div>
            {(term || fromDate || toDate)
              ? 'No sections found.'
              : 'No sections available.'
            }
            {' '}
            <Link to={`/courses/${course.id}/request-section`}>
              Request a section
            </Link>.
          </div>
        ) : (
          sections.map((section) => (
            <CourseSection
              key={section.id}
              section={section}
              session={section.session}
              course={course}
              onRecommend={onRecommend}
            />
          ))
        )}
      </section>
    </div>
  );
}
