import React from 'react';
import { useSelector } from 'react-redux';

import { Page } from '@acadeum/ui';
import { CourseSharingGroupPage } from '@acadeum/shared-admin-ui';
import type { ReduxState } from '../../helpers/app.types';
import { useTranslate } from '@acadeum/translate';

import actions from '../../actions';

const { fetchCourseSharingGroup } = actions;

const CourseSharingGroup = () => {
  const courseSharingGroup = useSelector((state: ReduxState) => state.courseSharingGroups.courseSharingGroup);

  const t = useTranslate('CourseSharingGroup');

  return (
    <Page
      title={t('title')}
      breadcrumbs={[
        [t('home', { global: true }), '/'],
        [t('adminTools', { global: true }), '/admin'],
        [t('courseSharingGroups'), '/admin/course-sharing-groups'],
        t('title')
      ]}
    >
      <CourseSharingGroupPage
        courseSharingGroup={courseSharingGroup}
      />
    </Page>
  );
};

CourseSharingGroup.load = async ({ params: { id } }) => {
  await fetchCourseSharingGroup(id);
};

export default CourseSharingGroup;
