import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';

import { useTranslate } from '@acadeum/translate';
import { getErrorData } from '@acadeum/helpers';

import Form from '../Form';
import FormField from '../FormField';
import FormHeader from '../FormHeader';
import FormTitle from '../FormTitle';
import FormDescription from '../FormDescription';
import FormFooter from '../FormFooter';
import FormSubmit from '../FormSubmit';

import actions from '../../actions';
import './RequestPasswordReset.scss';

const {
  requestPasswordReset,
  notifyError
} = actions;

export default function RequestPasswordReset ({
  onPasswordResetAttemptedOnSingleSignOnInstitution,
  onAfterSubmit
}) {
  const t = useTranslate('RequestPasswordReset');

  const [userNotFound, setUserNotFound] = useState();

  const onSubmit = async ({ email }) => {
    let error;
    try {
      await requestPasswordReset({ email });
    } catch (error_) {
      console.error(error_);
      error = getErrorData(error_);
    }
    if (error) {
      if (error.type === 'not_found') {
        return setUserNotFound(true);
      }
      if (error.code === 'single_sign_on_authentication_required') {
        return onPasswordResetAttemptedOnSingleSignOnInstitution();
      }
      console.error(error);
      return notifyError(t('errorVerbose', { global: true }));
    }
    onAfterSubmit();
  };

  const onEmailChange = useCallback(() => {
    setUserNotFound(false);
  }, []);

  return (
    <section className="RequestPasswordReset">
      <FormHeader>
        <FormTitle>
          {t('title')}
        </FormTitle>
        <FormDescription>
          {t('text')}
        </FormDescription>
      </FormHeader>

      <Form onSubmit={onSubmit} className="Form--alternativeFieldLabelStyle">
        <FormField
          required
          type="email"
          name="email"
          label={t('Login.form.email.label', { global: true })}
          placeholder={t('Login.form.email.hint', { global: true })}
          error={userNotFound ? t('Login.userNotFound', { global: true }) : undefined}
          onChange={onEmailChange}/>
        <FormFooter>
          <FormSubmit>
            {t('continue', { global: true })}
          </FormSubmit>
        </FormFooter>
      </Form>
    </section>
  );
}

RequestPasswordReset.propTypes = {
  onPasswordResetAttemptedOnSingleSignOnInstitution: PropTypes.func.isRequired,
  onAfterSubmit: PropTypes.func.isRequired
};
