import type { FC } from 'react';
import React, { useCallback, useMemo, useState } from 'react';
import { createColumnHelper } from '@tanstack/react-table';
import { useSelector } from 'react-redux';
import get from 'lodash/get';

import { FEE_TYPE_OPTIONS, optionsWithAllOption, PAYMENT_INTENT_STATUS_OPTIONS } from '@acadeum/selection-options';
import { getAuthSelector } from '@acadeum/auth';
import { useTranslate } from '@acadeum/translate';
import type { ActionsProps, TableExportDataColumns, TabsNavigationProps } from '@acadeum/ui';
import { Actions, DataBlock, Filters, FormatDate, Page, Table, TabsNavigation, Tag } from '@acadeum/ui';
import type {
  FetchPaymentIntentsInput,
  FetchPaymentIntentsOutputListItem,
  UseFetchInvoiceQuery,
  UseFetchPaymentIntentsQuery,
  UseLazyFetchPaymentIntentsQuery
} from '@acadeum/api';
import { useQueryWithPagination } from '@acadeum/hooks';

import { useLocation } from '../../providers/useLocation';
import { formatCurrency } from '../../helpers/format';
import { useSettingsRoutes } from '../../hooks/useSettingsRoutes';
import { useApp } from '../../providers/useApp';
import { useOnError } from '../../providers/useOnError';

import { InvoiceDetails } from './ui/InvoiceDetails';
import type { InvoiceDetailsProps } from './ui/InvoiceDetails/InvoiceDetails';

const columnHelper = createColumnHelper<FetchPaymentIntentsOutputListItem>();

const feeTypesOptions = optionsWithAllOption(FEE_TYPE_OPTIONS);
const paymentIntentStatusOptions = optionsWithAllOption(PAYMENT_INTENT_STATUS_OPTIONS);

function useColumns(useFetchInvoiceQuery: UseFetchInvoiceQuery) {
  const t = useTranslate('shared-admin-ui.financial');
  return useMemo(() => {
    const columns = [
      columnHelper.accessor((originalRow) => {
        const amount = originalRow.amount;
        return formatCurrency(amount, { cents: true, currency: 'USD' });
      }, {
        id: 'amount',
        header: t('invoiceAmount')
      }),
      columnHelper.accessor(originalRow => {
        const amountRefunded = originalRow.charge?.refundedAmount;
        return amountRefunded ? formatCurrency(amountRefunded, { cents: true, currency: 'USD' }) : null;
      }, {
        id: 'amountRefunded',
        header: t('amountRefunded')
      }),
      columnHelper.accessor('type', {
        header: t('transactionType'),
        cell: ({ getValue }) => t(`paymentTypes.${getValue()}`, {
          defaultMessage: getValue()
        })
      }),
      columnHelper.accessor((originalRow) => originalRow.type === 'ANNUAL_FEE'
        ? 'Acadeum'
        : originalRow.institution?.name, {
        id: 'counterparty',
        header: t('counterparty'),
        cell: ({ row }) => {
          return row.original.type === 'ANNUAL_FEE'
            ? t('acadeum')
            : row.original.institution ? <DataBlock type="institution" institution={row.original.institution}/> : null;
        }
      }),
      columnHelper.accessor('invoice.createdAt', {
        header: t('invoiceDate'),
        cell: ({ getValue }) => {
          return (
            <FormatDate
              utc={false}
              date={getValue()}
              month="short"
              day="numeric"
            />
          );
        }
      }),
      columnHelper.accessor('status', {
        header: t('paymentStatus'),
        cell: ({ getValue }) => <Tag variant={getValue()}/>
      }),
      columnHelper.accessor('charge.paymentMethod.type', {
        header: t('paymentMethod'),
        cell: ({ getValue }) => t(`paymentMethodTypes.${getValue()}`, {
          defaultMessage: getValue()
        })
      }),
      columnHelper.accessor('charge.id', {
        header: t('hiPaymentId')
      }),
      columnHelper.accessor('invoice.id', {
        header: t('stripeInvoiceId')
      }),
      columnHelper.accessor('description', {
        header: t('description')
      }),
      columnHelper.display({
        id: 'actions',
        cell: ({ row, downloadRow }) => (
          <ActionsCell
            downloadRow={downloadRow}
            originalRow={row.original}
            useFetchInvoiceQuery={useFetchInvoiceQuery}
          />
        )
      })
    ];

    const exportDataColumns = columns.reduce<TableExportDataColumns<'xlsx', FetchPaymentIntentsOutputListItem>>((res,
      column) => {
      if (column.id === 'actions') {
        return res;
      }

      if (typeof column.header !== 'string') {
        throw new Error(`Invalid column header: ${column.header}`);
      }

      res.push({
        title: column.header,
        value: row => {
          if (typeof column['accessorKey'] === 'string') {
            return get(row, column['accessorKey']);
          } else if (typeof column['accessorFn'] === 'function') {
            return column['accessorFn'](row);
          }
          throw new Error(`Invalid column accessor: ${column.header}`);
        }
      });

      return res;
    }, []);

    return { columns, exportDataColumns };
  }, [t, useFetchInvoiceQuery]);
}

export interface EnrollingPaymentHistoryPageProps extends Pick<InvoiceDetailsProps, 'useFetchInvoiceQuery'> {
  useFetchPaymentIntentsQuery: UseFetchPaymentIntentsQuery;
  useLazyFetchPaymentIntentsQuery: UseLazyFetchPaymentIntentsQuery;
}

export const EnrollingPaymentHistoryPage: FC<EnrollingPaymentHistoryPageProps> = ({
  useFetchInvoiceQuery,
  useFetchPaymentIntentsQuery,
  useLazyFetchPaymentIntentsQuery
}) => {
  const t = useTranslate('shared-admin-ui.financial');
  const { getSettingsUrl, getPaymentHistoryUrl, getTeachingPaymentHistoryUrl } = useSettingsRoutes();
  const { app } = useApp();
  const onError = useOnError();

  const location = useLocation();

  const { columns, exportDataColumns } = useColumns(useFetchInvoiceQuery);

  const fetchDataForExport = useFetchDataForExport(useLazyFetchPaymentIntentsQuery);

  const {
    data,
    error,
    isFetching,
    _globalFilterOptions,
    _paginationOptions,
    _filtersOptions
  } = useQueryWithPagination(useFetchPaymentIntentsQuery, {
    search: location.query.search as string
  });

  const currentUser = useSelector(getAuthSelector('user'));

  const menuItems = useMemo(() => {
    const menuItems: TabsNavigationProps['menuItems'] = [
      {
        title: t('enrollingPayments'),
        url: getPaymentHistoryUrl(),
        active: true
      }
    ];

    if (currentUser?.institution.teaching) {
      menuItems.push({
        title: t('teachingPayments'),
        url: getTeachingPaymentHistoryUrl()
      });
    }

    return menuItems;
  }, [currentUser, getPaymentHistoryUrl, getTeachingPaymentHistoryUrl]);

  if (error) {
    return onError(error);
  }

  return (
    <Page
      title={t('paymentHistory')}
      breadcrumbs={[
        [t(app === 'admin' ? 'general' : 'settings', { global: true }), getSettingsUrl()],
        t('paymentHistory')
      ]}
    >
      <TabsNavigation menuItems={menuItems}/>

      <Filters
        border
        values={_filtersOptions.filters}
        onFiltersChange={(newValue) => {
          // @ts-expect-error
          if (newValue.type === 'ANNUAL_FEE') {
            // @ts-expect-error
            delete newValue.institutionIds;
          }
          // @ts-expect-error
          if (newValue?.institutionIds?.length === 0) {
            // @ts-expect-error
            delete newValue?.institutionIds;
          }
          _filtersOptions.onFiltersChange(newValue);
        }}
      >
        <Filters.Row>
          <Filters.Select
            name="type"
            label={t('transactionType')}
            getFilterLabel={(value) => t(`paymentTypes.${value}`, {
              defaultMessage: value
            })}
            options={feeTypesOptions}
          />
          {_filtersOptions.filters.type !== 'ANNUAL_FEE' && (
            <Filters.Institution
              multiple
              type="institution"
              name="institutionIds"
              label="Counterparty"
            />
          )}
          <Filters.Select
            name="status"
            label="Status"
            options={paymentIntentStatusOptions}
          />
          <Filters.DateRange
            utc
            name="invoiceCreatedAt"
            label="Invoice Date"
          />
        </Filters.Row>
      </Filters>
      <Table
        enableRowSelection
        loading={isFetching}
        globalFilterOptions={_globalFilterOptions}
        paginationOptions={_paginationOptions}
        columns={columns}
        data={data?.results}
        exportOptions={{
          type: 'xlsx',
          fileName: 'enrolling_payments',
          exportDataColumns,
          fetchDataForExport
        }}
        translate={{
          searchPlaceholder: t('paymentHistoryPage.searchByPaymentId'),
          resultText: ({ totalCount }) => t('paymentHistoryPage.resultText', { totalCount }),
          selectedResultText: ({ totalCount, selectedRowsCount }) => t('paymentHistoryPage.selectedResultText', {
            totalCount,
            selectedRowsCount
          })
        }}
      />
    </Page>
  );
};

interface ActionsCellProps {
  downloadRow: () => void;
  originalRow: FetchPaymentIntentsOutputListItem;
  useFetchInvoiceQuery: UseFetchInvoiceQuery;
}

const ActionsCell: FC<ActionsCellProps> = ({
  originalRow,
  downloadRow,
  useFetchInvoiceQuery
}) => {
  const [showInvoiceDetails, setShowInvoiceDetails] = useState(false);

  const actions = useMemo(() => {
    const actions: ActionsProps['actions'] = [
      {
        title: 'Download',
        onClick: () => downloadRow()
      }
    ];

    if (originalRow.invoice?.id) {
      actions.push({
        title: 'See Invoice Details',
        onClick: () => setShowInvoiceDetails(true)
      });
    }

    return actions;
  }, [downloadRow, originalRow.invoice?.id, setShowInvoiceDetails]);

  return (
    <>
      <Actions
        variant="kebab"
        actions={actions}
      />

      {showInvoiceDetails && originalRow.invoice?.id && (
        <InvoiceDetails
          source="payments"
          useFetchInvoiceQuery={useFetchInvoiceQuery}
          invoiceId={originalRow.invoice.id}
          onHide={() => setShowInvoiceDetails(false)}
        />
      )}
    </>
  );
};

function useFetchDataForExport(useLazyFetchPaymentIntentsQuery: UseLazyFetchPaymentIntentsQuery) {
  const [lazyFetchPaymentIntentsQuery] = useLazyFetchPaymentIntentsQuery();

  return useCallback(async (ids) => {
    const pageSize = 1000;
    let page = 1;
    let allRecords: FetchPaymentIntentsOutputListItem[] = [];

    function fetchRecords() {
      const query: FetchPaymentIntentsInput = { page, pageSize };

      if (ids.length > 0) {
        query.filters = {};
        query.filters.ids = ids;
      }

      return lazyFetchPaymentIntentsQuery(query).unwrap().then((response) => {
        allRecords = allRecords.concat(response.results);
        page += 1;

        if (response.results.length === pageSize) {
          return fetchRecords();
        } else {
          return allRecords;
        }
      });
    }

    return await fetchRecords();
  }, [lazyFetchPaymentIntentsQuery]);
}
