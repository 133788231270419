import React, { memo } from 'react';
import classNames from 'classnames';

import { InfoTooltip } from '../InfoTooltip';
import type { ActionButtonProps } from '../ActionButton';
import { ActionButton } from '../ActionButton';

import type { MarginsProp } from '../../utils/useMargin';
import { useMargin } from '../../utils/useMargin';

import styles from './Label.module.scss';

export interface LabelProps extends Pick<MarginsProp, 'mb'> {
  id?: string;
  htmlFor?: string;
  children?: React.ReactNode;
  hidden?: boolean;
  required?: boolean;
  action?: ActionButtonProps;
  tooltip?: string;
  className?: string;
}

export const Label: React.FC<LabelProps> = memo(({
  htmlFor,
  hidden,
  action,
  tooltip,
  required,
  children,
  mb,
  className,
  id
}) => {
  const { marginClassNames } = useMargin({ mb });

  return (
    <div className={classNames(className, styles.root, marginClassNames, {
      [styles.hidden]: hidden
    })}>
      <label
        id={id}
        htmlFor={htmlFor}
        className={classNames(styles.text, required && styles.requiredIndicator)}
      >
        {children}
        {tooltip && (
          <InfoTooltip ariaTitle={children as string}>
            {tooltip}
          </InfoTooltip>
        )}
      </label>
      {action && (
        <ActionButton
          size="small"
          variant="text-with-padding"
          {...action}
          className={classNames(styles.action, action.className)}
        />
      )}
    </div>
  );
});
