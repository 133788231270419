import type { RtkApi } from '@acadeum/helpers';
import type { Course, CoursePricing, CreateCourseResource, Id, Institution, Section, Session } from '@acadeum/types';

export interface CreateCourseInput extends Pick<Course,
  'code' |
  'title' |
  'description' |
  'level' |
  'hours' |
  'prerequisitesText' |
  'zeroTextbookCost' |
  'courseMaterialISBNs' |
  'courseMaterialTitles' |
  'deliveryMethod' |
  'location' |
  'additionalCosts' |
  'certificates' |
  'notes' |
  'cost' |
  'onDemand'
> {
  categories: string[];
  resources?: CreateCourseResource[];
  // Sessions and sections will be merged soon
  sections: (Pick<Section, 'name' | 'cost'> & Partial<Pick<Section, 'schedule' | 'startDate' | 'endDate' | 'lastAddDate' | 'lastDropDate'>>)[];
}

export interface CreateCourseOutput {
  id: Course['id'];
  sections?: {
    id: Section['id']
  }[];
}

type Section_ = Pick<Section, 'id'> & {
  customCosts: {
    cost: number
  }[];
};

type Session_ = Pick<Session, 'id' | 'name' | 'term' | 'cost' | 'startDate'> & {
  sections: Section_[];
};

type Course_ = Pick<Course, 'id' | 'code' | 'hours' | 'level' | 'title'> & {
  institution: Pick<Institution, 'id' | 'name'>;
  sessions: Session_[];
};

export interface FetchApprovedCoursesWithPricingOutput {
  courses: Course_[];
  courseEnrollmentPricing?: CoursePricing;
  homeStudentCourseEnrollmentPricing?: CoursePricing;
}

interface FetchApprovedCoursesWithPricingInput {
  institutionId?: Id;
}

export const injectCoursesEndpoints = (rtkApi: RtkApi, { overrideExisting = false } = {}) => rtkApi
  .injectEndpoints({
    overrideExisting,
    endpoints: build => ({
      createCourse: build.mutation<CreateCourseOutput, CreateCourseInput>({
        query: (body) => ({
          url: '/courses',
          method: 'POST',
          body
        })
      }),
      fetchApprovedCoursesWithPricing: build.query<FetchApprovedCoursesWithPricingOutput, FetchApprovedCoursesWithPricingInput>({
        query: (params) => ({
          url: '/approved-courses-with-pricing',
          params
        })
      })
    })
  });

export type UseCreateCourseMutation = ReturnType<typeof injectCoursesEndpoints>['useCreateCourseMutation'];
