import type { Id } from '@acadeum/types';

import { isObject } from './isObject';

type HttpStatusCode = 401 | 403 | 404 | 409 | 422 | 429 | 500 | 503 | number;

interface ErrorTypeByCode {
  401: 'authentication_error';
  403: 'unauthorized';
  404: 'not_found';
  409: 'conflict';
  422: 'invalid_request';
  429: 'rate_limit';
  500: 'error'; // Default
  503: 'maintenance';
}

interface ErrorData {
  /** The error message */
  message: string;
  /** Error type */
  type: ErrorTypeByCode[keyof ErrorTypeByCode];
  /** Available error `code`s: server-lib/utility/errors.js */
  code?: string;
  /** If the error is related to a specific input field */
  field?: string;
  /** If the error is related to a specific input field value */
  value?: any;
  /** entity or model */
  subject?: string;
  /** entity or model id */
  subjectId?: Id;
  /** entity or model ids */
  subjectIds?: Id[];
  /** when `errors` is provided, that's the array of errors */
  errors?: Omit<ErrorData, 'errors'>[];

  // unknown properties (Ex: 'contacts' in 'user_account_request_pending')
  [key: string]: unknown;
}

interface HttpError extends Error {
  status: HttpStatusCode;
  data: ErrorData;
}

export const isHttpError = (error: unknown): error is HttpError => {
  return isObject(error) && 'status' in error && 'data' in error;
};
