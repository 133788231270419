import invoiceStatuses from 'common-lib/constants/stripe/invoiceStatuses.json';
import chargeStatuses from 'common-lib/constants/stripe/chargeStatuses.json';
import paymentIntentStatuses from 'common-lib/constants/stripe/paymentIntentStatuses.json';

import { t } from '../../translate';

export const INVOICE_STATUS_OPTIONS = invoiceStatuses.map(invoiceStatus => ({
  value: invoiceStatus,
  label: t(`selection-options.stripe.invoiceStatuses.${invoiceStatus}`)
}));

export const CHARGE_STATUS_OPTIONS = chargeStatuses.map(chargeStatus => ({
  value: chargeStatus,
  label: t(`selection-options.stripe.chargeStatuses.${chargeStatus}`)
}));

export const PAYMENT_INTENT_STATUS_OPTIONS = paymentIntentStatuses.map(paymentIntentStatus => ({
  value: paymentIntentStatus,
  label: t(`selection-options.stripe.paymentIntentStatuses.${paymentIntentStatus}`)
}));
