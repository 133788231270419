import React from 'react';
import classNames from 'classnames';
import { useSelector } from 'react-redux';

import getLocationUrl from 'common-lib/lib/getLocationUrl';

import { getAuthSelector } from '@acadeum/auth';
import type { IconSource } from '@acadeum/types';
import { Button, HamburgerButton, Link, Loader, Separator, useScreenSize, HStack, Text } from '@acadeum/ui';
import { __STUDENTS_SITE_URL__ } from '@acadeum/consts';

import { InstitutionMenu } from '../InstitutionMenu';
import { BentoBox } from '../BentoBox';
import { UserMenu } from '../UserMenu';

import { useAppTemplateContext } from '../context';

import styles from './Header.module.scss';

export interface PageHeaderProps {
  className?: string;
  isLoading?: boolean;
  narrow?: boolean;
  Logo: IconSource;
  // Temporary solution for https://acadeum.atlassian.net/browse/RC-6
  hideInstitutionAndBentoBoxAndUserMenu?: boolean;
  // CourseShare should render student login link for HS4CC student flow
  // https://acadeum.atlassian.net/browse/CS-449
  renderStudentLoginLinkForHS4CC?: boolean;
}

const studentHS4CCLoginUrl = getLocationUrl({
  origin: __STUDENTS_SITE_URL__,
  pathname: '/login',
  query: {
    hs4cc: '✓'
  }
});

const studentHS4CCSignUpUrl = getLocationUrl({
  origin: __STUDENTS_SITE_URL__,
  pathname: '/sign-up',
  query: {
    hs4cc: '✓'
  }
});

export const Header: React.FC<PageHeaderProps> = ({
  Logo,
  isLoading,
  className,
  narrow,
  renderStudentLoginLinkForHS4CC,
  hideInstitutionAndBentoBoxAndUserMenu
}) => {
  const { user, isAuthenticationLoading } = useSelector(getAuthSelector);
  const { isSmallScreen } = useScreenSize();
  const {
    expanded,
    toggleExpanded,
    buttonRef,
    loginUrl,
    additionalMenu,
    userMenu,
    useAdminChangeOwnInstitutionMutation
  } = useAppTemplateContext();

  return (
    <header
      className={classNames(className, styles.Header, {
        [styles.narrow]: narrow
      })}
    >
      {Logo && (
        <Link
          className={styles.Logo}
          to="/"
          aria-label="Homepage"
          monochrome
        >
          <Logo focusable="false" aria-hidden="true"/>
        </Link>
      )}

      {!hideInstitutionAndBentoBoxAndUserMenu && isSmallScreen && user && (
        <BentoBox.Mobile user={user}/>
      )}

      {isSmallScreen && (
        <HamburgerButton
          ref={buttonRef}
          onClick={toggleExpanded}
          expanded={expanded}
        />
      )}

      {isSmallScreen === false && (
        <div className={styles.Header__inner}>
          <nav aria-label="External links to support and community services">
            <ul className={styles.Header__nav}>
              {additionalMenu?.map(({ title, url, external, onClick }) => (
                <li key={title}>
                  <Link
                    monochrome
                    to={url}
                    external={external}
                    onClick={onClick}
                  >
                    {title}
                  </Link>
                </li>
              ))}

              {additionalMenu && (!user || !hideInstitutionAndBentoBoxAndUserMenu) && (
                <li style={{ alignSelf: 'stretch' }}>
                  <Separator m="none" orientation="vertical"/>
                </li>
              )}

              {user ? (
                !hideInstitutionAndBentoBoxAndUserMenu && (
                  <>
                    <InstitutionMenu
                      as="li"
                      useAdminChangeOwnInstitutionMutation={useAdminChangeOwnInstitutionMutation}
                      user={user}
                    />
                    <li style={{ alignSelf: 'stretch' }}>
                      <Separator m="none" orientation="vertical"/>
                    </li>
                    <li>
                      <BentoBox user={user}/>
                    </li>
                    <li>
                      <UserMenu
                        user={user}
                        actions={userMenu}
                      />
                    </li>
                  </>
                )
              ) : (
                renderStudentLoginLinkForHS4CC ? (
                  <HStack as="li" gap="md">
                    <Text variant="subtitle2">
                      <Link to={studentHS4CCLoginUrl}>
                        Log In
                      </Link>
                    </Text>
                    <Button url={studentHS4CCSignUpUrl}>
                      Sign Up
                    </Button>
                  </HStack>
                ) : (
                  <li>
                    <Button url={loginUrl}>
                      Login
                    </Button>
                  </li>
                )
              )}
            </ul>
          </nav>
        </div>
      )}

      {(isLoading || isAuthenticationLoading) && (
        <Loader
          inline
          variant="linear"
          className={styles.Loader}
        />
      )}
    </header>
  );
};
