import type { FC } from 'react';
import React, { useState } from 'react';
import type { RowSelectionState } from '@tanstack/react-table';

import { useTranslate } from '@acadeum/translate';

import { getEnrollmentTypeLabel, getErrorData } from '@acadeum/helpers';
import type { FetchTeachingStudentOutput } from '@acadeum/api';

import type { ModalProps, OnSubmit as OnSubmitUI } from '@acadeum/ui';
import type { Id } from '@acadeum/types';
import {
  Accordion,
  Button,
  DataBlock,
  exportTableData,
  FormSubmit,
  HStack,
  Link,
  Loader,
  Modal,
  Tag,
  Text,
  toast
} from '@acadeum/ui';

import InfoStudent from '../InfoStudent';
import SectionDate from '../SectionDate';
import StudentsTable from '../StudentsTable';
import ModalDeny from '../ModalDeny';

import { useRefreshNotifications } from '../../../../api/notifications';
import { useExportDataColumns } from '../../../Students/ui/HomeStudents';

import actions from '../../../../actions';

import styles from './ModalInfo.module.scss';
import { OnDemandBadge } from '../../../../components/CourseBadges/ui/OnDemandBadge';

import { useLazyFetchTeachingStudentQuery } from '../../../../api/teachingStudents';

const {
  approveStudentEnrollments,
  denyStudentEnrollments,
  fetchTeachingEnrollmentRequest,
  fetchEnrollmentRequestsTM,
  goto
} = actions;

const LINK_PAGE_MANAGE_ENROLLMENTS = 'enrollments/live';

interface ModalInfoProps extends Pick<ModalProps, 'onHide' | 'show'> {
  downloadRow: () => Promise<void>;
  // eslint-disable-next-line
  data: any;
}

interface FormValueDenySelectedEnrollments {
  reason?: string;
  reasonNotes?: string;
}

type OnSubmit = (values: FormValueDenySelectedEnrollments & {
  action: (ids: Id[], options: FormValueDenySelectedEnrollments & {
    enrollmentRequestId: Id;
  }) => void;
}) => Promise<void>;

const ModalInfo: FC<ModalInfoProps> = ({ show, data, onHide, downloadRow }) => {
  const t = useTranslate('EnrollmentRequests');

  const onDemand = data.section.session.course.onDemand;

  const [student, setStudent] = useState<FetchTeachingStudentOutput>();
  const [loaderStudent, setLoaderStudent] = useState<boolean>();
  const [showDenyPopup, setShowDenyPopup] = useState<boolean>();

  const studentEnrollments = getNonProcessedEnrollments(data);
  const studentEnrollmentsReviewed = data.enrollments.filter(_ => !studentEnrollments.includes(_));

  const [checkedRows, setCheckedRows] = useState<RowSelectionState[]>(studentEnrollments.reduce((acc, obj) => {
    acc[obj.id] = true;
    return acc;
  }, {}));

  const exportDataColumns = useExportDataColumns();

  const onCloseStudent = () => {
    setStudent(undefined);
    setLoaderStudent(false);
  };

  const [lazyFetchTeachingStudentQuery] = useLazyFetchTeachingStudentQuery();

  const onShowInfoStudent = async (student) => {
    setLoaderStudent(true);
    try {
      const studentInfo = await lazyFetchTeachingStudentQuery({ id: student.id }).unwrap();
      setStudent(studentInfo);
    } finally {
      setLoaderStudent(false);
    }
  };

  const enrollmentRequest = [
    ...(onDemand ? [] : [
      {
        title: t('courseDates'),
        content: (
          <>
            <DataBlock
              utc
              type="date"
              month="long"
              as="span"
              date={data.section.session.startDate}
            />
            {' - '}
            <DataBlock
              utc
              type="date"
              month="long"
              as="span"
              date={data.section.session.endDate}
            />
          </>
        )
      },
      {
        title: t('addDate'),
        content: (
          <DataBlock
            utc
            type="date"
            month="long"
            date={data.section.session.lastAddDate}
          />
        )
      },
      {
        title: t('dropDate'),
        content: (
          <DataBlock
            utc
            type="date"
            month="long"
            date={data.section.session.lastDropDate}
          />
        )
      }
    ]),
    {
      title: t('course'),
      content: data.section.session.course.title
    },
    ...(onDemand ? [] : [
      {
        title: t('term'),
        content: data.section.session.term
      },
      {
        title: t('session'),
        content: data.section.session.name
      }
    ]),
    {
      title: t('homeInstitution'),
      content: <DataBlock hideLogo type="institution" institution={data.institution} />
    }
  ];

  const onSubmit: OnSubmit = async ({ action, reason, reasonNotes }) => {
    try {
      const ids = Object.keys(checkedRows).map(_ => Number(_));
      await action(ids, {
        reason,
        reasonNotes,
        enrollmentRequestId: data.id
      });
      // refetch list
      await fetchEnrollmentRequestsTM();
    } catch (err) {
      console.error(err);
    }
  };

  const resetSelection = () => {
    setCheckedRows([]);
  };

  const refreshNotifications = useRefreshNotifications();

  const refresh = async () => {
    refreshNotifications();
    await fetchTeachingEnrollmentRequest(data.id);
  };

  const onAfterSubmit = async (actionType) => {
    const count = Object.keys(checkedRows).length;
    const hasMore = getNonProcessedEnrollments(data).length > count;
    resetSelection();
    switch (actionType) {
      case 'approve':
        toast.success(t(count === 1 ? 'approveMessageSuccess' : 'approveMessageSuccessMany', {
          link: (children) => (
            <Link
              className={styles['ModalInfo__link-toast']}
              to={LINK_PAGE_MANAGE_ENROLLMENTS}
              removeUnderline={false}
            >
              {children}
            </Link>
          ),
          number: count
        }));
        break;
      case 'deny':
        toast.success(t(count === 1 ? 'denyMessageSuccess' : 'denyMessageSuccessMany', {
          link: (children) => (
            <Link
              className={styles['ModalInfo__link-toast']}
              to={LINK_PAGE_MANAGE_ENROLLMENTS}
              removeUnderline={false}
            >
              {children}
            </Link>
          ),
          number: count
        }));
        break;
    }
    await refresh();
    onHide(false);
    if (!hasMore) {
      goto('/enrollment-requests');
    }
  };

  const onAfterSubmitApproveSelectedEnrollments = async () => {
    try {
      await onAfterSubmit('approve');
    } catch (error) {
      console.log('error', error);
    }
  };

  const onApproveSelectedEnrollments = async () => {
    if (Object.keys(checkedRows).length === 0) {
      return toast.warn('Select some students first');
    }
    try {
      await onSubmit({ action: approveStudentEnrollments });
      await onAfterSubmitApproveSelectedEnrollments();
    } catch (err) {
      const { message } = getErrorData(err);
      toast.warn(message);
    }
  };

  const onDenySelectedEnrollments = () => {
    if (Object.keys(checkedRows).length === 0) {
      return toast.warn('Select some students first');
    }
    setShowDenyPopup(true);
  };

  const onCloseDeny = () => {
    setShowDenyPopup(false);
  };

  const onSubmitDenySelectedEnrollments: OnSubmitUI<FormValueDenySelectedEnrollments> = async (values) => {
    try {
      await onSubmit({ action: denyStudentEnrollments, ...values });
      await onAfterSubmit('deny');
    } catch (err) {
      const { message } = getErrorData(err);
      toast.warn(message);
    }
  };

  if (showDenyPopup) {
    return (
      <ModalDeny
        onHide={onCloseDeny}
        data={data}
        onSubmit={onSubmitDenySelectedEnrollments}
        checkedRows={checkedRows}
      />
    );
  }

  const exportDataStudent = async () => {
    try {
      await exportTableData({
        type: 'xlsx',
        fileName: t('student'),
        exportDataColumns,
        data: [student]
      });
    } catch (err) {
      const error = getErrorData(err);
      toast.warn(error.message);
    }
  };

  return (
    <Modal
      show={show}
      size="wide"
      onSubmit={studentEnrollments.length > 0 ? onApproveSelectedEnrollments : undefined}
      onHide={student ? onCloseStudent : onHide}
      onBack={student && onCloseStudent}
      title={student ? t('student') : t('title')}
      actions={[
        ...((studentEnrollments.length > 0 && student) ? [
          {
            title: t('denyStudent'),
            onClick: () => {
              const newCheckedRows = studentEnrollments.filter(_ => _.student.id === student.id).reduce((acc, obj) => {
                acc[obj.id] = true;
                return acc;
              }, {});
              setCheckedRows(newCheckedRows);
              setShowDenyPopup(true);
            }
          }
        ] : []),
        {
          title: t('downloadInformation'),
          onClick: student ? exportDataStudent : downloadRow
        }
      ]}
    >
      <Modal.Body>
        {loaderStudent ? (
          <Loader />
        ) : (
          <>
            {student ? (
              <InfoStudent data={student} />
            ) : (
              <div className={styles.ModalInfo__block}>
                <HStack gap="sm" mb="lg">
                  <Text as="h3" variant="subtitle2">
                    {t('titleModal')}
                  </Text>
                  <Tag>{getEnrollmentTypeLabel(data.enrollments[0])}</Tag>
                  {onDemand && (
                    <OnDemandBadge />
                  )}
                </HStack>
                {enrollmentRequest.map((_, index) => (
                  <SectionDate title={_.title} key={index}>
                    {_.content}
                  </SectionDate>
                ))}
                <Accordion
                  defaultOpen
                  title={`${studentEnrollments.length || studentEnrollmentsReviewed.length} ${(studentEnrollments.length > 1 || studentEnrollmentsReviewed.length > 1)
                    ? 'students'
                    : 'student'}`}
                >
                  <StudentsTable
                    data={studentEnrollments.length > 0 ? studentEnrollments : studentEnrollmentsReviewed}
                    enableRowSelection={studentEnrollments.length > 0}
                    checkedRows={checkedRows}
                    setCheckedRows={setCheckedRows}
                    onShowInfoStudent={onShowInfoStudent}
                  />
                </Accordion>
              </div>
            )}
          </>
        )}
      </Modal.Body>
      {studentEnrollments.length > 0 && !student && (
        <Modal.Footer>
          <Button onClick={onDenySelectedEnrollments} variant="secondary">
            {t('deny')}
          </Button>
          <FormSubmit>
            {t('accept')}
          </FormSubmit>
        </Modal.Footer>
      )}
    </Modal>
  );
};

export default ModalInfo;

export const getNonProcessedEnrollments = (request) => {
  return request.enrollments.filter(_ => _.status === 'REQUESTED');
};
