import React, { Component } from 'react';
import PropTypes from 'prop-types';
import readXlsxFile, { parseExcelDate } from 'read-excel-file';
import { DropFileUpload } from 'react-responsive-ui';
import { FormatDate, Table } from '@acadeum/ui';
import classNames from 'classnames';

import { getErrorData } from '@acadeum/helpers';

import Field from '../Field';
import Form from '../Form';
import FormSubmit from '../FormSubmit';

import './DataUploadPage.sass';

import actions from '../../actions';

const {
  notify,
  notifyError
} = actions;

export default class DataUploadPage extends Component {
  static propTypes = {
    onUpload: PropTypes.func.isRequired,
    schema: PropTypes.object.isRequired,
    transform: PropTypes.func.isRequired,
    getColumnSchema: PropTypes.func,
    successMessage: PropTypes.string
  };

  static defaultProps = {
    transform: () => {}
  };

  state = {};

  constructor() {
    super();
    this.onUpload = this.onUpload.bind(this);
    this.onFileChosen = this.onFileChosen.bind(this);
  }

  async onFileChosen(file) {
    const { schema, transform, getColumnSchema } = this.props;

    this.setState({
      file: undefined,
      errors: undefined,
      rows: undefined,
      tableData: undefined,
      tableColumns: undefined,
      ignoredColumns: undefined
    });

    let tableData;
    let errors;
    let rows;

    try {
      // Data for `react-table`.
      tableData = await readXlsxFile(file);

      const parsed = await readXlsxFile(file, {
        schema,
        includeNullValues: true,
        transformData(data) {
          // Remove trailing `*` from `required` columns' titles.
          // https://github.com/Acadeum/Tickets/issues/1217
          if (data.length > 0) {
            data[0] = data[0].map(title => title.replace(/\*$/, ''));
          }
          return data;
        }
      });

      errors = parsed.errors;
      rows = parsed.rows;

      transform(rows);
    }
    catch (error) {
      console.error(error);
      return notifyError('Couldn\'t read the file');
    }

    let headerRow = tableData.length > 0 ? tableData.shift() : [];

    // Remove trailing `*` from `required` columns' titles.
    // https://github.com/Acadeum/Tickets/issues/1217
    headerRow = headerRow.map(title => title.replace(/\*$/, ''));

    if (schema['INSTITUTION ID'] && headerRow.indexOf('INSTITUTION ID') < 0 && headerRow.indexOf('institutionId') < 0) {
      return notify((
        <div>
          <h3>Incorrect header row.</h3>
          <br/>
          <p>Expected the first row to contain column headers, e.g. "INSTITUTION ID" or "institutionId".</p>
        </div>
      ));
    }

    if (rows.length === 0) {
      return notify('No data');
    }

    // Debugging.
    // console.log('Parsed Excel Rows', rows);

    this.setState({
      file,
      errors,
      rows,
      tableData,
      tableColumns: headerRow.map((columnName, i) => createHeaderColumnDescriptor(columnName || '—', i, errors, getColumnSchema || (column => schema[column]))),
      ignoredColumns: getIgnoredColumns(headerRow, schema)
    });
  }

  async onUpload() {
    const { onUpload, successMessage } = this.props;
    const { rows } = this.state;

    try {
      let message;
      const result = await onUpload(rows);
      if (result && result.message) {
        message = result.message;
      }
      else if (successMessage) {
        message = successMessage;
        if (typeof result === 'object') {
          for (const key of Object.keys(result)) {
            message = message.replace(new RegExp(`{${key}}`, 'g'), result[key]);
          }
        }
      }
      else {
        message = 'The data has been updated.';
        if (result && result.count) {
          message = message.slice(0, -1) + ': ' +
            Object.keys(result.count)
              .map(key => `${result.count[key]} ${key}`)
              .join(', ') +
            '.';
        }
      }
      notify(message);
    }
    catch (error) {
      notifyError(`Couldn't update the data. ${getErrorData(error).message}.`);
      console.error(error);
    }
  }

  render() {
    const {
      file,
      rows,
      errors,
      tableData,
      tableColumns,
      ignoredColumns
    } = this.state;

    return (
      <div>
        <Form
          onSubmit={this.onUpload}
          className={classNames('inline-file-upload', 'upload-data-page__file-upload', {
            'upload-data-page__file-upload--selected': file
          })}>

          <Field
            required
            name="file"
            ext="xlsx"
            component={DropFileUpload}
            onChange={this.onFileChosen}>
            {file && file.name}
            {!file && 'Click here to choose a file or drop a file here'}
          </Field>

          {rows && (!errors || errors.length === 0) && file &&
            <FormSubmit className="inline-file-upload__upload">
              Submit
            </FormSubmit>
          }
        </Form>

        {ignoredColumns && ignoredColumns.length > 0 &&
          <div className="upload-data-page__ignored-columns">
            Ignored columns:
            <ul className="upload-data-page__ignored-columns-list">
              {ignoredColumns.map((ignoredColumn, i) => (
                <li key={i}>
                  {ignoredColumn}
                </li>
              ))}
            </ul>
          </div>
        }

        {errors && errors.length > 0 &&
          <div className="upload-data-page__errors">
            <h3>Errors</h3>
            <ul>
              {errors.map((error, i) => (
                <li key={i}>
                  <ParseExcelError>{error}</ParseExcelError>
                </li>
              ))}
            </ul>
          </div>
        }

        {rows &&
          <Table
            className="upload-data-page__table"
            data={tableData}
            columns={tableColumns}
          />
        }

        {/* {rows && rows.length > 0 &&
          <div className="upload-data-page__data">
            <h3>Data</h3>
            <pre>
              {JSON.stringify(rows, null, 2)}
            </pre>
          </div>
        } */}
      </div>
    );
  }
}

function ParseExcelError({ children }) {
  const {  value, error, reason, row, column } = children;

  // Error summary.
  return (
    <div>
      <code>"{error}"</code>
      {reason && ' '}
      {reason && <code>("{reason}")</code>}
      {' for value '}
      <code>{stringifyValue(value)}</code>
      {' in column '}
      <code>"{column}"</code>
      {' in row '}
      <code>{row}</code>
      {' of spreadsheet'}
    </div>
  );
}

ParseExcelError.propTypes = {
  children: PropTypes.object.isRequired
};

function stringifyValue(value) {
  // Wrap strings in quotes.
  if (typeof value === 'string') {
    return '"' + value + '"';
  }
  return String(value);
}

function createHeaderColumnDescriptor(column, i, errors, getColumnSchema) {
  const schema = getColumnSchema(column);
  const getError = (row) => errors.filter(error => error.column === column && error.row === row.index + 1)[0];

  function renderTableCellValue(value, schema) {
    if (schema) {
      if (schema.parse) {
        try {
          value = schema.parse(value);
        } catch (error) {
          console.error(error);
          value = 'INVALID';
        }
      }
      if (schema.type === Date) {
        if (typeof value === 'number') {
          value = parseExcelDate(value);
        }
      }
    }
    if (value instanceof Date) {
      value = <FormatDate date={value} utc/>;
    }
    // React renders `false` as empty.
    if (typeof value === 'boolean') {
      value = value.toString();
    }
    return value;
  }

  return {
    id: column,
    header: column,
    accessorFn: row => row[i],
    cell: ({ row, getValue }) => {
      const error = schema && getError(row);
      const value = renderTableCellValue(getValue(), schema);
      return (
        <div
          title={error ? JSON.stringify({ ...error, type: error.type && error.type.name }, null, 2) : undefined}
          className={`upload-data-page__table-cell ${error ? 'upload-data-page__table-cell--invalid' : undefined}`}>
          {value}
        </div>
      );
    }
  };
}

function getIgnoredColumns(columns, schema) {
  columns = columns.slice();
  removeInSchemaColumns(columns, schema);
  return columns;
}

function removeInSchemaColumns(columns, schema) {
  for (let property of Object.keys(schema)) {
    if (typeof schema[property].type === 'object') {
      removeInSchemaColumns(columns, schema[property].type);
    } else {
      // Remove an optional "required" asterisk ("*") at the end.
      property = property.replace(/\*$/, '');
      const index = columns.indexOf(property);
      if (index >= 0) {
        columns.splice(index, 1);
      }
    }
  }
}
