import React, { useMemo, useState } from 'react';
import type { FC } from 'react';
import { Select } from 'react-responsive-ui';

import validatePassword from 'common-lib/lib/validatePassword';

import { DeactivateUserModal } from '@acadeum/shared-admin-ui';
import { getErrorData } from '@acadeum/helpers';
import { toast, AppLoading } from '@acadeum/ui';
import type { Options, User } from '@acadeum/types';

import {
  useActivateStudentUserMutation,
  useDeactivateStudentUserMutation,
  useDeleteStudentUserMutation
} from '../../api/studentUsers';

import {
  useStudentUserRequestPasswordResetMutation,
  useStudentUserRequestVerifyEmailMutation,
  useStudentUserVerifyEmailMutation,
  useStudentUserResetPasswordMutation
} from '../../api/admin/studentUsers';

import {
  useAdminRequestUserPasswordResetMutation,
  useAdminRequestUserEmailVerificationMutation,
  useAdminResetUserPasswordMutation,
  useAdminVerifyUserEmailMutation
} from '../../api/admin/users';

import {
  useActivateUserMutation,
  useDeactivateUserMutation,
  useFetchUsersQuery,
  useDeleteUserMutation
} from '../../api/users';

import { useFetchContactsQuery, useUpdateContactMutation } from '../../api/contacts';

import { waitForModalToClose } from '../Modal';
import FormModal from '../FormModal';
import TextInputModal from '../TextInputModal';
import ConfirmationModal from '../ConfirmationModal';
import ConfirmActionModal from '../ConfirmActionModal';
import FormField from '../FormField';

import { formatUserName } from '../../helpers/format';

import actions from '../../actions';
import './AdminUserActions.sass';

const {
  goto,
  signInAs,
  notifyError
} = actions;

interface UserForAction {
  id: number;
  email: string;
}

interface AdminUserActionsProps {
  user: Pick<User, 'id' | 'email' | 'emailVerified' | 'isActive'>;
  isStudent?: boolean;
  seeDetails: boolean;
  refresh: () => Promise<void> | unknown;
}

const AdminUserActions: FC<AdminUserActionsProps> = ({
  user,
  isStudent,
  seeDetails,
  refresh
}) => {
  // TODO: Fix type replace `as UserForAction` with `UserForAction | null`
  const [userForAction, setUserForAction] = useState<UserForAction>({} as UserForAction);

  const [showSendPasswordResetEmailModal, setShowSendPasswordResetEmailModal] = useState(false);
  const [showSendPasswordResetEmailConfirmationModal, setShowSendPasswordResetEmailConfirmationModal] = useState(false);

  const [showPasswordChangeModal, setShowPasswordChangeModal] = useState(false);
  const [showPasswordChangeConfirmationModal, setShowPasswordChangeConfirmationModal] = useState(false);

  const [showRequestEmailVerificationModal, setShowRequestEmailVerificationModal] = useState(false);
  const [showRequestEmailVerificationConfirmationModal, setShowRequestEmailVerificationConfirmationModal] = useState(false);

  const [showVerifyEmailModal, setShowVerifyEmailModal] = useState(false);
  const [showVerifyEmailConfirmationModal, setShowVerifyEmailConfirmationModal] = useState(false);

  const [showActivateUserModal, setShowActivateUserModal] = useState(false);
  const [showActivateUserConfirmationModal, setShowActivateUserConfirmationModal] = useState(false);

  const [showDeactivateUserModal, setShowDeactivateUserModal] = useState(false);
  const [showDeactivateUserConfirmationModal, setShowDeactivateUserConfirmationModal] = useState(false);

  const [showDeleteUserModal, setShowDeleteUserModal] = useState(false);
  const [showDeleteUserConfirmationModal, setShowDeleteUserConfirmationModal] = useState(false);

  const [showLogInAsUserModal, setShowLogInAsUserModal] = useState(false);
  const [showLogInAsUserConfirmationModal, setShowLogInAsUserConfirmationModal] = useState(false);

  const validatePassword_ = (password) => {
    const errorText = {
      tooShort: 'The password is too short. Enter eight characters or more.',
      tooWeak: 'The password is too weak. Enter eight characters or more, using both lowercase and uppercase letters and numbers. Use at least one special character.'
    };
    const error = validatePassword(password);
    if (error) {
      return errorText[error];
    }
  };

  const [userResetPasswordAdmin] = useAdminResetUserPasswordMutation();
  const [studentUserResetPassword] = useStudentUserResetPasswordMutation();

  const onSubmitPasswordChange = async (values) => {
    const { password } = values;
    await (isStudent ? studentUserResetPassword : userResetPasswordAdmin)({ id: userForAction.id, password }).unwrap();
    setShowPasswordChangeConfirmationModal(true);
  };

  const [studentUserRequestPasswordReset] = useStudentUserRequestPasswordResetMutation();
  const [userRequestPasswordResetAdmin] = useAdminRequestUserPasswordResetMutation();

  const onSubmitSendPasswordResetEmail = async () => {
    await (isStudent ? studentUserRequestPasswordReset : userRequestPasswordResetAdmin)({ id: userForAction.id }).unwrap();
    setShowSendPasswordResetEmailModal(false);
    await waitForModalToClose();
    setShowSendPasswordResetEmailConfirmationModal(true);
  };

  const [studentUserRequestVerifyEmail] = useStudentUserRequestVerifyEmailMutation();
  const [userRequestVerifyEmailAdmin] = useAdminRequestUserEmailVerificationMutation();

  const onRequestEmailVerification = async () => {
    await (isStudent ? studentUserRequestVerifyEmail : userRequestVerifyEmailAdmin)({ id: userForAction.id }).unwrap();
    setShowRequestEmailVerificationModal(false);
    await waitForModalToClose();
    setShowRequestEmailVerificationConfirmationModal(true);
  };

  const [studentUserVerifyEmail] = useStudentUserVerifyEmailMutation();
  const [userVerifyEmailAdmin] = useAdminVerifyUserEmailMutation();

  const onVerifyEmail = async () => {
    await (isStudent ? studentUserVerifyEmail : userVerifyEmailAdmin)({ id: userForAction.id }).unwrap();
    await refresh();
    setShowVerifyEmailModal(false);
    await waitForModalToClose();
    setShowVerifyEmailConfirmationModal(true);
  };

  const [activateStudentUserMutation] = useActivateStudentUserMutation();
  const [activateUserMutation] = useActivateUserMutation();

  const onActivateUser = async () => {
    await (isStudent ? activateStudentUserMutation : activateUserMutation)({ id: userForAction.id }).unwrap();
    await refresh();
    setShowActivateUserModal(false);
    await waitForModalToClose();
    setShowActivateUserConfirmationModal(true);
  };

  const [deactivateStudentUserMutation] = useDeactivateStudentUserMutation();


  const onDeactivateUser = async () => {
    await deactivateStudentUserMutation({ id: userForAction.id }).unwrap();
    await refresh();
    setShowDeactivateUserModal(false);
    await waitForModalToClose();
    setShowDeactivateUserConfirmationModal(true);
  };

  const onLogInAsUser = async () => {
    await signInAs({ userId: userForAction.id });
    setShowLogInAsUserModal(false);
    await waitForModalToClose();
    // setShowLogInAsUserConfirmationModal(true);
    // // Wait for the "Logging in..." modal to open.
    // await waitForModalToClose();
    window.location.reload();
  };

  const [deleteStudentUserMutation] = useDeleteStudentUserMutation();
  const [deleteUserMutation] = useDeleteUserMutation();

  const onDeleteUser = async ({ notes }) => {
    if (notes !== 'delete') {
      setShowDeleteUserModal(false);
      await waitForModalToClose();
      return notifyError('You haven\'t typed "delete" in the text input field');
    }
    try {
      await (isStudent ? deleteStudentUserMutation : deleteUserMutation)({ id: userForAction.id }).unwrap();
      await refresh();
      setShowDeleteUserModal(false);
      await waitForModalToClose();
      setShowDeleteUserConfirmationModal(true);
    } catch (err) {
      const error = getErrorData(err);
      console.error(error);
      toast.error(error.message);
    }
  };

  const options = useMemo(() => {
    let actions: Options<string> = [];
    if (seeDetails) {
      actions = actions.concat(SEE_DETAILS_ACTIONS);
    }
    if (user.isActive) {
      actions = actions.concat(DEACTIVATE_USER_ACTION);
    } else {
      actions = actions.concat(ACTIVATE_USER_ACTION);
      actions = actions.concat(DELETE_USER_ACTION);
    }
    if (!user.emailVerified) {
      actions = actions.concat(EMAIL_ACTIONS);
    }
    if (!isStudent && user.isActive && user.emailVerified) {
      actions = actions.concat(LOG_IN_AS_USER_ACTION);
    }
    actions = actions.concat(PASSWORD_ACTIONS);
    return actions;
  }, [user, seeDetails]);

  const goToUserDetailsPage = () => {
    goto(`/admin/${isStudent ? 'student-' : ''}users/${user.id}${isStudent ? '' : '/log'}`);
  };

  const {
    data: contacts,
    isLoading: isLoadingContact
  } = useFetchContactsQuery();

  if (!contacts || isLoadingContact) {
    return <AppLoading/>;
  }

  return (
    <>
      <Select
        placeholder="Actions"
        alignment="right"
        className="AdminUserActions"
        options={options}
        onChange={(action) => {
          if (action === 'see-details') {
            return goToUserDetailsPage();
          }
          setUserForAction(user);
          switch (action) {
            case 'request-password-reset':
              return setShowSendPasswordResetEmailModal(true);
            case 'reset-password':
              return setShowPasswordChangeModal(true);
            case 'request-email-verification':
              return setShowRequestEmailVerificationModal(true);
            case 'verify-email':
              return setShowVerifyEmailModal(true);
            case 'activate':
              return setShowActivateUserModal(true);
            case 'deactivate':
              return setShowDeactivateUserModal(true);
            case 'delete':
              return setShowDeleteUserModal(true);
            case 'login':
              return setShowLogInAsUserModal(true);
            default:
              throw new Error(`Unsupported "action": ${action}`);
          }
        }}
      />

      <ConfirmActionModal
        title="Send Password Reset Email"
        description={(
          <>
            Please confirm that you would like to email <strong>{formatUserName(userForAction)}</strong> at <strong>{userForAction.email}</strong> a password reset link.
          </>
        )}
        onHide={() => setShowSendPasswordResetEmailModal(false)}
        show={showSendPasswordResetEmailModal}
        submitText="Send Email"
        onSubmit={onSubmitSendPasswordResetEmail}
      />

      <ConfirmationModal
        text="A password reset email has been sent"
        show={showSendPasswordResetEmailConfirmationModal}
        onHide={() => setShowSendPasswordResetEmailConfirmationModal(false)}
      />

      {/* @ts-expect-error It's expected error because `FormModal` not typed yet */}
      <FormModal
        title="Reset Password"
        description={`Enter a new password for ${userForAction.email}.`}
        submitText="Submit"
        onSubmit={onSubmitPasswordChange}
        show={showPasswordChangeModal}
        onHide={() => setShowPasswordChangeModal(false)}
      >
        {({ watch }) => {
          const password = watch('password');

          const validatePasswordConfirmation = (passwordConfirmation) => {
            const passwordsMatch = password === passwordConfirmation;
            if (!passwordsMatch) {
              return 'The passwords don\'t match';
            }
          };

          return (
            <>
              {/* @ts-expect-error It's expected error because `FormField` not typed yet */}
              <FormField
                required
                name="password"
                type="password"
                label="New Password"
                validate={validatePassword_}
              />
              {/* @ts-expect-error It's expected error because `FormField` not typed yet */}
              <FormField
                required
                name="passwordConfirmation"
                type="password"
                label="Confirm New Password"
                validate={validatePasswordConfirmation}
              />
            </>
          );
        }}
      </FormModal>

      <ConfirmationModal
        text="The password has been reset"
        show={showPasswordChangeConfirmationModal}
        onHide={() => setShowPasswordChangeConfirmationModal(false)}
      />

      <ConfirmActionModal
        title="Send Verification Email"
        description={(
          <>
            Please confirm that you'd like to send <strong>{formatUserName(userForAction)}</strong> at <strong>{userForAction.email}</strong> a verification email.
          </>
        )}
        submitText="Confirm"
        onSubmit={onRequestEmailVerification}
        show={showRequestEmailVerificationModal}
        onHide={() => setShowRequestEmailVerificationModal(false)}
      />

      <ConfirmationModal
        text={`A verification email has been sent to ${userForAction.email}`}
        show={showRequestEmailVerificationConfirmationModal}
        onHide={() => setShowRequestEmailVerificationConfirmationModal(false)}
      />

      <ConfirmActionModal
        title="Mark Email As Verified"
        description={(
          <>
            Please confirm that you'd like to mark email address as verified: <strong>{userForAction.email}</strong>.
          </>
        )}
        submitText="Confirm"
        onSubmit={onVerifyEmail}
        show={showVerifyEmailModal}
        onHide={() => setShowVerifyEmailModal(false)}
      />

      <ConfirmationModal
        text="The email address has been marked as verified"
        show={showVerifyEmailConfirmationModal}
        onHide={() => setShowVerifyEmailConfirmationModal(false)}
      />

      <ConfirmActionModal
        title="Activate User"
        description={(
          <>
            Please confirm that you'd like to activate user account for <strong>{formatUserName(userForAction)}</strong>.
          </>
        )}
        submitText="Activate"
        onSubmit={onActivateUser}
        show={showActivateUserModal}
        onHide={() => setShowActivateUserModal(false)}
      />

      <ConfirmationModal
        text="The user has been activated"
        show={showActivateUserConfirmationModal}
        onHide={() => setShowActivateUserConfirmationModal(false)}
      />
      {isStudent ? (
        <ConfirmActionModal
          title="Deactivate User"
          description={(
            <>
              Please confirm that you'd like to deactivate user account for <strong>{formatUserName(userForAction)}</strong>.
            </>
          )}
          submitText="Deactivate"
          onSubmit={onDeactivateUser}
          show={showDeactivateUserModal}
          onHide={() => setShowDeactivateUserModal(false)}
        />
      ) : (
        <DeactivateUserModal
          show={showDeactivateUserModal}
          onHide={setShowDeactivateUserModal}
          user={user}
          useUpdateContactMutation={useUpdateContactMutation}
          useDeactivateUserMutation={useDeactivateUserMutation}
          useFetchUsersQuery={useFetchUsersQuery}
          contacts={contacts}
          onSuccessfulSubmit={async () => {
            await refresh();
          }}
        />
      )}


      <ConfirmationModal
        text="The user has been deactivated"
        show={showDeactivateUserConfirmationModal}
        onHide={() => setShowDeactivateUserConfirmationModal(false)}
      />

      {/* @ts-expect-error It's expected error because `TextInputModal` not typed yet */}
      <TextInputModal
        title="Delete User"
        description={(
          <>
            Please confirm that you'd like to <strong>delete</strong> user account for <strong>{formatUserName(userForAction)}</strong>.
            <br />
            Type <strong>delete</strong> in the text input field below.
          </>
        )}
        submitText="Delete User"
        onSubmit={onDeleteUser}
        show={showDeleteUserModal}
        onHide={() => setShowDeleteUserModal(false)}
      />

      <ConfirmationModal
        text="The user has been deleted"
        show={showDeleteUserConfirmationModal}
        onHide={() => setShowDeleteUserConfirmationModal(false)}
      />

      <ConfirmActionModal
        title="Log In as User"
        description={(
          <>
            Please confirm that you'd like to log into the user account for <strong>{formatUserName(userForAction)}</strong>.
          </>
        )}
        submitText="Log In"
        onSubmit={onLogInAsUser}
        show={showLogInAsUserModal}
        onHide={() => setShowLogInAsUserModal(false)}
      />

      <ConfirmationModal
        text="Logging you in..."
        show={showLogInAsUserConfirmationModal}
        onHide={() => setShowLogInAsUserConfirmationModal(false)}
      />
    </>
  );
};

export default AdminUserActions;

const EMAIL_ACTIONS = [
  { value: 'request-email-verification', label: 'Send Verification Email' },
  { value: 'verify-email', label: 'Mark Email As Verified' }
];

const PASSWORD_ACTIONS = [
  { value: 'request-password-reset', label: 'Send Password Reset Email' },
  { value: 'reset-password', label: 'Change Password' }
];

const SEE_DETAILS_ACTIONS = [
  { value: 'see-details', label: 'See User Log' }
];

const ACTIVATE_USER_ACTION = {
  value: 'activate',
  label: 'Activate'
};

const DEACTIVATE_USER_ACTION = {
  value: 'deactivate',
  label: 'Deactivate'
};

const DELETE_USER_ACTION = {
  value: 'delete',
  label: 'Delete'
};

const LOG_IN_AS_USER_ACTION = {
  value: 'login',
  label: 'Log In as User'
};
