import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import { useTranslate } from '@acadeum/translate';
import { getErrorData } from '@acadeum/helpers';

import Output, { OutputPlaceholder } from '../../components/Output';
import Outputs from '../../components/Outputs';
import Row from '../../components/Row';
import FormModal from '../../components/FormModal';
import ReasonFormField from '../../components/ReasonFormField';
import Button from '../../components/Button';
import CourseSubstituteForm from '../../components/CourseSubstituteForm';
import Section from '../../components/Section';
import FormButtons from '../../components/FormButtons';

import { formatName, formatReason } from '../../helpers/format';
import { useRefreshNotifications } from '../../api/notifications';

import actions from '../../actions';

const {
  denyStudentRequest,
  approveStudentRequest,
  notify,
  fetchStudentRequest,
  fetchCourseSubstitutes,
  goto
} = actions;

export default function CourseRegistrationRequest() {
  const t = useTranslate('CourseRegistrationRequest');

  const request = useSelector(state => state.studentRequests.studentRequest);
  const substitutes = useSelector(state => state.courseSubstitutes.substitutes);

  const [showDenyPopup, setShowDenyPopup] = useState();

  // `substituteId` is `null` for the first time,
  // then it's `undefined`, so it's being
  // differentiated between those two.
  const [substituteId, setSubstituteId] = useState(null);

  const getMatchingSubstituteId = () => {
    const { substituteCourseCode, substituteCourseTitle } = request;

    // Loading substitutes.
    if (!substitutes) {
      return;
    }

    const matchingSubstitute = substitutes.filter((substitute) => {
      return substitute.equivalentCourse.code === substituteCourseCode &&
        substitute.equivalentCourse.title === substituteCourseTitle;
    })[0];

    if (matchingSubstitute) {
      return matchingSubstitute.id;
    }
  };

  const getCourseSubstituteId = () => {
    // If the user has explicitly changed the
    // pre-selected matching substitute to "Other"
    // then don't return the matching substitute.
    if (substituteId === undefined && getMatchingSubstituteId()) {
      return undefined;
    }
    return substituteId || request.courseSubstituteId || getMatchingSubstituteId();
  };

  const refreshNotifications = useRefreshNotifications();

  const onApprove = async () => {
    const courseSubstituteId = getCourseSubstituteId();
    if (!courseSubstituteId) {
      return notify('You must pick a substitute or accept/add a new one.');
    }
    await onApproveCourseRegistrationRequest(request.id, {
      courseSubstituteId
    });
    refreshNotifications();
    goto('/course-registration-requests');
  };

  const onDeny = async ({ reason, reasonNotes }) => {
    await denyStudentRequest(request.id, { reason, reasonNotes });
    refreshNotifications();
    notify('The registration request has been denied.');
    goto('/course-registration-requests');
  };

  const onSubstituteChange = (substituteId) => {
    setSubstituteId(substituteId);
  };

  const { student } = request;
  const course = request.section.session.course;
  const { onDemand } = course;

  let title = 'Student Registration Request';
  if (request.status !== 'REQUESTED') {
    title += ' — ' + request.status;
  }

  return (
    <Section title={title}>
      <FormModal
        title={t('deny.title')}
        description={t('deny.description')}
        show={showDenyPopup}
        onHide={() => setShowDenyPopup(false)}
        submitText={t('deny.submit')}
        onSubmit={onDeny}>
        <ReasonFormField
          required
          labelsPath="CourseRegistrationRequest.denyReason"
          options={DENY_REASONS}
        />
      </FormModal>

      <Outputs>
        <Row singleLineEqualHeightSL>
          <OutputPlaceholder className="col-1 col-xs-2 align-children-center">
            <img
              src={course.institution.logoUrl}
              alt={course.institution.name}
              className="logo-grid" />
          </OutputPlaceholder>

          <Output
            label="Teaching Institution"
            className="col-5 col-xs-10"
            value={course.institution.name} />

          <Output
            label="Date Requested"
            type="date"
            value={request.createdAt}
            col={6}
            utc={false} />
        </Row>

        {/* Some of the old legacy course registration requests don't have a `studentId` */}
        {student &&
          <Row>
            <Output
              label="Name"
              value={formatName(student)}
              linkTo={`/students/${student.id}`}
              linkOpensInNewTab
              col={6} />

            <Output
              label="Student ID"
              className="col-2 col-xs-4"
              value={student.hiStudentId} />

            <Output
              label="Email"
              type="email"
              className="col-4 col-xs-8"
              value={student.email} />
          </Row>
        }

        <Row>
          <Output
            label="Course"
            value={`${course.code} ${course.title}`}
            linkTo={`/sections/${request.section.id}`}
            linkOpensInNewTab
            col={6} />

          <Output
            label="Course Credits"
            className="col-2 col-xs-4"
            value={course.hours} />

          <Output
            label="Level"
            className="col-4 col-xs-8"
            value={course.level}/>
        </Row>

        {!onDemand && (
          <Row>
            <Output
              label="Term"
              className="col-2 col-xs-4"
              value={request.section.session.term}/>

            <Output
              label="Session"
              className="col-2 col-xs-4"
              value={request.section.session.name}/>

            <Output
              label="Section"
              className="col-2 col-xs-4"
              value={request.section.number}/>

            <Output
              label="Dates"
              type="date"
              values={[request.section.session.startDate, request.section.session.endDate]}
              col={6}
              utc/>
          </Row>
        )}

        <Row>
          {onDemand ? (
            <Output
              label="On-Demand"
              className="col-2 col-xs-6"
              value={' '}
            />
          ) : (
            <>
              <Output
                label="Last Day to Add"
                className="col-2 col-xs-6"
                type="date"
                value={request.section.session.lastAddDate}
                utc
              />

              <Output
                label="Drop Date"
                className="col-2 col-xs-6"
                type="date"
                value={request.section.session.lastDropDate}
                utc
              />
            </>
          )}

          {request.status !== 'REQUESTED' && request.courseSubstitute &&
            <Output
              label="Home Course"
              value={`${request.courseSubstitute.equivalentCourse.code} ${request.courseSubstitute.equivalentCourse.title}`}
              col={8} />
          }
        </Row>

        {/* Course substitute <select/> */}
        {request.status === 'REQUESTED' &&
          <CourseSubstituteForm
            courseId={course.id}
            substitutes={substitutes}
            substituteId={getCourseSubstituteId()}
            substituteCourseCode={request.substituteCourseCode}
            substituteCourseTitle={request.substituteCourseTitle}
            onSubstituteChange={onSubstituteChange} />
        }

        <Output
          multiline
          label="Reason for registering"
          value={formatReason(request.registerReason, request.registerReasonNotes, 'CourseRegistrationRequest.registerReason')}
        />

        {request.status === 'DENIED' &&
          <Output
            multiline
            label="Reason given for school's denial of registration"
            value={formatReason(request.denyReason, request.denyReasonNotes, 'CourseRegistrationRequest.denyReason')}
          />
        }

        {request.status === 'REQUESTED' && !student &&
          <div className="output">
            Student information must be added before approving the registration request. All students are required to fill in their info before submitting a registration request. Technically, this situation can't happen. Please contact the Acadeum support for assistance.
          </div>
        }

        {request.status === 'REQUESTED' &&
          <FormButtons justify="apart">
            <div>
            </div>

            <div>
              <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                <Button
                  secondary
                  border
                  onClick={() => setShowDenyPopup(true)}>
                  Deny
                </Button>

                {student &&
                  <Button
                    onClick={onApprove}>
                    Approve
                  </Button>
                }
              </div>
            </div>
          </FormButtons>
        }
      </Outputs>
    </Section>
  );
}

CourseRegistrationRequest.propTypes = {
  request: PropTypes.shape({
    id: PropTypes.number.isRequired,
    status: PropTypes.string.isRequired,
    createdAt: PropTypes.instanceOf(Date).isRequired,
    courseSubstitute: PropTypes.shape({
      equivalentCourse: PropTypes.shape({
        code: PropTypes.string.isRequired,
        title: PropTypes.string.isRequired
      }).isRequired
    }),
    courseSubstituteId: PropTypes.number,
    substituteCourseCode: PropTypes.string,
    substituteCourseTitle: PropTypes.string,
    student: PropTypes.object,
    section: PropTypes.shape({
      id: PropTypes.number.isRequired,
      session: PropTypes.shape({
        course: PropTypes.object.isRequired
      }).isRequired
    }).isRequired,
    registerReason: PropTypes.string.isRequired,
    registerReasonNotes: PropTypes.string
  }),
  substitutes: PropTypes.arrayOf(PropTypes.shape({
    equivalentCourse: PropTypes.shape({
      code: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired
    }).isRequired
  }))
};

CourseRegistrationRequest.meta = ({ useSelector }) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const studentRequest = useSelector(state => state.studentRequests.studentRequest);
  return {
    title: studentRequest.status === 'REQUESTED' ? 'Approve/Deny Student Request' : 'Student Request'
  };
};

CourseRegistrationRequest.breadcrumbs = (state) => [
  ['Student Requests', '/course-registration-requests'],
  state.studentRequests.studentRequest.status === 'REQUESTED' ? 'Approve Request' : 'Student Request'
];

CourseRegistrationRequest.load = async ({ params: { id } }) => {
  const studentRequest = await fetchStudentRequest(id);
  // Load the list of available course substitutes.
  await fetchCourseSubstitutes(studentRequest.section.session.course.id);
};

async function onApproveCourseRegistrationRequest(requestId, extraData) {
  try {
    await approveStudentRequest(requestId, extraData);
    notify('Success! The student request has been added to your Enrollment Requests.');
  }
  catch (error) {
    switch (getErrorData(error).message) {
      case 'already_submitted':
        return notify('Your school has already submitted this enrollment request.');
      default:
        throw error;
    }
  }
}

const DENY_REASONS = [
  'COURSE_OFFERED_BY_HOME_INSTITUTION',
  'WRONG_CLASS_OR_WRONG_SECTION',
  'COURSE_NOT_NEEDED',
  'PREREQUISITE_NOT_MET',
  'FINANCIAL_OR_ABILITY_TO_PAY'
];
