import { injectTeachingStudentsEndpoints } from '@acadeum/api';

import { rtkApi } from './rtkApi';

export const { 
  useFetchTeachingStudentQuery,
  useFetchTeachingStudentCourseEnrollmentsQuery,
  useLazyFetchTeachingStudentQuery,
  useLazyFetchTeachingStudentCourseEnrollmentsQuery
} = injectTeachingStudentsEndpoints(rtkApi);

