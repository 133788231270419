import React, { useCallback, useContext } from 'react';
import PropTypes from 'prop-types';
import { Form as ReactForm } from 'easy-react-form';

import { ERROR, PENDING, setStatus, SUCCESS } from '@acadeum/core-ui';
import { getErrorData } from '@acadeum/helpers';

import { ModalContext } from '../Modal';

import actions from '../../actions';

export { List } from 'easy-react-form';

const {
  notifyError
} = actions;

let Form_ = function({
  onSubmit,
  ...rest
}, ref) {
  const modalContext = useContext(ModalContext);

  const wait = useCallback((value) => {
    if (modalContext) {
      modalContext.wait(value);
    }
  }, [modalContext]);

  const onError = useCallback((error) => {
    const { message } = getErrorData(error);
    // notifyError(t('errorVerbose', { global: true }));
    notifyError(message);
    console.error(error);
    wait(false);
    setStatus(ERROR);
  }, [wait]);

  const onSubmit_ = useCallback((values) => {
    wait(true);
    setStatus(PENDING);
    const result = onSubmit(values);
    if (result && typeof result.then === 'function') {
      return result.then(() => {
        wait(false);
        setStatus(SUCCESS);
      });
    } else {
      wait(false);
      setStatus(SUCCESS);
    }
    return result;
  }, [
    wait,
    onSubmit
  ]);

  // `novalidate` HTML attribute prevents the built-in web browser validation tooltips
  // from showing, like the one that tells the user that the email is not valid.
  return (
    <ReactForm
      noValidate
      ref={ref}
      {...rest}
      onError={onError}
      onSubmit={onSubmit_}
    />
  );
};

Form_ = React.forwardRef(Form_);

Form_.propTypes = {
  onSubmit: PropTypes.func.isRequired
};

export default class Form extends React.Component {
  static propTypes = {
    onSubmit: PropTypes.func.isRequired
  };

  form = React.createRef();

  values = () => this.form.current.values();
  get = (name) => this.form.current.get(name);
  set = (name, value) => this.form.current.set(name, value);
  // `clear()` is used on Student Course Pricing Settings page.
  clear = (name) => this.form.current.clear(name);
  reset = () => this.form.current.reset();
  submit = () => this.form.current.onSubmit();
  focus = (name) => this.form.current.focus(name);

  render() {
    return (
      <Form_
        ref={this.form}
        {...this.props}
      />
    );
  }
}
