import React from 'react';
import PropTypes from 'prop-types';

import { useTranslate } from '@acadeum/translate';
import { Col, Row, Button, Form, FormField, FormFooter, FormSubmit, InstitutionAutocomplete, ConsortiumAutocomplete } from '@acadeum/ui';

export  function CourseSharingGroupForm({
  defaultValues,
  onSubmit,
  cancelUrl,
  submitText
}) {
  const t = useTranslate('shared-admin-ui.CourseSharingGroupForm');

  return (
    <Form
      defaultValues={defaultValues}
      onSubmit={onSubmit}>
      <Row>
        <Col lg={12}>
          <FormField
            name="name"
            label={t('name')}
          />
        </Col>
        <Col lg={12}>
          <FormField
            multiline
            name="description"
            label={t('description')}
          />
        </Col>
        <Col lg={6}>
          <InstitutionAutocomplete
            multiple
            label={t('institutions.label')}
            name="institutionIds"
            type="institution"
            required={false}
            placeholder={t('institutions.placeholder')}
          />
        </Col>
        <Col lg={6}>
          <ConsortiumAutocomplete
            multiple
            label={t('consortia.label')}
            name="consortiumIds"
            required={false}
            placeholder={t('consortia.placeholder')}
          />
        </Col>
      </Row>
      <FormFooter>
        {cancelUrl &&
          <Button variant="text-with-padding" url={cancelUrl}>
            {t('cancel', { global: true })}
          </Button>
        }
        <FormSubmit>
          {submitText}
        </FormSubmit>
      </FormFooter>
    </Form>
  );
}

CourseSharingGroupForm.propTypes = {
  defaultValues: PropTypes.shape({
    name: PropTypes.string,
    description: PropTypes.string,
    institutionIds: PropTypes.arrayOf(PropTypes.number).isRequired,
    consortiumIds: PropTypes.arrayOf(PropTypes.number).isRequired
  }),
  cancelUrl: PropTypes.string,
  submitText: PropTypes.string.isRequired,
  onSubmit: PropTypes.func.isRequired
};
