import type { FC } from 'react';
import React from 'react';
import classNames from 'classnames';

import type { MarginProp } from '@acadeum/hooks';
import { useMargin } from '@acadeum/hooks';

import { FiltersProvider } from './context';

import { ActiveFilters } from './ActiveFilters';
import { SelectFilter } from './SelectFilter';
import { PriceFilter } from './PriceFilter';
import { DateRangeFilter } from './DateRangeFilter';
import { InstitutionFilter } from './InstitutionFilter';
import { CounterpartyFilter } from './CounterpartyFilter';
import { ConsortiumFilter } from './ConsortiumFilter';
import { Row } from './Row';

import styles from './Filters.module.scss';

export interface FiltersProps extends Pick<MarginProp, 'mb'> {
  className?: string;
  children?: React.ReactNode;
  values: object;
  onFiltersChange: (values: object) => void;
  border?: boolean;
}

export const Filters: FC<FiltersProps> & {
  Row: typeof Row;
  Select: typeof SelectFilter;
  Price: typeof PriceFilter;
  DateRange: typeof DateRangeFilter;
  Institution: typeof InstitutionFilter;
  Consortium: typeof ConsortiumFilter;
  Counterparty: typeof CounterpartyFilter;
} = ({
  className,
  onFiltersChange,
  values,
  children,
  border,
  mb= 'lg'
}) => {
  const { marginClassNames } = useMargin({ mb });
  return (
    <FiltersProvider onFiltersChange={onFiltersChange} values={values}>
      <div className={classNames(className, styles.root, marginClassNames, {
        [styles.border]: border
      })}>
        <div className={styles.filters}>
          {children}
        </div>
        <ActiveFilters/>
      </div>
    </FiltersProvider>
  );
};

Filters.Consortium = ConsortiumFilter;
Filters.Counterparty = CounterpartyFilter;
Filters.Institution = InstitutionFilter;
Filters.DateRange = DateRangeFilter;
Filters.Select = SelectFilter;
Filters.Price = PriceFilter;
Filters.Row = Row;
