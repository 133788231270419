import React from 'react';
import type { RowData, Table } from '@tanstack/react-table';
import { flexRender } from '@tanstack/react-table';

import styles from './TFoot.module.scss';

interface TFootProps<TData> {
  table: Table<TData>;
}

export const TFoot = <TData extends RowData>({
  table
}: TFootProps<TData>) => {
  return (
    <tfoot className={styles.TFoot}>
      {table.getFooterGroups().map(footerGroup => (
        <tr key={footerGroup.id}>
          {footerGroup.headers.map(header => (
            <th key={header.id} className={styles.th}>
              {header.isPlaceholder
                ? null
                : flexRender(
                  header.column.columnDef.footer,
                  header.getContext()
                )}
            </th>
          ))}
        </tr>
      ))}
    </tfoot>
  );
};
