import React, { useState } from 'react';
import { useSelector } from 'react-redux';

import { useTranslate } from '@acadeum/translate';
import { getEnrollmentTypeLabel } from '@acadeum/helpers';

import getInstitutionLink from '../../helpers/getInstitutionLink';
import { formatName } from '../../helpers/format';

import Output, { OutputPlaceholder } from '../../components/Output';
import Outputs from '../../components/Outputs';
import Row from '../../components/Row';
import Table from '../../components/Table';
import Section from '../../components/Section';
import StudentDetails from '../../components/StudentDetails';
import FormButtons from '../../components/FormButtons';
import Email from '../../components/Email';
import InstitutionLink from '../../components/InstitutionLink';
import FormModal from '../../components/FormModal';
import Button from '../../components/Button';
import ReasonFormField from '../../components/ReasonFormField';

import { useLazyFetchTeachingStudentQuery } from '../../api/teachingStudents';

import { useRefreshNotifications } from '../../api/notifications';

import actions from '../../actions';

import './EnrollmentRequest.sass';

const {
  approveStudentEnrollments,
  denyStudentEnrollments,
  fetchTeachingEnrollmentRequest,
  notify,
  goto
} = actions;

export default function EnrollmentRequest() {
  const t = useTranslate('EnrollmentRequest');

  const request = useSelector(state => state.enrollmentRequestsTM.teachingEnrollmentRequest);

  const [wait, setWait] = useState();
  const [showDenyPopup, setShowDenyPopup] = useState();
  const [studentEnrollmentsDetails, setStudentEnrollmentsDetails] = useState({});
  const [checkedRows, setCheckedRows] = useState(getNonProcessedEnrollments(request).map(_ => _.id));

  const lock = () => setWait(true);
  const unlock = () => setWait(false);

  async function onSubmit(action, parameters) {
    try {
      lock();
      const ids = checkedRows;
      await action(ids, {
        ...parameters,
        enrollmentRequestId: request.id
      });
    } catch (error) {
      unlock();
      throw error;
    }
  }

  async function onAfterSubmit(actionType) {
    const count = checkedRows.length;
    const hasMore = getNonProcessedEnrollments(request).length > count;
    resetSelection();
    switch (actionType) {
      case 'approve':
        notify('Success! ' + (count === 1 ? 'The enrollment has' : `The ${count} enrollments have`) + ' been added to your Accepted Enrollments.');
        break;
      case 'deny':
        notify((count === 1 ? 'The enrollment has' : `The ${count} enrollments have`) + ' been denied.');
        break;
    }
    await refresh();
    unlock();
    if (!hasMore) {
      goto('/enrollment-requests');
    }
  }

  function onDenySelectedEnrollments() {
    if (checkedRows.length === 0) {
      return notify('Select some students first');
    }
    setShowDenyPopup(true);
  }

  async function onSubmitDenySelectedEnrollments({ reason, reasonNotes }) {
    await onSubmit(denyStudentEnrollments, { reason, reasonNotes });
  }

  async function onAfterSubmitDenySelectedEnrollments() {
    await onAfterSubmit('deny');
  }

  async function onApproveSelectedEnrollments() {
    if (checkedRows.length === 0) {
      return notify('Select some students first');
    }
    await onSubmitApproveSelectedEnrollments();
    await onAfterSubmitApproveSelectedEnrollments();
  }

  async function onSubmitApproveSelectedEnrollments() {
    await onSubmit(approveStudentEnrollments);
  }

  async function onAfterSubmitApproveSelectedEnrollments() {
    await onAfterSubmit('approve');
  }

  function resetSelection() {
    setCheckedRows([]);
    setStudentEnrollmentsDetails({});
  }

  const refreshNotifications = useRefreshNotifications();

  async function refresh() {
    refreshNotifications();
    await fetchTeachingEnrollmentRequest(request.id);
  }

  const [lazyFetchTeachingStudentQuery] = useLazyFetchTeachingStudentQuery();
  async function onToggleDetails(studentEnrollment) {
    // Hide details.
    if (studentEnrollmentsDetails[studentEnrollment.id]) {
      return setStudentEnrollmentsDetails({
        ...studentEnrollmentsDetails,
        [studentEnrollment.id]: undefined
      });
    }
    // Show details.
    lock();
    try {
      const studentInfo = await lazyFetchTeachingStudentQuery({ id: studentEnrollment.student.id }).unwrap();
      setStudentEnrollmentsDetails({
        ...studentEnrollmentsDetails,
        [studentEnrollment.id]: studentInfo
      });
    } finally {
      unlock();
    }
  }

  function getStudentEnrollmentsTableColumns(wait) {
    return [{
      title: 'STUDENT NAME',
      content: studentEnrollment => (
        <>
          {formatName(studentEnrollment.student)}
          {studentEnrollmentsDetails[studentEnrollment.id] && (
            <StudentDetails
              student={studentEnrollmentsDetails[studentEnrollment.id]}
              type="TM"
            />
          )}
        </>
      )
    }, {
      title: 'STUDENT ID',
      content: studentEnrollment => studentEnrollment.student.hiStudentId
    }, {
      title: 'EMAIL',
      content: studentEnrollment => <Email address={studentEnrollment.student.email} />
    }, {
      content: studentEnrollment => (
        <div className="data-table__row-actions">
          <Button
            border
            secondary
            disabled={wait}
            onClick={async () => await onToggleDetails(studentEnrollment)}>
            Details
          </Button>
        </div>
      )
    }];
  }

  const studentEnrollments = getNonProcessedEnrollments(request);
  const studentEnrollmentsReviewed = request.enrollments.filter(_ => studentEnrollments.indexOf(_) < 0);

  const section = request.section;
  const session = section.session;
  const course = session.course;

  return (
    <Section
      title={`Enrollment Request — ${getEnrollmentTypeLabel(request.enrollments[0])}`}
      className="new-teaching-enrollment-request-page">

      <Outputs>
        {/* For some reason a combination of `.outputs`, `.row` and `.output`
            prevents the institution logo from being hugely sized in IE11. */}
        <Row singleLineEqualHeight>
          <OutputPlaceholder col={1} className="align-children-center">
            {/* Adding ".logo-grid" CSS class to the link
                seems to prevent the institution logo
                from being hugely sized in IE11. */}
            <InstitutionLink
              institution={request.institution}
              target="_blank"
              className="logo-grid">
              <img
                src={request.institution.logoUrl}
                alt={request.institution.name}
                className="logo-grid" />
            </InstitutionLink>
          </OutputPlaceholder>

          <Output
            label="Member Institution"
            col={4}
            value={request.institution.name}
            linkTo={getInstitutionLink(request.institution)}
            linkOpensInNewTab />

          <Output
            label="Date Requested"
            col={2}
            type="date"
            value={request.createdAt}
            utc={false} />

          <Output
            label="Number of Enrollments"
            col={5}
            type="integer"
            value={request.seatsRequested} />
        </Row>

        <Row>
          <Output
            label="Course"
            col={7}
            value={course.code + ' ' + course.title}
            linkTo={`/courses/${course.id}`}
            linkOpensInNewTab />

          <Output
            label="LEVEL"
            col={5}
            value={course.level} />
        </Row>

        <Row>
          <Output
            label="Term"
            col={2}
            value={session.term} />

          <Output
            label="Session"
            col={3}
            value={session.name} />

          <Output
            label="Section"
            col={2}
            value={section.number}
            linkTo={`/sections/${section.id}`}
            linkOpensInNewTab />

          <Output
            label="Dates"
            col={5}
            type="date"
            values={[session.startDate, session.endDate]}
            utc
          />
        </Row>

        <Table
          disabled={wait}
          checkedRows={checkedRows}
          setCheckedRows={setCheckedRows}
          selectableRows={studentEnrollments.length > 0}
          showTotalResultsCount={false}
          data={studentEnrollments.length > 0 ? studentEnrollments : studentEnrollmentsReviewed}
          columns={getStudentEnrollmentsTableColumns(wait)}
          className="new-teaching-enrollment-request-page__students-table"
        />

        {studentEnrollments.length === 0 &&
          <div>
            All student enrollments have been reviewed for this enrollment request.
          </div>
        }

        {studentEnrollments.length > 0 &&
          <FormButtons>
            <Button
              border
              secondary
              disabled={wait}
              onClick={onDenySelectedEnrollments}>
              Deny
            </Button>

            <Button
              disabled={wait}
              onClick={onApproveSelectedEnrollments}>
              Accept
            </Button>
          </FormButtons>
        }
      </Outputs>

      <FormModal
        title={t('deny.title', { count: checkedRows.length })}
        description={t('deny.description', { count: checkedRows.length })}
        show={showDenyPopup}
        onHide={() => setShowDenyPopup(false)}
        submitText={t('deny.submit')}
        onSubmit={onSubmitDenySelectedEnrollments}
        onAfterSubmit={onAfterSubmitDenySelectedEnrollments}>
        <ReasonFormField
          required
          labelsPath="EnrollmentRequest.denyReason"
          options={DENY_REASONS}
        />
      </FormModal>
    </Section>
  );
}

EnrollmentRequest.meta = () => ({
  title: 'Enrollment Requests'
});

EnrollmentRequest.load = async ({ params: { id } }) => {
  await fetchTeachingEnrollmentRequest(id);
};

EnrollmentRequest.breadcrumbs = () => [
  ['Enrollment Requests', '/enrollment-requests'],
  'Approve Enrollments'
];

function getNonProcessedEnrollments(request) {
  return request.enrollments.filter(_ => _.status === 'REQUESTED');
}

const DENY_REASONS = [
  'COURSE_AT_CAPACITY',
  'COURSE_CANCELLED',
  'NOT_ALIGN_WITH_REQUIREMENT'
];
