/* eslint-disable */

import { useCallback, useEffect, useRef, useState } from 'react';

// `Parameters<F>` requires this type of definition.
// "Type 'F' does not satisfy the constraint '(...args: any) => any'".
type Function = (...args: any[]) => any;

type Options<F extends Function> = {
  argument?: Parameters<F>[0];
  onError?: (error: unknown) => void;
}

type UseFetchReturnType<F extends Function> = {
  loading: true;
  loadingInitially: true;
  data: undefined;
  error?: unknown;
  updateData: (dataUpdate: object) => void;
  refresh: () => Promise<any>
} | {
  loading: false;
  loadingInitially: false;
  // @ts-ignore
  data: Awaited<ReturnType<F>>;
  error?: unknown;
  updateData: (dataUpdate: object) => void;
  refresh: () => Promise<any>
} | {
  loading: true;
  loadingInitially: false;
  // @ts-ignore
  data: Awaited<ReturnType<F>>;
  error?: unknown;
  updateData: (dataUpdate: object) => void;
  refresh: () => Promise<any>
}

interface FetchStatus {
  loading?: boolean;
  cancelled?: boolean;
  success?: boolean;
  error?: boolean;
}
/**
 * @deprecated Use `useQueryWithPagination()` instead.
 * */
export function useFetch<F extends Function>(fetch: F, options = {} as Options<F>): UseFetchReturnType<F> {
  const [error, setError] = useState<unknown>();

  const [loading, setLoading] = useState(true);
  const [loadingInitially, setLoadingInitially] = useState(true);
  // const [hasLoadedInitially, setHasLoadedInitially] = useState(false);

  // @ts-ignore
  type DataType = Awaited<ReturnType<typeof fetch>>

  const [data, setData] = useState<DataType>();

  const fetchStatus = useRef<FetchStatus>();

  const updateData = useCallback((dataUpdate: object) => {
    setData(Object.assign({}, data, dataUpdate));
  }, [data, setData]);

  // Removed the arguments from `fetchData()`.
  // Instead, it should be just a function of no arguments
  // so that it could be automatically called in `useEffect()` in this file.
  // const fetchData = useCallback((async (...rest: Parameters<typeof fetch>) => {

  const { onError, argument } = options;

  // @ts-ignore
  const fetchData = useCallback((async () => {
    const status: FetchStatus = { loading: true };

    fetchStatus.current = status;

    setLoading(true);
    setError(undefined);

    try {
      // @ts-ignore
      // const data = await fetch(...rest) as Awaited<ReturnType<typeof fetch>>;
      const data = await fetch(argument) as DataType;
      if (status.cancelled) {
        return;
      }
      setData(data);
      status.loading = false;
      status.success = true;
      // setHasLoadedInitially(true);
    } catch (error: unknown) {
      if (status.cancelled) {
        return;
      }
      setError(error);
      status.loading = false;
      status.error = true;
      if (onError) {
        onError(error);
      }
    } finally {
      setLoading(false);
      setLoadingInitially(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }), [
    fetch,
    argument
    // No need to re-fetch the data when `onError` option changes.
    // onError
  ]);

  useEffect(() => {
    void fetchData();
    return () => {
      if (fetchStatus.current) {
        if (fetchStatus.current.loading) {
          fetchStatus.current.cancelled = true;
        }
        fetchStatus.current = undefined;
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    // Re-fetch data if `fetchData()` function changes.
    fetchData
  ]);

  // @ts-ignore
  return { loading, loadingInitially, data, error, updateData, refresh: fetchData };
}
