import { createContext } from 'react';

export type ModalDatePickerContextProps = boolean;

export const ModalDatePickerContext = createContext<ModalDatePickerContextProps>(false);

export interface ModalSelectContextProps {
  enforceFocus: boolean;
  onOpenCombobox: () => void;
  onCloseCombobox: () => void;
}

export const ModalSelectContext = createContext<ModalSelectContextProps | undefined>(undefined);

export interface ModalLexicalEditorContextProps {
  enforceFocus: boolean;
  onShow: () => void;
  onClose: () => void;
}

export const ModalLexicalEditorContext = createContext<ModalLexicalEditorContextProps | undefined>(undefined);

export interface ModalUIContextProps {
  hasScroll: boolean;
  setHasScroll: (value: boolean) => void;
  hasFooter: boolean;
  setHasFooter: (value: boolean) => void;
  hasHeader: boolean;
  setHasHeader: (value: boolean) => void;
}

export const ModalUIContext = createContext<ModalUIContextProps | undefined>(undefined);
