import { injectUserRolesEndpoints } from '@acadeum/api';

import { rtkApi } from './rtkApi';

export const {
  useFetchUserRoleQuery,
  useFetchUserRolesQuery,
  useDuplicateUserRoleMutation,
  useCreateUserRoleMutation,
  useUpdateUserRoleMutation,
  useDeleteUserRoleMutation
} = injectUserRolesEndpoints(rtkApi);
