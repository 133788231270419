import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import { DataBlock, Table, Select, Label, Email } from '@acadeum/ui';
import { createColumnHelper } from '@tanstack/react-table';
import { useQueryWithPagination } from '@acadeum/hooks';

import Section from '../../components/Section';
import AdminUserActions from '../../components/AdminUserActions';

import { useFetchAdminUsersQuery } from '../../api/admin/users';
import { useFetchAdminStudentUsersQuery } from '../../api/admin/studentUsers';

import AdminUserStatusList from './ui/AdminUserStatusList';

const columnHelper = createColumnHelper();

export default function AdminUsersPage() {
  const [isStudents, setIsStudents] = useState(true);

  const { users: adminUsers } = useSelector(state => state.usersAdmin);
  const { studentUsers } = useSelector(state => state.studentUsersAdmin);

  const data = isStudents ? studentUsers : adminUsers;

  const totalCount = data && data.totalCount;
  const users = data && data.results;

  return (
    <Section title="User Accounts">
      <Label>Environment
        <Select
          value={isStudents ? 'studentUsers' : 'users'}
          onChange={(value) => setIsStudents(value === 'studentUsers')}
          options={[
            { value: 'studentUsers', label: 'Student App' },
            { value: 'users', label: 'Admin App' }
          ]}
        />
      </Label>
      <br />
      <UsersList
        key={isStudents ? 'studentUsers' : 'users'}
        users={users}
        totalCount={totalCount}
        isStudents={isStudents}
      />
    </Section>
  );
}

AdminUsersPage.meta = () => ({
  title: 'User Accounts'
});

AdminUsersPage.breadcrumbs = () => [
  ['Admin Tools', '/admin'],
  'User Accounts'
];

const UsersList = ({
  isStudents
}) => {

  const queryFn = useMemo(() => {
    return (isStudents ? useFetchAdminStudentUsersQuery : useFetchAdminUsersQuery);
  }, [isStudents]);

  const defaultSorting = useMemo(() => [
    { by: 'latestLoginDate', asc: false }
  ], []);

  const {
    data,
    isFetching,
    isLoading,
    refetch,
    _paginationOptions,
    isError,
    _globalFilterOptions
  } = useQueryWithPagination(queryFn, { sort: defaultSorting });

  const columns = useColumns({ isStudents, refresh: refetch });

  return (
    <Table
      data={isError ? [] : data?.results}
      loading={isLoading}
      isFetching={isFetching}
      paginationOptions={_paginationOptions}
      globalFilterOptions={_globalFilterOptions}
      columns={columns}
      translate={{
        searchPlaceholder: 'Search by Name, ID or Email'
      }}
    />
  );
};

UsersList.propTypes = {
  isStudents: PropTypes.bool
};

function useColumns({
  isStudents,
  refresh
}) {
  return [
    columnHelper.display({
      id: 'name',
      header: 'Name',
      enableSorting: false,
      cell: ({ row }) => (
        <DataBlock
          type="user"
          user={row.original}
          inlineInfo={<AdminUserStatusList user={row.original} />}
        />
      )
    }),
    columnHelper.accessor('email', {
      header: 'Email',
      enableSorting: false,
      cell: (info) => <Email address={info.getValue()} />
    }),
    columnHelper.accessor('institution.name', {
      header: 'Home Institution',
      enableSorting: false
    }),
    columnHelper.accessor('latestLoginDate', {
      header: 'Latest Login',
      enableSorting: true,
      cell: (info) => <DataBlock type="relativeDate" date={info.getValue()} />
    }),
    columnHelper.display({
      id: 'actions',
      cell: ({ row }) => (
        <AdminUserActions
          seeDetails
          user={row.original}
          isStudents={isStudents}
          refresh={refresh}
        />
      )
    })
  ];
}
