import reportError from './reportError.js';
import formatDateYearMonthDayUtc from './formatDateYearMonthDayUtc.js';
import convertLocalDateToUtcDateWithSameTime from './convertLocalDateToUtcDateWithSameTime.js';

const SUPPORTED_LOCALES = [
  'en'
];

const formattersCache = {};

function getCacheKey(locale, options) {
  return [
    locale,
    options.day === false ? 'false' : 'numeric',
    options.month || 'numeric',
    options.year === false ? 'false' : 'numeric',
    options.time ? 'time' : 'no-time',
    options.weekday || 'false',
    options.utc ? 'utc' : 'local'
  ].join('-');
}

function getFormatter(locale, options) {
  const cacheKey = getCacheKey(locale, options);

  if (formattersCache[cacheKey]) {
    return formattersCache[cacheKey];
  }

  const formatter = new Intl.DateTimeFormat(locale, {
    day: options.day === false ? undefined : 'numeric',
    month: options.month || 'numeric',
    year: options.year === false ? undefined : 'numeric',
    hour: options.time ? '2-digit' : undefined,
    minute: options.time ? '2-digit' : undefined,
    weekday: options.weekday || undefined,
    timeZone: options.utc ? 'UTC' : undefined
  });

  formattersCache[cacheKey] = formatter;
  return formatter;
}

/**
 * Formats a date.
 * @param {Date} date - The date object to format.
 * @param {Object} [options={}] - Formatting options.
 * @param {boolean} [options.day=true] - Whether to include the day in the output. `false` excludes the day.
 * @param {('long'|'short'|'numeric')} [options.month=numeric] - The month format. Can be 'long' (e.g., "September"),
 *   'short' (e.g., "Sep"), or `undefined` for numeric format (e.g., "9").
 * @param {boolean} [options.utc=false] - If `true`, the date is formatted in UTC. If `false`, it uses the local
 *   timezone.
 * @param {string} [options.locale='en'] - The locale to use for formatting. Default is 'en' (English).
 * @param {boolean} [options.year=true] - Whether to include the year in the output. `false` excludes the year.
 * @param {boolean} [options.time=false] - Whether to include the time (hours and minutes) in the output.
 * @return {string} - The formatted date as a string.
 */
export default function formatDate(date, options = {}) {
  if (!(date instanceof Date)) {
    console.error('`formatDate()` argument must be a `Date`');
  }

  if (!date) {
    throw new Error('date not supplied to "formatDate"');
  }

  if (options.utc === undefined) {
    if (typeof window === 'undefined') {
      throw new Error('`utc` parameter is required when calling `formatDate()`');
    } else {
      // Don't abort the code execution if someone forgot the `utc` parameter:
      // it's considered a better UX to show the user something rather than a blank page.
      reportError('`utc` parameter is required when calling `formatDate()`');
    }
  }

  const locale = options.locale;
  if (SUPPORTED_LOCALES.includes(locale)) {
    const formattedDate = getFormatter(locale, options).format(date);
    if (formattedDate) {
      return formattedDate;
    }
  }

  switch (locale) {
    case 'en':
      if (typeof window === 'undefined') {
        throw new Error(`Couldn't format date using options:\n${JSON.stringify(options, null, 2)}`);
      } else {
        reportError(`Couldn't format date using options:\n${JSON.stringify(options, null, 2)}`);
        if (options.utc) {
          return formatDateYearMonthDayUtc(date);
        } else {
          return formatDateYearMonthDayUtc(convertLocalDateToUtcDateWithSameTime(date));
        }
      }
  }

  return formatDate(date, { ...options, locale: 'en' });
}
