import {
  getDataFromStorageWithExpiry,
  saveDataToStorageWithExpiry,
  getDataFromStorage,
  saveDataToStorage,
  removeDataFromStorage,
  getCookie,
  setCookie,
  deleteCookie,
  isHttpError
} from '@acadeum/helpers';

import type {
  AuthCookieConfig,
  ExpiresAt,
  ExpiresIn,
  OnAccessToken,
  OnAccessTokenExpired, RefreshAccessToken,
  Token,
  UserSessionEphemeral
} from './types';

// Refresh the access token max once in a minute.
const REFRESH_TIMEOUT = 60 * 1000;

function getTokenRefreshInterval(expiresIn: ExpiresIn) {
  return expiresIn * 0.3;
}

export function shouldUseCookies({ authCookieConfig, userSessionEphemeral }) {
  return Boolean(authCookieConfig) && !userSessionEphemeral;
}

// Public API.
export function onAccessTokenReceived(
  token: Token,
  { expiresIn, expiresAt }: { expiresIn: ExpiresIn; expiresAt: ExpiresAt; },
  { authCookieConfig }: { authCookieConfig?: AuthCookieConfig }
) {
  setAccessToken(token, {
    expiresIn,
    expiresAt,
    // `onAccessTokenReceived()` function gets called when an `accessToken` has been received
    // from a "log in" API endpoint, and that `accessToken` should be applied on the page.
    // The only instance when that's the case is a "Log In" form.
    // It doesn't get called in a "Log In As" scenario.
    userSessionEphemeral: false,
    authCookieConfig
  });
}

export function setAccessToken(
  token: Token,
  { expiresIn, expiresAt, userSessionEphemeral, authCookieConfig }: {
    expiresIn: ExpiresIn;
    expiresAt: ExpiresAt;
    userSessionEphemeral: UserSessionEphemeral;
    authCookieConfig?: AuthCookieConfig
  }
) {
  debug('Auth: Set Access Token');
  debug('Auth: Access Token expires in', expiresIn);

  if (userSessionEphemeral) {
    debug('Auth: User Session is Ephemeral');
  }

  const saveAuthParameter = (name, value) => {
    if (shouldUseCookies({ authCookieConfig, userSessionEphemeral })) {
      const setAuthCookie = (name, value) => setCookie(authCookieConfig!.prefix + name, value, {
        domain: authCookieConfig!.domain,
        expiresAt: expiresAt ? new Date(expiresAt) : undefined
      });
      setAuthCookie(name, String(value));
    } else {
      saveDataToStorage(name, value, {
        useSessionStorage: userSessionEphemeral
      });
    }
  };

  const removeAuthParameter = (name) => {
    if (shouldUseCookies({ authCookieConfig, userSessionEphemeral })) {
      const clearAuthCookie = (name) => deleteCookie(authCookieConfig!.prefix + name, {
        domain: authCookieConfig!.domain
      });
      clearAuthCookie(name);
    } else {
      removeDataFromStorage(name, {
        useSessionStorage: userSessionEphemeral
      });
    }
  };

  // Save `accessToken` value.
  if (shouldUseCookies({ authCookieConfig, userSessionEphemeral })) {
    // Access token cookie can only be set on the server.
  } else {
    saveDataToStorage('accessToken', token, {
      useSessionStorage: userSessionEphemeral
    });
  }

  // Save `expiresIn` value.
  if (expiresIn) {
    saveAuthParameter('accessTokenExpiresIn', expiresIn);
  } else {
    removeAuthParameter('accessTokenExpiresIn');
  }

  // Save `expiresAt` value.
  if (expiresAt) {
    saveAuthParameter('accessTokenExpiresAt', expiresAt);
  } else {
    removeAuthParameter('accessTokenExpiresAt');
  }

  // // Save `accessTokenRefreshedAt` value.
  // saveDataToStorageWithExpiry('accessTokenRefreshedAt', Date.now(), {
  //   expiresIn: getTokenRefreshInterval(expiresIn),
  //   useSessionStorage: userSessionEphemeral
  // });

  // `accessTokenRefreshedAt` parameter is no longer used in the code.
  // Clean it up from `localStorage` where it has been saved by older versions of the websites.
  removeDataFromStorage('accessTokenRefreshedAt', {
    useSessionStorage: userSessionEphemeral
  });
}

export function hasAccessToken({ authCookieConfig }: { authCookieConfig?: AuthCookieConfig }) {
  const userSessionEphemeral = isUserSessionEphemeral();
  if (shouldUseCookies({ authCookieConfig, userSessionEphemeral })) {
    return hasAccessTokenCookie({ authCookieConfig });
  } else {
    return getAccessTokenFromLocalStorage() !== null;
  }
}

export function hasAccessTokenCookie({ authCookieConfig }: { authCookieConfig?: AuthCookieConfig }) {
  const getAuthCookie = (name) => getCookie(authCookieConfig!.prefix + name);
  return authCookieConfig && Boolean(getAuthCookie('accessTokenCookie'));
}

// Even though Acadeum websites normally use cookies for authentication,
// cookies aren't used when running those websites in development on `localhost`.
// So local storage is still used for storing access token in those cases.
//
// Also, some websites, like the Student site, don't need to use cookies for authentication
// because there's no requirement for "Single Sign On" across Acadeum apps on that website.
//
export function getAccessTokenFromLocalStorage() {
  const userSessionEphemeral = isUserSessionEphemeral();
  return getAccessTokenFromStorage({
    useSessionStorage: userSessionEphemeral
  });
}

function getAccessTokenFromStorage({ useSessionStorage }: { useSessionStorage?: boolean }) {
  return getDataFromStorage('accessToken', {
    type: 'string',
    useSessionStorage
  });
}

function clearAccessToken({ authCookieConfig }: { authCookieConfig?: AuthCookieConfig }) {
  debug('Auth: Clear Access Token');

  // These access token parameters may be stored either in `localStorage` or in cookies:
  // * `accessToken`
  // * `accessTokenExpiresIn`
  // * `accessTokenExpiresAt`

  const userSessionEphemeral = isUserSessionEphemeral();

  if (shouldUseCookies({ authCookieConfig, userSessionEphemeral })) {
    const clearAuthCookie = (name) => deleteCookie(authCookieConfig!.prefix + name, {
      domain: authCookieConfig!.domain
    });
    // The `accessToken` cookie is `httpOnly` so it can only be cleared using
    // `authentication-clearCookie` lambda rather than using `document.cookie`.
    // // clearAuthCookie('accessToken');
    clearAuthCookie('accessTokenCookie');
    clearAuthCookie('accessTokenExpiresIn');
    clearAuthCookie('accessTokenExpiresAt');
  }
  // There might be some "leftovers" in `localStorage` from the times
  // before cookie authentication was implemented, so clean those up.
  else {
    removeDataFromStorage('accessToken', {
      useSessionStorage: userSessionEphemeral
    });
    removeDataFromStorage('accessTokenExpiresIn', {
      useSessionStorage: userSessionEphemeral
    });
    removeDataFromStorage('accessTokenExpiresAt', {
      useSessionStorage: userSessionEphemeral
    });
  }

  // These utility parameters are always stored in `localStorage`.
  removeDataFromStorage('accessTokenRefreshLock', {
    useSessionStorage: userSessionEphemeral
  });
  // `accessTokenRefreshedAt` parameter is no longer used in the code.
  // Clean it up from `localStorage` where it has been saved by older versions of the websites.
  removeDataFromStorage('accessTokenRefreshedAt', {
    useSessionStorage: userSessionEphemeral
  });
}

export function getAccessTokenExpiresAt({ authCookieConfig }: { authCookieConfig?: AuthCookieConfig }) {
  const userSessionEphemeral = isUserSessionEphemeral();
  if (shouldUseCookies({ authCookieConfig, userSessionEphemeral })) {
    const getAuthCookie = (name) => getCookie(authCookieConfig!.prefix + name);
    const expiresAt = getAuthCookie('accessTokenExpiresAt');
    if (expiresAt) {
      return Number(expiresAt);
    }
  } else {
    const expiresAt: number = getDataFromStorage('accessTokenExpiresAt', {
      useSessionStorage: userSessionEphemeral
    });
    if (expiresAt) {
      return expiresAt;
    }
  }
}

export function getAccessTokenExpiresIn({ authCookieConfig }: { authCookieConfig?: AuthCookieConfig }) {
  const userSessionEphemeral = isUserSessionEphemeral();
  if (shouldUseCookies({ authCookieConfig, userSessionEphemeral })) {
    const getAuthCookie = (name) => getCookie(authCookieConfig!.prefix + name);
    const expiresIn = getAuthCookie('accessTokenExpiresIn');
    if (expiresIn) {
      return Number(expiresIn);
    }
  } else {
    const expiresIn = getDataFromStorage('accessTokenExpiresIn', {
      useSessionStorage: userSessionEphemeral
    });
    if (expiresIn) {
      return Number(expiresIn);
    }
  }
}

// Returns `false` if the token can't be refreshed due to being expired.
export async function refreshAccessToken({
  onAccessToken,
  onAccessTokenExpired,
  refreshAccessToken,
  authCookieConfig
}: {
  onAccessToken: OnAccessToken;
  onAccessTokenExpired?: OnAccessTokenExpired;
  refreshAccessToken: RefreshAccessToken;
  authCookieConfig?: AuthCookieConfig;
}) {
  const accessTokenExpiresAt = getAccessTokenExpiresAt({ authCookieConfig });
  const accessTokenExpiresIn = getAccessTokenExpiresIn({ authCookieConfig });

  const userSessionEphemeral = isUserSessionEphemeral();

  // if (getDataFromStorageWithExpiry('accessTokenRefreshedAt', {
  //   useSessionStorage: userSessionEphemeral
  // }) !== null) {
  //   debug('Auth: Refresh Access Token: No Need');
  //   return;
  // }

  // If the token doesn't expire then there's no need to refresh it.
  if (!accessTokenExpiresAt) {
    debug('Auth: Refresh Access Token: No Need');
    return;
  }

  // If `accessTokenExpiresAt` is defined, `accessTokenExpiresIn` should be too.
  if (!accessTokenExpiresIn) {
    console.error('Auth: Refresh Access Token: Error: `accessTokenExpiresIn` unknown');
    return;
  }

  // If it's not the time to refresh access token yet then don't do that.
  if (Date.now() - (accessTokenExpiresAt - accessTokenExpiresIn) < getTokenRefreshInterval(accessTokenExpiresIn)) {
    debug('Auth: Refresh Access Token: No Need');
    return;
  }

  // See if a "concurrent" access token refresh is already running in some other tab.
  if (getDataFromStorageWithExpiry('accessTokenRefreshLock', {
    useSessionStorage: userSessionEphemeral
  }) !== null) {
    debug('Auth: Refresh Access Token: Already In Progress');
    return;
  }

  debug('Auth: Refresh Access Token: Refresh');

  // Acquire an inter-tab lock on the refresh procedure.
  const accessTokenRefreshLockId = Date.now();
  saveDataToStorageWithExpiry('accessTokenRefreshLock', accessTokenRefreshLockId, {
    expiresIn: REFRESH_TIMEOUT,
    useSessionStorage: userSessionEphemeral
  });

  try {
    // Refresh access token.
    const { token, expiresIn, expiresAt } = await refreshAccessToken({
      expiresIn: getAccessTokenExpiresIn({ authCookieConfig })
    });

    debug('Auth: Refresh Access Token: Refreshed');

    // Set the new access token.
    onAccessToken(token, {
      expiresIn,
      expiresAt,
      userSessionEphemeral,
      authCookieConfig
    });
  } catch (error) {
    console.error('Auth: Refresh Access Token: Error');
    console.error(error);
    if (isHttpError(error) && error.data.code === 'access_token_expired') {
      clearUserSession({ authCookieConfig });
      if (onAccessTokenExpired) {
        onAccessTokenExpired();
      }
      return false;
    }
    throw error;
  } finally {
    // Release the inter-tab lock.
    const currentAccessTokenRefreshLockId = getDataFromStorageWithExpiry('accessTokenRefreshLock', {
      useSessionStorage: userSessionEphemeral
    });
    if (currentAccessTokenRefreshLockId === accessTokenRefreshLockId) {
      removeDataFromStorage('accessTokenRefreshLock', {
        useSessionStorage: userSessionEphemeral
      });
    } else {
      console.error('Auth: Refresh Access Token: Timed Out');
    }
  }
}

export function clearUserSession({ authCookieConfig }: { authCookieConfig?: AuthCookieConfig; }) {
  clearAccessToken({ authCookieConfig });
}

function isUserSessionDataStoredInSessionStorage() {
  return Boolean(getAccessTokenFromStorage({ useSessionStorage: true }));
}

export function isUserSessionEphemeral() {
  return isUserSessionDataStoredInSessionStorage();
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
function debug(...args) {
  // console.log(...args);
}

export function usesCookiesForAuth(): boolean {
  // Cookies aren't used on localhost.
  if (typeof window !== 'undefined') {
    if (window.location.hostname === 'localhost') {
      return false;
    }
  }
  return !isUserSessionEphemeral();
}
