import React, { useMemo, useState } from 'react';
import type { FC } from 'react';
import PropTypes from 'prop-types';

import { useTranslate } from '@acadeum/translate';

import { FormField, FormRow } from '@acadeum/ui';

interface ReasonFormFieldUiProps {
  required?: boolean;
  col?: boolean;
  defaultValue?: string;
  notesDefaultValue?: string;
  other?: boolean;
  labelsPath: string;
  options: string[];
}

const ReasonFormFieldUi: FC<ReasonFormFieldUiProps> = ({
  required,
  col,
  defaultValue,
  notesDefaultValue,
  other = true,
  labelsPath,
  options
}) => {
  const t = useTranslate('ReasonFormField');

  const options_ = useMemo(() => {
    const options_ = options.map((value) => ({
      value,
      label: t(`${labelsPath}.options.${value}`, { global: true })
    }));
    if (other) {
      options_.push({
        label: t('other'),
        value: 'OTHER'
      });
    }
    return options_;
  }, [options]);

  const [reason, setReason] = useState(defaultValue);

  const onChangeReason = (event) => {
    setReason(event.target.value);
  };

  const reasonFormField = (
    <FormField
      required={required}
      name="reason"
      type="select"
      defaultValue={defaultValue}
      options={options_}
      label={t(`${labelsPath}.label`, { global: true })}
      onChange={onChangeReason}
    />
  );

  const reasonNotesFormField = (
    <FormField
      autoFocus
      required
      multiline
      rows={2}
      name="reasonNotes"
      placeholder={t(`${labelsPath}.notesHint`, { global: true, required: false })}
      defaultValue={notesDefaultValue}
      label={t('notes')}
      labelHidden
    />
  );

  if (col) {
    return (
      <FormRow>
        {reasonFormField}
        {reason === 'OTHER' && reasonNotesFormField}
      </FormRow>
    );
  }

  return (
    <>
      {reasonFormField}
      {reason === 'OTHER' &&
        reasonNotesFormField
      }
    </>
  );
};

export default ReasonFormFieldUi;

ReasonFormFieldUi.propTypes = {
  required: PropTypes.bool,
  col: PropTypes.bool,
  other: PropTypes.bool,
  labelsPath: PropTypes.string.isRequired,
  defaultValue: PropTypes.string,
  notesDefaultValue: PropTypes.string,
  options: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired
};
