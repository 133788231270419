import React from 'react';

import { useTranslate } from '@acadeum/translate';

import { Button, DataBlock, Table } from '@acadeum/ui';

const StudentsTable = ({ data, onShowInfoStudent, enableRowSelection, checkedRows, setCheckedRows }) => {
  const t = useTranslate('EnrollmentRequests');
  const columns = [
    {
      accessorKey: 'name',
      header: t('name'),
      cell: ({ row }) => {
        const onClick = () => {
          onShowInfoStudent(row.original.student);
        };

        return (
          <Button variant="text" onClick={onClick}>
            <DataBlock
              type="student"
              student={row.original.student}
              withLink={false}
              showId={false}
            />
          </Button>
        );
      }
    },
    {
      accessorKey: 'student.id',
      header: t('id')
    },
    {
      accessorKey: 'student.email',
      header: t('email', { global: true })
    }
  ];

  return (
    <Table
      rowSelectionOptions={enableRowSelection ? { rowSelection: checkedRows, onRowSelectionChange: setCheckedRows } : undefined}
      enableRowSelection={enableRowSelection}
      data={data}
      columns={columns}
    />
  );
};
export default StudentsTable;
